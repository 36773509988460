import React from 'react';
import PropTypes from 'prop-types';
import Button from '../common/Button';
import styles from './CtaButtonSection.module.scss';

class CtaButtonSection extends React.Component {
    renderCtaVersion() {
        const { subtitle, ctaButtonStandard } = this.props.data;

        return (
            <section className={styles.section}>
                {subtitle && <h5 className={styles.title}>{subtitle}</h5>}
                {ctaButtonStandard && (
                    <Button
                        className={styles.button}
                        href={ctaButtonStandard.url}
                        colour="primary">
                        {ctaButtonStandard.buttonText}
                    </Button>
                )}
            </section>
        );
    }

    render() {
        if (this.props.data) {
            const ctaContent = this.renderCtaVersion();

            if (!this.props.data) {
                console.error(
                    'CtaButtonSection: No data found. Please provide an data object'
                );
                return null;
            }

            return <React.Fragment>{ctaContent}</React.Fragment>;
        } else {
            return null;
        }
    }
}

CtaButtonSection.propTypes = {
    subtitle: PropTypes.string,
    ctaButtonStandard: PropTypes.shape({
        url: PropTypes.string.isRequired,
        buttonText: PropTypes.string.isRequired
    })
};

export default CtaButtonSection;
