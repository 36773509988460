import React, { PureComponent } from 'react';
import { graphql } from 'gatsby';
import Metrics from '../components/Home/Metrics';
import HowItWorks from '../components/Home/HowItWorksV2/HowItWorks';
import HowItWorksScroll from '../components/Home/HowItWorks/HowItWorksScroll';
import MessageSection from '../components/common/MessageSection';
import LinksCards from '../components/common/LinksCards/LinksCards';
import ContactSection from '../components/common/ContactSection/ContactSection';
import { fetchIsLoggedIn } from '../util/authHelpers';
import Layout from '../components/Layout/Layout';
import MediaAndText from '../components/common/MediaAndText';
import HeroBannerSmall from '../components/common/HeroBannerSmall';
import CTASection from '../components/common/CTASection';
import QuickJumpContentBlocks from '../components/common/QuickJumpContentBlocks';
import ContentSection from '../components/common/ContentSection';
import ParterCarouselSection from '../components/common/carousel/PartnerCarouselSection';
import ComparisonTableSection from '../components/common/ComparisonTableSection';
import HeroDynamicImageOnTheRight from '../components/common/hero/HeroDynamicImageOnTheRight';
import FeaturesAndReviewSection from '../components/AboutUs/FeaturesAndReviewSection';
import LinkCollectionSection from '../components/AboutUs/LinkCollectionSection';
import LegacyHeroBannerSection from '../components/LegacyPage/HeroBannerSection';
import LegacyToNewRCFCalculatorConverter from './uk/common/CalculatorSection/LegacyToNewRCFCalculatorConverter.jsx';

class WebsitePage extends PureComponent {
    render() {
        const { data } = this.props;

        const globalVariables = data.contentfulGlobalVariables;

        const {
            contentfulPage,
            contentfulFeatureManagementSystem,
            contentfulHreflangManagement
        } = data;

        const {
            titleTag,
            metaDescription,
            allowSearchEngineScraping,
            url,
            canonicalTag
        } = contentfulPage;

        const jobs = data.jobs ? data.jobs.edges.map((item) => item.node) : [];

        const pageData = data.contentfulPage || {};
        const { header = {}, sections = [] } = pageData.pageContent;

        const isLoggedIn = fetchIsLoggedIn();

        // Sections
        const sectionsComponents = renderSections(sections, globalVariables, {
            pageData,
            jobs,
            isLoggedIn
        });

        // Forms
        const { applyForm, loginForm, resetForm, termLoansForm } = data;

        return (
            <Layout
                headerData={header}
                data={data}
                userIsLoggedIn={isLoggedIn}
                globalVariables={globalVariables}
                modals={{
                    applyForm,
                    loginForm,
                    resetForm,
                    termLoansForm
                }}
                metaData={{
                    titleTag,
                    metaDescription,
                    allowSearchEngineScraping,
                    url,
                    canonicalTag,
                    contentfulHreflangManagement
                }}
                featureFlags={contentfulFeatureManagementSystem}>
                {sectionsComponents}
            </Layout>
        );
    }
}

const renderSections = (sections, globalLabels, props) => {
    // Flatten the sections i.e. Extract sections from sets
    let flattenedSections = [];

    if (sections === null || sections.length < 1) return null;

    /* eslint-disable */
    for (const section of sections) {
        if (section.__typename === 'ContentfulSectionSet') {
            for (const nestedSection of section.sections) {
                flattenedSections.push(nestedSection);
            }
        } else {
            flattenedSections.push(section);
        }
    }
    /* eslint-enable */

    const result = flattenedSections.map((section, index) => {
        let component;
        const sectionData = Object.assign({}, section, globalLabels);

        switch (section.__typename) {
            case 'ContentfulSectionHeroBanner':
                if (
                    sectionData.advancedSettings &&
                    sectionData.advancedSettings.heroBannerVersion === 'old'
                ) {
                    component = (
                        <LegacyHeroBannerSection
                            data={sectionData}
                            loggedIn={props.isLoggedIn}
                            key={sectionData.id}
                            nonLegacyLayout={true}
                        />
                    );
                    break;
                }
                break;
            case 'ContentfulSectionFeaturesBar':
                component = (
                    <Metrics
                        data={sectionData}
                        key={index}
                        score={props.reviewsScore}
                        reviewsTotal={props.reviewsTotal}
                    />
                );
                break;
            case 'ContentfulSectionHowItWorksV2':
                component = <HowItWorks data={sectionData} key={index} />;
                break;
            case 'ContentfulSectionHowItWorksScroll':
                component = <HowItWorksScroll data={sectionData} key={index} />;
                break;
            case 'ContentfulSectionRepaymentCalculator':
                component = (
                    <LegacyToNewRCFCalculatorConverter data={sectionData} />
                );
                break;
            case 'ContentfulSectionCta':
                component = <CTASection data={sectionData} key={index} />;
                break;
            case 'ContentfulSectionMessage':
                component = <MessageSection data={sectionData} key={index} />;
                break;
            case 'ContentfulSectionLinkCards':
                component = (
                    <LinksCards
                        data={sectionData}
                        jobs={props.jobs}
                        key={index}
                    />
                );
                break;
            case 'ContentfulSectionContact':
                component = (
                    <div
                        className="contact-section"
                        key={index}
                        minHeight="439px">
                        <ContactSection data={sectionData} />
                    </div>
                );
                break;
            case 'ContentfulSectionMediaAndText':
                component = <MediaAndText key={index} data={sectionData} />;
                break;
            case 'ContentfulSectionHeroBannerSmall':
                component = (
                    <HeroBannerSmall
                        key={index}
                        data={sectionData}
                        currentPage={props.pageData}
                    />
                );
                break;
            case 'ContentfulSectionQuickJumpContentBlocks':
                component = (
                    <QuickJumpContentBlocks key={index} data={sectionData} />
                );
                break;
            case 'ContentfulSectionContent':
                component = <ContentSection key={index} data={sectionData} />;
                break;
            case 'ContentfulSectionPartnerCarousel':
                component = (
                    <ParterCarouselSection key={index} data={sectionData} />
                );
                break;
            case 'ContentfulSectionComparisonTable':
                component = (
                    <ComparisonTableSection key={index} data={sectionData} />
                );
                break;
            case 'ContentfulHeroDynamicImageOnTheRight':
                component = (
                    <HeroDynamicImageOnTheRight
                        key={index}
                        data={sectionData}
                    />
                );
                break;
            case 'ContentfulSectionFeaturesAndReviews':
                component = (
                    <FeaturesAndReviewSection
                        key={sectionData.id}
                        data={sectionData}
                    />
                );
                break;
            case 'ContentfulSectionLinkCollection':
                component = (
                    <LinkCollectionSection
                        key={sectionData.id}
                        data={sectionData}
                    />
                );
                break;
            default:
                component = null;
                break;
        }
        return component;
    });
    return result;
};

export default WebsitePage;

export const query = graphql`
    query WebsitePageQuery($id: String!) {
        contentfulPage(id: { eq: $id }) {
            ...PageData
        }
        contentfulGlobalVariables {
            ...GlobalVariables
        }
        allContentfulAsset {
            edges {
                node {
                    file {
                        url
                        fileName
                    }
                }
            }
        }
        applyForm: contentfulForm(type: { eq: "customer_signup_form" }) {
            ...Form
        }
        termLoansForm: contentfulForm(type: { eq: "Term Loans Form" }) {
            ...Form
        }
        loginForm: contentfulForm(type: { eq: "Customer Login Form" }) {
            ...Form
        }
        resetForm: contentfulForm(
            type: { eq: "Customer Password Reset Form" }
        ) {
            ...Form
        }
        contentfulFeatureManagementSystem(
            name: { eq: "Feature Management System" }
        ) {
            ...FeatureManagementSystem
        }
        contentfulHreflangManagement(name: { eq: "Hreflang Management" }) {
            ...HreflangManagement
        }
        allContentfulVariable {
            edges {
                node {
                    id
                    name
                    variableId
                    initialValue {
                        initialValue
                    }
                    fallbackValue {
                        fallbackValue
                    }
                }
            }
        }
        jobs: allWorkableJob {
            edges {
                node {
                    id
                    title
                    department
                    url
                }
            }
        }
    }

    fragment PageData on ContentfulPage {
        title
        url
        titleTag: title
        canonicalTag
        metaDescription
        allowSearchEngineScraping
        pageContent {
            header {
                ...Header
            }
            sections {
                __typename
                ...SectionHeroBanner
                ...SectionMetrics
                ...SectionHowItWorksV2
                ...SectionHowItWorksScroll
                ...SectionCustomerReviews
                ...SectionRepaymentCalculator
                ...SectionCTA
                ...SectionMessage
                ...SectionLinkCards
                ...SectionContact
                ...SectionMediaAndText
                ...SectionHeroBannerSmall
                ...SectionQuickJumpContentBlocks
                ...SectionContent
                ...SectionPartnerCarousel
                ...SectionComparisonTable
                ...HeroDynamicImageOnTheRight
                ...SectionFeaturesAndReviews
                ...SectionLinkCollection
                ... on ContentfulSectionSet {
                    sections {
                        __typename
                        ...SectionHeroBanner
                        ...SectionMetrics
                        ...SectionCustomerReviews
                        ...SectionRepaymentCalculator
                    }
                }
            }
            activateSignupModal
            activateTermLoansModal
            activateLoginModal
            activatePasswordResetModal
        }
    }

    fragment GlobalVariables on ContentfulGlobalVariables {
        labelCurrency
        labelMonthSingular
        labelMonthPlural
        thousandsSeparator
        decimalSeparator
        primaryColour
        secondaryColour
        accountPageUrl
        trustpilotFallbackScore
        browserUpdatePhrase
    }

    fragment FeatureManagementSystem on ContentfulFeatureManagementSystem {
        id
        name
    }

    fragment HreflangManagement on ContentfulHreflangManagement {
        id
        redirections {
            internal {
                content
            }
        }
    }

    fragment Header on ContentfulHeader {
        logoLinks {
            ...LogoLink
        }
        menuTitle
        menu {
            menuList {
                ...Link
                ...Page
            }
        }
        contactLinks {
            ...ContactLink
        }
        standardButtons {
            ...Button
        }
        scrollButton {
            ...Button
        }
        mobileContactLinks {
            ...ContactLink
        }
    }

    fragment Form on ContentfulForm {
        id
        type
        title
        subtitle {
            subtitle
        }
        submitButtonText
        contentfulfields {
            __typename
            ...FieldCheckbox
            ...FieldText
            ...FieldSelect
            ...FieldValue
        }
        alternativeActions {
            id
            title
            url
        }
        successMessage {
            ...SectionMessage
        }
        submitButton {
            ...Button
        }
        quoteImageSidecar {
            coverImage {
                file {
                    url
                }
            }
            firstName
            teambusinessName
            quotation
            overlayPresent
        }
        modalHash
    }

    fragment SectionLinkCards on ContentfulSectionLinkCards {
        title
        subtitleText {
            subtitleText
        }
        linkCards {
            ...LinkCard
        }
        cardCallToActionIcon {
            file {
                url
            }
        }
    }

    fragment SectionHeroBanner on ContentfulSectionHeroBanner {
        headline
        subtitleText: subtitle {
            subtitle
        }
        mobileSubtitle
        shortTitle
        shortTitleMenu {
            name
            menuList {
                ...Page
            }
        }
        button {
            ...Button
        }
        secondaryButton {
            ...Button
        }
        supportingStrapline {
            ...SupportingStrapline
        }
        content {
            keyword
            highlightColour
            image {
                file {
                    url
                }
                fixed(width: 1800, quality: 90) {
                    width
                    height
                    src
                }
            }
        }
        advancedSettings {
            heroBannerVersion: version
        }
    }

    fragment SectionMetrics on ContentfulSectionFeaturesBar {
        id
        name
        title
        subtitle
        feature {
            ...Feature
        }
    }

    fragment SectionHowItWorksV2 on ContentfulSectionHowItWorksV2 {
        headline
        steps {
            ...HowItWorksStep
        }
    }

    fragment SectionHowItWorksScroll on ContentfulSectionHowItWorksScroll {
        id
        name
        headline
        subheadline
        isScroll
        deviceImage {
            file {
                url
            }
        }
        deviceImageTablet {
            file {
                url
            }
        }
        deviceImageMobile {
            file {
                url
            }
        }
        steps {
            ...HowItWorksStep
        }
    }

    fragment SectionCustomerReviews on ContentfulSectionCustomerReviews {
        id
        name
        title
        legacySubtitle {
            legacySubtitle
        }
        reviews {
            ...Review
        }
        logoBar {
            ...LogoLink
        }
    }

    fragment Review on ContentfulReviewItem {
        id
        title
        customerName
        customerImage {
            file {
                url
            }

            fixed(width: 560, quality: 100) {
                base64
                aspectRatio
                width
                height
                src
                srcSet
                srcWebp
                srcSetWebp
            }
        }
        customerJobAndCompany
        reviewDate
        text {
            text
        }
        legacyUrl
    }

    fragment SectionRepaymentCalculator on ContentfulSectionRepaymentCalculator {
        id
        name
        title
        subtitle
        legacyEuLogo {
            file {
                url
            }
        }
        legacyEuLogo2 {
            file {
                url
            }
        }
        legacyEuText {
            legacyEuText
        }
        legacyBorrowText {
            legacyBorrowText
        }
        footerText {
            footerText
        }
        calculators {
            ...RepaymentCalculator
        }
    }

    fragment RepaymentCalculator on ContentfulRepaymentCalculator {
        id
        title
        subtitle
        iWantText
        forText
        monthlyRepaymentText
        monthlyRepaymentTextPlural
        principal
        interest
        showTable
        fees
        footerText {
            footerText
        }
        minAmount
        maxAmount
        minTime
        maxTime
        feesPercentage
        type
        jsonOverride {
            interestRates
        }
        ctaButton {
            ...Button
        }
        showInterestRateSlider
    }

    fragment SectionCTA on ContentfulSectionCta {
        id
        title
        subtitle
        ctaButtonStandard {
            ...Button
        }
        ctaButtonLoggedIn {
            ...Button
        }
    }

    fragment SectionContact on ContentfulSectionContact {
        id
        name
        title
        subtitleText: subtitle {
            subtitle
        }
        profilePictures {
            id
            name
            altText
            profilePictureImage {
                file {
                    url
                }
            }
        }
        contactLinks {
            ...ContactLink
        }
        legacyImage {
            file {
                url
            }
            fixed(width: 1440, quality: 90) {
                width
                height
                src
            }
            description
        }
    }

    fragment VideoAndQuote on ContentfulVideoAndQuote {
        id
        name
        highlightText
        highlightColour
        firstName
        lastName
        teambusinessName
        quotation
        youTubeVideoId
        coverImage {
            file {
                url
            }
        }
    }

    fragment Link on ContentfulLink {
        id
        title
        url
        icon {
            file {
                url
            }
        }
    }

    fragment Page on ContentfulPage {
        id
        title
        pageName
        url
    }

    fragment BuildingAddress on ContentfulBuildingAddress {
        id
        url
        address {
            address
        }
    }

    fragment ContactLink on ContentfulContactLink {
        id
        name
        contactMethodIcon {
            file {
                url
            }
        }
        linkText
        url
    }

    fragment Button on ContentfulButton {
        id
        buttonText
        url
        icon {
            file {
                url
            }
        }
    }

    fragment SupportingStrapline on ContentfulSupportingStrapline {
        id
        name
        icon {
            file {
                url
            }
        }
        strapline {
            id
            strapline
        }
    }

    fragment SocialMediaLink on ContentfulSocialMediaLink {
        id
        name
        linkText
        url
        socialMediaIcon {
            file {
                url
            }
        }
    }

    fragment LogoLink on ContentfulLogoOrSectionLink {
        id
        name
        url
        logo {
            file {
                url
            }
        }
    }

    fragment FieldCheckbox on ContentfulFieldCheckbox {
        id
        name
        fieldType
        statement {
            statement
        }
        defaultValue
        requiredField
    }

    fragment FieldText on ContentfulFieldText {
        id
        name
        fieldType
        label
        placeholder
        prefix
        errorMessage
        errorMessages {
            ...ErrorMessage
        }
        requiredField
    }

    fragment FieldValue on ContentfulFieldValue {
        id
        name
        fieldType
        label
        placeholder
        prefix
        errorMessage
        errorMessages {
            ...ErrorMessage
        }
        requiredField
        minValue
        maxValue
    }

    fragment FieldSelect on ContentfulFieldSelect {
        id
        name
        fieldType
        label
        placeholder
        errorMessage
        requiredField
        options {
            ...FieldSelectOptions
            ...FieldText
        }
    }

    fragment FieldSelectOptions on ContentfulFieldSelectOptions {
        id
        name
        text
        apiValue
        icon {
            file {
                url
            }
        }
    }

    fragment Feature on ContentfulFeature {
        id
        icon {
            file {
                url
            }
        }
        featureTitle {
            featureTitle
        }
        description {
            description
        }
    }

    fragment SectionMessage on ContentfulSectionMessage {
        id
        name
        title
        description {
            description
        }
        supportingImage {
            file {
                url
            }
        }
    }

    fragment ErrorMessage on ContentfulErrorMessage {
        id
        condition
        errorMessage {
            errorMessage
        }
    }

    fragment LinkCard on ContentfulLinkCard {
        id
        title
        description {
            description
        }
        callToAction
        url
        preTitle
        image {
            file {
                url
            }
        }
    }

    fragment PointOfInterestTransaction on ContentfulPointOfInterestTransaction {
        id
        location {
            lon
            lat
        }
        amount
        description
    }

    fragment HowItWorksStep on ContentfulHowItWorksStep {
        icon {
            fluid(maxWidth: 75, quality: 100) {
                ...GatsbyContentfulFluid_withWebp_noBase64
            }
        }
        mobileIcon {
            fluid(maxWidth: 75, quality: 100) {
                ...GatsbyContentfulFluid_withWebp_noBase64
            }
        }
        title
        description {
            description
        }
        banner {
            file {
                url
            }
        }
        bannerMobile {
            file {
                url
            }
        }
        stepNumber {
            file {
                url
            }
        }
        callToActionLinkText
        callToActionLinkUrl
        callToActionLinkIcon {
            file {
                url
            }
        }
    }

    fragment SectionContent on ContentfulSectionContent {
        headline
        contentBlocks {
            ...ContentBlock
        }
        features {
            ...Feature
        }
    }

    fragment ContentBlock on ContentfulContentBlock {
        name
        title
        content {
            content
        }
    }

    fragment TileCard on ContentfulTileCard {
        __typename
        title
        description {
            description
        }
        image {
            file {
                url
            }
        }
        isBig
    }

    fragment SectionMediaAndText on ContentfulSectionMediaAndText {
        heading
        bodyText {
            bodyText
        }
        image {
            file {
                url
            }
        }
        videoPlayButton {
            file {
                url
            }
        }
        youtubeVideoId
        mediaAndTextAlignment
    }

    fragment SectionHeroBannerSmall on ContentfulSectionHeroBannerSmall {
        shortTitle
        shortTitleMenu {
            menuList {
                ...Link
                ...Page
            }
        }
        headline
        subtitleText: subtitle {
            subtitle
        }
        content {
            __typename
            ...HeroImage
        }
        button {
            ...Button
        }
        selectTextAlignment
        selectTextColourScheme
    }

    fragment HeroImage on ContentfulHeroImage {
        keyword
        image {
            file {
                url
            }
        }
        highlightColour
    }

    fragment SectionQuickJumpContentBlocks on ContentfulSectionQuickJumpContentBlocks {
        introduction {
            introduction
        }
        contentBlocks {
            id
            title
            content {
                id
                content
            }
            contentBlocks {
                id
                title
                content {
                    id
                    content
                }
            }
        }
    }

    fragment SectionPartnerCarousel on ContentfulSectionPartnerCarousel {
        title
        partnerCategory {
            id
            title
            partnerBoxes {
                id
                title
                logoImage {
                    file {
                        url
                    }
                }
                description {
                    description
                }
            }
        }
        leftChevron {
            file {
                url
            }
        }
        rightChevron {
            file {
                url
            }
        }
    }

    fragment SectionComparisonTable on ContentfulSectionComparisonTable {
        title
        subheading
        options {
            ...ComparisonOption
        }
    }

    fragment ComparisonOption on ContentfulComparisonOption {
        id
        title
        subheading
        text
        ctaButton {
            ...Button
        }
        bulletpointsHeadline
        bulletpoints {
            ...Bulletpoint
        }
        findOutMoreText
    }

    fragment Bulletpoint on ContentfulBulletpoint {
        id
        icon {
            file {
                url
            }
        }
        text
    }

    fragment HeroDynamicImageOnTheRight on ContentfulHeroDynamicImageOnTheRight {
        id
        name
        preHeadline
        headline
        images {
            title
            file {
                url
            }
        }
    }
    fragment SectionFeaturesAndReviews on ContentfulSectionFeaturesAndReviews {
        id
        name
        title
        features {
            ...Feature
        }
        testimonials {
            ...Review
        }
        backgroundImage {
            file {
                url
            }
        }
    }
    fragment SectionLinkCollection on ContentfulSectionLinkCollection {
        id
        name
        title
        links {
            ...LinkCard
            ... on ContentfulBlogArticle {
                id
                articleTitle
                slug
                subtitle {
                    subtitle
                }
                articleHeaderImage {
                    file {
                        url
                    }
                }
            }
        }
    }
`;
