import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import ReactMarkdown from 'react-markdown';
import {
    BasicCircle,
    BasicSquare,
    BasicArch,
    BasicDoughnut,
    BasicGrid
} from '../../../../SVGComponents/shapes';
import styles from './EmailUs.module.scss';

function useEmailUs() {
    const { contentfulEmailUs } = useStaticQuery(
        graphql`
            {
                contentfulEmailUs {
                    heading
                    subheading {
                        subheading
                    }
                    ctaText
                    successText
                    policy {
                        policy
                    }
                }
            }
        `
    );
    return contentfulEmailUs;
}

const EmailUs = () => {
    const { heading, subheading, policy, ctaText, successText } = useEmailUs();
    const hubspotNewsLetterId = '3901e8c2-c504-45a6-854c-cc64bec91fc8';

    return (
        <div className={styles.EmailUs}>
            <div className={styles.Container}>
                <BasicGrid className={styles.BasicGrid} />
                <BasicCircle
                    className={styles.BasicCircle}
                    size="xs"
                    color="denim"
                />
                <h2>{heading}</h2>
                <p>{subheading.subheading}</p>
                <ReactMarkdown source={policy} />

                <BasicSquare
                    className={styles.BasicCoralSquare}
                    size="sm"
                    rotate="left"
                />
                <BasicSquare
                    className={styles.BasicLondonSquare}
                    size="sm"
                    color="london"
                />
                <BasicDoughnut
                    className={styles.BasicDoughnut}
                    color="snow"
                    size="xs"
                />
                <BasicArch
                    rotate="right"
                    className={styles.BasicArch}
                    color="london"
                />
            </div>
        </div>
    );
};

export default EmailUs;
