import React from 'react';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import Button from '../../common/Button';
import CalculatorMonthsTable from './CalculatorMonthsTable';
import { getLoanCalculatorInterestRates } from '../../../util/localization';
import {
    getRepaymentDetails,
    sendSliderValueToGA
} from '../../../util/loanCalculator';
import { formatMoney } from 'aphrodite-shared/util/helpers';
import styles from './CreditLineCalculator.module.scss';

/* eslint-disable */

class CreditLineCalculator extends React.Component {
    constructor(props) {
        super(props);

        const { data } = this.props;

        const monthSliderInitialValue = 4;
        const monthSliderValue =
            monthSliderInitialValue > this.getMinDuration()
                ? monthSliderInitialValue
                : this.getMinDuration();
        const interestSliderInitialValue = 3;

        // Get interest from JSON Override and otherwise uses predefined values
        if (data.jsonOverride !== null && data.jsonOverride.interestRates) {
            this.rates = data.jsonOverride.interestRates;
        } else {
            this.rates = getLoanCalculatorInterestRates();
        }

        const interestSliderValue =
            this.props.data.showInterestRateSlider === true
                ? interestSliderInitialValue
                : getLoanCalculatorInterestRates();

        this.state = {
            amountSliderValue: 21,
            monthSliderValue,
            interestSliderValue,
            monthsRepayment: [],
            fees: 0
        };

        this.disableVerticalScroll = this.disableVerticalScroll.bind(this);
        this.getMinAmount = this.getMinAmount.bind(this);
        this.getMaxAmount = this.getMaxAmount.bind(this);
        this.getMinDuration = this.getMinDuration.bind(this);
        this.getMaxDuration = this.getMaxDuration.bind(this);
        this.selectedAmount = this.selectedAmount.bind(this);
        this.roundAndParseAmountValue = this.roundAndParseAmountValue.bind(
            this
        );

        this.calcMoneyAmountWanted = this.calcMoneyAmountWanted.bind(this);
        this.onSliderChange = this.onSliderChange.bind(this);
    }

    componentDidMount() {
        // Disable vertical scroll on mobile while moving the slider
        const sliders = document.getElementsByClassName('rc-slider');
        for (let i = 0; i < sliders.length; i++) {
            sliders[i].addEventListener(
                'touchmove',
                this.disableVerticalScroll
            );
        }
    }

    componentWillUnmount() {
        // Cleanup vertical scroll disable on mobile while moving the slider
        const sliders = document.getElementsByClassName('rc-slider');
        for (let i = 0; i < sliders.length; i++) {
            sliders[i].removeEventListener(
                'touchmove',
                this.disableVerticalScroll
            );
        }
    }

    // Disable vertical scrolling
    disableVerticalScroll() {
        e.preventDefault();
    }

    // Get minimum possible amount
    getMinAmount() {
        const minAmountFallback = 1000;
        return this.props.data.minAmount || minAmountFallback;
    }

    // Get maximum possible amount
    getMaxAmount() {
        const maxAmountFallback = 100000;
        return this.props.data.maxAmount || maxAmountFallback;
    }

    // Get minimum possible duration
    getMinDuration() {
        const minTimeFallback = 1;
        return this.props.data.minTime || minTimeFallback;
    }

    // Get maximum possible duration
    getMaxDuration() {
        const maxTimeFallback = 12;
        return this.props.data.maxTime || maxTimeFallback;
    }

    // Determine which amount is selected
    selectedAmount(position) {
        return Math.pow(
            position *
                (this.getMaxAmount() ** 0.4 - this.getMinAmount() ** 0.4) +
                Math.pow(this.getMinAmount(), 0.4),
            2.5
        );
    }

    roundAndParseAmountValue(
        value,
        valueToRoundAmountTo = this.props.valueToRoundAmountTo
    ) {
        return (
            Math.floor(
                this.selectedAmount(value / 100) / valueToRoundAmountTo
            ) * valueToRoundAmountTo
        );
    }

    // Amount of the "I want" slider
    calcMoneyAmountWanted() {
        const { amountSliderValue } = this.state;

        return this.roundAndParseAmountValue(amountSliderValue);
    }

    // Handle changes of sliders
    onSliderChange(values = {}) {
        const {
            amountSliderValue = this.state.amountSliderValue,
            monthSliderValue = this.state.monthSliderValue,
            interestSliderValue = this.state.interestSliderValue
        } = values;
        this.updateSliderStates(
            amountSliderValue,
            monthSliderValue,
            interestSliderValue
        );
    }

    updateSliderStates(
        amountSliderValue,
        monthSliderValue,
        interestSliderValue
    ) {
        this.setState({
            amountSliderValue,
            monthSliderValue,
            interestSliderValue
        });
    }

    render() {
        const { data, globalData, enableTable } = this.props;
        const {
            amountSliderValue,
            monthSliderValue,
            interestSliderValue
        } = this.state;

        return (
            <section className={styles.loanCalculatorLegacy}>
                <div className={styles.calculatorBox}>
                    <div className={styles.leftSide}>
                        <div
                            className={`amount-slider-box ${styles.amountSliderBox}`}>
                            <div className={styles.sliderBoxLeft}>
                                <Slider
                                    onChange={(value) =>
                                        this.onSliderChange({
                                            amountSliderValue: value
                                        })
                                    }
                                    value={amountSliderValue}
                                    min={0}
                                    max={100}
                                    step={0.1}
                                    onAfterChange={(value) =>
                                        sendSliderValueToGA(
                                            this.roundAndParseAmountValue(
                                                // easing
                                                value
                                            ),
                                            'amountSlider'
                                        )
                                    }
                                />
                                <div className={styles.flexText}>
                                    <span
                                        className={`calculator-box__value ${styles.calculatorBoxValue}`}>
                                        {formatMoney(this.getMinAmount())}
                                    </span>
                                    <span
                                        className={`calculator-box__value ${styles.calculatorBoxValue}`}>
                                        {formatMoney(this.getMaxAmount())}
                                    </span>
                                </div>
                            </div>
                            <input
                                className={styles.sliderBoxRight}
                                type="text"
                                disabled
                                onChange={(e) =>
                                    this.onSliderChange({
                                        amountSliderValue: e.target.value
                                    })
                                }
                                value={this.calcMoneyAmountWanted()}
                                min={0}
                                max={100}
                            />
                        </div>
                        <div
                            className={`months-slider-box ${styles.monthsSliderBox}`}>
                            <div className={styles.sliderBoxLeft}>
                                <Slider
                                    onChange={(value) =>
                                        this.onSliderChange({
                                            monthSliderValue: value
                                        })
                                    }
                                    value={monthSliderValue}
                                    min={this.getMinDuration()}
                                    max={this.getMaxDuration()}
                                    step={1}
                                    onAfterChange={(value) =>
                                        sendSliderValueToGA(
                                            value,
                                            'durationSlider'
                                        )
                                    }
                                />
                                <div className={styles.flexText}>
                                    <span
                                        className={`calculator-box__value ${styles.calculatorBoxValue}`}>
                                        {`${this.getMinDuration()} ${
                                            globalData.labelMonthSingular
                                        }`}
                                    </span>
                                    <span
                                        className={`calculator-box__value ${styles.calculatorBoxValue}`}>
                                        {`${this.getMaxDuration()} ${
                                            globalData.labelMonthPlural
                                        }`}
                                    </span>
                                </div>
                            </div>
                            <input
                                className={styles.sliderBoxRight}
                                type="text"
                                disabled
                                onChange={(e) =>
                                    this.onSliderChange({
                                        monthSliderValue: e.target.value
                                    })
                                }
                                value={`${monthSliderValue} ${
                                    monthSliderValue === 1
                                        ? globalData.labelMonthSingular
                                        : globalData.labelMonthPlural
                                }`}
                                min={this.getMinDuration()}
                                max={this.getMaxDuration()}
                            />
                        </div>
                        {data.showInterestRateSlider && (
                            <div className={styles.interestSliderBox}>
                                <div className={styles.flexText}>
                                    <span>Interest</span>
                                    <span
                                        className={`calculator-box__value ${styles.calculatorBoxValue}`}>
                                        {`${interestSliderValue}% per month`}
                                    </span>
                                </div>
                                <Slider
                                    onChange={(value) =>
                                        this.onSliderChange({
                                            interestSliderValue: value
                                        })
                                    }
                                    value={interestSliderValue}
                                    min={2}
                                    max={5.7}
                                    step={0.01}
                                />
                            </div>
                        )}
                    </div>
                    {!enableTable && (
                        <CalculatorMonthsTable
                            loanAmount={formatMoney(
                                this.calcMoneyAmountWanted()
                            )}
                            data={data}
                            globalData={globalData}
                            repaymentDetails={getRepaymentDetails(
                                this.calcMoneyAmountWanted(),
                                monthSliderValue,
                                interestSliderValue,
                                globalData
                            )}
                            formatMoney={formatMoney}
                        />
                    )}
                </div>
                {data.ctaButton && (
                    <Button
                        key={data.ctaButton.id}
                        href={data.ctaButton.url}
                        title={data.ctaButton.buttonText}
                        colour="primary"
                        dataGaId="calculator__cta"
                        className={styles.button}>
                        {data.ctaButton.buttonText}
                    </Button>
                )}
            </section>
        );
    }
}

CreditLineCalculator.defaultProps = {
    data: {},
    enableTable: false,
    valueToRoundAmountTo: 1000
};

export default CreditLineCalculator;
