import React from 'react';
import CommerzbankCTABox from '../PartnersCTABox/PartnersCTABox';
import styles from './BelowHeaderCTA.module.scss';
import Button from '../../../../components/common/Button';

const BelowHeaderCTA = ({
    firstPDFLabel,
    firstPDF,
    secondPDFLabel,
    secondPDF,
    thirdPDFLabel,
    thirdPDF,
    partnerSignupLabel,
    partnerSignupLink,
    underCTAText
}) => {
    return (
        <React.Fragment>
            <div className={styles.mobileButton}>
                <Button href={partnerSignupLink} colour="secondary" newTab>
                    {partnerSignupLabel}
                </Button>
            </div>
            <div className={styles.BelowHeaderCTA}>
                <CommerzbankCTABox
                    firstPDFLabel={firstPDFLabel}
                    firstPDF={firstPDF}
                    secondPDFLabel={secondPDFLabel}
                    secondPDF={secondPDF}
                    thirdPDFLabel={thirdPDFLabel}
                    thirdPDF={thirdPDF}
                    partnerSignupLabel={partnerSignupLabel}
                    partnerSignupLink={partnerSignupLink}
                />
            </div>
        </React.Fragment>
    );
};

export default BelowHeaderCTA;
