import React, { PureComponent } from 'react';
import { graphql } from 'gatsby';
import pressPageComponentParser from './pressPageComponentParser';
import Layout from '../components/Layout/Layout';
import '../scss/legacy/globalStyles/globalStyles.scss';
import { fetchIsLoggedIn } from '../util/authHelpers';

class WebsitePage extends PureComponent {
    render() {
        const { data } = this.props;

        const globalVariables = data.contentfulGlobalVariables;

        const {
            contentfulPage,
            contentfulFeatureManagementSystem,
            contentfulHreflangManagement
        } = data;

        const {
            titleTag,
            metaDescription,
            allowSearchEngineScraping,
            url,
            canonicalTag
        } = contentfulPage;

        const pageData = data.contentfulPage || {};
        const { header = {}, sections = [] } = pageData.pageContent;

        const isLoggedIn = fetchIsLoggedIn();

        // Sections
        const sectionsComponents = renderSections(sections, globalVariables, {
            pageData
        });

        // Forms
        const { applyForm, loginForm, resetForm, termLoansForm } = data;

        return (
            <Layout
                headerData={header}
                data={data}
                userIsLoggedIn={isLoggedIn}
                globalVariables={globalVariables}
                modals={{
                    applyForm,
                    loginForm,
                    resetForm,
                    termLoansForm
                }}
                metaData={{
                    titleTag,
                    metaDescription,
                    allowSearchEngineScraping,
                    url,
                    canonicalTag,
                    contentfulHreflangManagement
                }}
                featureFlags={contentfulFeatureManagementSystem}>
                {sectionsComponents}
            </Layout>
        );
    }
}

const renderSections = (sections, globalLabels, props) => {
    if (sections === null || sections.length < 1) return null;

    const result = sections.map((section, index) => {
        const sectionData = Object.assign({}, section, globalLabels);

        return pressPageComponentParser(section.__typename, {
            props,
            sectionData,
            index
        });
    });
    return result;
};

export default WebsitePage;

export const query = graphql`
    query PressPageQuery($id: String!) {
        contentfulPage(id: { eq: $id }) {
            ...PressPageData
        }
        contentfulGlobalVariables {
            ...GlobalVariables
        }
        allContentfulAsset {
            edges {
                node {
                    file {
                        url
                        fileName
                    }
                }
            }
        }
        applyForm: contentfulForm(type: { eq: "customer_signup_form" }) {
            ...Form
        }
        termLoansForm: contentfulForm(type: { eq: "Term Loans Form" }) {
            ...Form
        }
        loginForm: contentfulForm(type: { eq: "Customer Login Form" }) {
            ...Form
        }
        resetForm: contentfulForm(
            type: { eq: "Customer Password Reset Form" }
        ) {
            ...Form
        }
        contentfulFeatureManagementSystem(
            name: { eq: "Feature Management System" }
        ) {
            ...FeatureManagementSystem
        }
        contentfulHreflangManagement(name: { eq: "Hreflang Management" }) {
            ...HreflangManagement
        }
        allContentfulVariable {
            edges {
                node {
                    id
                    name
                    variableId
                    initialValue {
                        initialValue
                    }
                    fallbackValue {
                        fallbackValue
                    }
                }
            }
        }
    }

    fragment PressPageData on ContentfulPage {
        title
        url
        titleTag: title
        metaDescription
        allowSearchEngineScraping
        pageContent {
            header {
                ...Header
            }
            sections {
                __typename
                ...SectionHeroBannerSmall
                #...SectionNavigationCards
                ...SectionFeaturedArticle
                ...PressContactBox
                ...SectionBlogPostCards
            }
            activateSignupModal
            activateTermLoansModal
            activateLoginModal
            activatePasswordResetModal
        }
    }

    fragment SectionNavigationCards on ContentfulSectionNavigationCards {
        id
        name
        navigationCards {
            ...NavigationCards
        }
    }

    fragment NavigationCards on ContentfulNavigationCards {
        id
        name
        icon {
            file {
                url
            }
        }
        title
        description {
            description
        }
        url {
            url
            title
        }
    }

    fragment SectionFeaturedArticle on ContentfulSectionFeaturedArticle {
        id
        headline
        backgroundImage {
            file {
                url
            }
        }
        articleTitle
        articleUrl
        articleTopic
        publicationDate
        articleDescription
        logo {
            file {
                url
            }
        }
    }

    fragment PressContactBox on ContentfulPressContactBox {
        title
        subtitle
        contactLink1 {
            contactLink1
        }
        contactLink2 {
            contactLink2
        }
        contactLink3 {
            contactLink3
        }
    }

    fragment SectionBlogPostCards on ContentfulSectionBlogPostCards {
        title
        blogCards {
            id
            title
            url
            blogCardImages {
                smallImage {
                    file {
                        url
                    }
                }
                wideImage {
                    file {
                        url
                    }
                }
            }
        }
        pressButton {
            ...Button
        }
    }
`;
