import React from 'react';
import ReactMarkdown from 'react-markdown';
import styles from './MediaAndText.module.scss';

class MediaAndText extends React.Component {
    constructor() {
        super();
        this.state = {
            isPlayingVideo: false
        };
        this.playVideo = this.playVideo.bind(this);
        this.stopVideo = this.stopVideo.bind(this);
    }

    playVideo() {
        this.setState(
            {
                isPlayingVideo: true
            },
            () => {
                const element = document.querySelector(
                    `.${styles.mediaSection} .${styles.video}`
                );
                element.contentWindow.postMessage(
                    '{"event":"command","func":"' +
                        'playVideo' +
                        '","args":""}',
                    '*'
                );
            }
        );
    }

    stopVideo() {
        const element = document.querySelector(
            `.${styles.mediaSection} .${styles.video}`
        );
        element.contentWindow.postMessage(
            '{"event":"command","func":"stopVideo","args":""}',
            '*'
        );

        setTimeout(() => {
            this.setState({
                isPlayingVideo: false
            });
        }, 500);
    }

    render() {
        const { data } = this.props;
        const { isPlayingVideo } = this.state;

        return (
            <div
                className={`${styles.mediaSection} ${
                    !data.mediaAndTextAlignment ? styles.rowReverse : styles.row
                }`}>
                <div
                    className={styles.media}
                    style={{
                        backgroundImage: `url("${data.image.file.url}")`
                    }}>
                    {data.youtubeVideoId && (
                        <div>
                            <button
                                className={styles.playButton}
                                style={{
                                    backgroundImage: `url("${data.videoPlayButton.file.url}")`
                                }}
                                onClick={this.playVideo}
                                onTouchStart={this.playVideo}
                            />
                            <iframe
                                className={`${styles.video} ${
                                    isPlayingVideo ? styles.play : ''
                                }`}
                                title="embeded video"
                                width="100%"
                                height="100%"
                                src={`https://www.youtube-nocookie.com/embed/${data.youtubeVideoId}?wmode=transparent&rel=0&showinfo=0&iv_load_policy=3&modestbranding=1&enablejsapi=1`}
                                frameBorder="0"
                                allowFullScreen="1"
                            />
                        </div>
                    )}
                </div>
                <div className={styles.textContainer}>
                    <h3 className={styles.title}>{data.heading}</h3>
                    <ReactMarkdown
                        className={styles.content}
                        source={data.bodyText.bodyText}
                    />
                </div>
            </div>
        );
    }
}

export default MediaAndText;
