import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import IwocaLogoSVG from '../../../SVGComponents/IwocaLogoSVG';
import IwLink from '../../IwLink/IwLink';
import ReactMarkdown from 'react-markdown';
import styles from './SimpleFooter.module.scss';

function useFooterData() {
    const { contentfulGenericLpFooter } = useStaticQuery(
        graphql`
            {
                contentfulGenericLpFooter {
                    address {
                        address
                    }
                    links {
                        url
                        title
                    }
                    disclaimer {
                        disclaimer
                    }
                }
            }
        `
    );

    return contentfulGenericLpFooter;
}

const SimpleFooter = () => {
    const { address, links, disclaimer } = useFooterData();
    return (
        <footer className={styles.SimpleFooter}>
            <div className={styles.logo}>
                <IwLink to="/">
                    <IwocaLogoSVG className={styles.logo} />
                </IwLink>
                <ReactMarkdown source={address.address} />
            </div>
            <div className={styles.links}>
                {links.map(({ title, url }) => {
                    return (
                        <IwLink to={url} dataGaId="footer__link">
                            {title}
                        </IwLink>
                    );
                })}
            </div>
            <div className={styles.disclaimer}>
                <ReactMarkdown source={disclaimer.disclaimer} />
            </div>
        </footer>
    );
};

export default SimpleFooter;
