import React from 'react';
import { graphql } from 'gatsby';
import classnames from 'classnames';
import MetaData from '../../../components/Layout/MetaData.jsx';
import NavBar from '../../../components/NavBar/NavBar';
import HowItWorks from './HowItWorks/HowItWorks';
import Header from './Header/Header';
import Features from './Features/Features';
import Quote from './Quote/Quote';
import CalculatorSection from '../common/CalculatorSection/CalculatorSection';
import WantToKnowMore from '../common/WantToKnowMore/WantToKnowMore';
import ReadAboutUs from '../common/ReadAboutUs/ReadAboutUs';
import Questions from '../common/Questions/Questions';
import SimpleFooter from '../../../components/Footer/SimpleFooter/SimpleFooter';
import { fetchIsLoggedIn } from '../../../util/authHelpers';
import styles from './GenericLanding.module.scss';
import GenericLandingEbayTheme from './Themes/Ebay/GenericLandingEbayTheme';

const GenericLanding = ({ data }) => {
    const { contentfulPageGenericLanding: pageData, trustpilot } = data;
    const {
        title,
        metaDescription,
        allowSearchEngineScraping,
        url,
        theme
    } = pageData;

    if (theme === 'Ebay' || theme === 'Qonto')
        return <GenericLandingEbayTheme data={data} />;

    const isLoggedIn = fetchIsLoggedIn();

    // TODO: Move this to contentful
    const mainCtaButton = {
        text: pageData.headerButton,
        href:
            (url === '/iwoca-cycling-club/' &&
                'https://share.hsforms.com/1dLOSoHw7QNa_KBK5oTQ7Dg3rrot') ||
            (url === '/ebay-flexikapital/' && 'https://www.ebay.de/')
    };

    return (
        <div class={classnames(styles.GenericLanding)}>
            <MetaData
                metaData={{
                    titleTag: title,
                    metaDescription,
                    allowSearchEngineScraping,
                    url
                }}
            />
            <NavBar
                loggedIn={isLoggedIn}
                isLandingPage={true}
                hideLoginLink={true}
                hideSignupLink={true}
                partnershipLogo={pageData.partnershipLogo}
                primaryCtaLink={mainCtaButton.href}
                maximumLoanAmount={pageData.maximumLoanAmount}
            />
            <Header
                title={pageData.headerTitle}
                subtitle={pageData.headerSubtitle}
                button={mainCtaButton}
                image={pageData.headerImage}
                theme={theme}
                trustpilot={pageData.trustpilot}
                trustpilotRating={trustpilot.score.trustScore}
                trustpilotReviews={trustpilot.numberOfReviews.total}
                disclaimerText={pageData.headerDisclaimerText}
                additionalTrustElements={pageData.additionalTrustElements}
                partnershipLogo={pageData.partnershipLogo}
            />
            <Features
                title={pageData.featureTitle}
                featureText={pageData.featureText.featureText}
                mobileTitle={pageData.featureMobileTitle}
                features={pageData.features}
            />
            <Quote
                image={pageData.quoteImage}
                quote={pageData.quoteText}
                mobileQuote={pageData.quoteMobileText}
                author={pageData.quoteAuthor}
            />
            <HowItWorks
                howItWorksTitle={pageData.howItWorksTitle}
                image={pageData.howItWorksImage}
                steps={pageData.howItWorksSteps}
            />
            <CalculatorSection
                calculatorCopy={pageData.calculator}
                contactBubble={pageData.contactBubble}
                showArrow={false}
                overflowShapes={{
                    WedgedArchShape: false,
                    LongArchShape: false,
                    SectionArchShape: false
                }}
                maximumLoanAmount={pageData.maximumLoanAmount}
            />

            <WantToKnowMore
                faq={pageData.faqSection.faqs}
                title={pageData.faqSection.title}
                subtitle={pageData.faqSection.subtitle.subtitle}
            />

            <ReadAboutUs
                desktopTitle={pageData.readAboutUsDesktopTitle}
                mobileTitle={pageData.readAboutUsMobileTitle}
                trustpilotRating={trustpilot.score.trustScore}
                pressReleaseIcons={pageData.pressReleaseSection.pressPhotos}
                trustpilotText={pageData.pressReleaseSection.trustpilotText}
            />
            <Questions
                title={pageData.contactUsSection.title}
                content={pageData.contactUsSection.content.content}
                email={pageData.contactUsSection.email}
                phoneNumber={pageData.contactUsSection.phoneNumber}
                showApplyButton={pageData.contactUsSection.showApplyButton}
                showJobsButton={pageData.contactUsSection.showJobsButton}
                jobCTAText={pageData.jobCtaText}
                jobLink={pageData.jobLink}
                loanCTAText={pageData.loanCtaText}
                overflowShapes={{ BasicDoughnutShaoe: false }}
            />
            <SimpleFooter />
        </div>
    );
};

export default GenericLanding;

export const query = graphql`
    query GenericLandingQuery($id: String!) {
        contentfulPageGenericLanding(id: { eq: $id }) {
            theme
            title
            url
            partnershipLogo {
                fluid(maxWidth: 688, quality: 100) {
                    ...GatsbyContentfulFluid_withWebp_noBase64
                }
            }
            metaDescription
            allowSearchEngineScraping
            headerTitle
            headerSubtitle {
                headerSubtitle
            }
            headerButton
            headerDisclaimerText {
                headerDisclaimerText
            }
            headerImage {
                fluid(maxWidth: 688, quality: 100) {
                    ...GatsbyContentfulFluid_withWebp_noBase64
                }
            }
            featureTitle
            featureMobileTitle
            featureText {
                featureText
            }
            features {
                ...HowItWorksStep
            }
            trustpilot
            additionalTrustElements {
                id
                fluid(maxWidth: 640, quality: 100) {
                    ...GatsbyContentfulFluid_withWebp_noBase64
                }
            }
            quoteImage {
                fluid(maxWidth: 688, quality: 100) {
                    ...GatsbyContentfulFluid_withWebp_noBase64
                }
            }
            howItWorksTitle
            howItWorksImage {
                fluid(maxWidth: 688, quality: 100) {
                    ...GatsbyContentfulFluid_withWebp_noBase64
                }
            }
            howItWorksSteps {
                ...HowItWorksStep
            }
            quoteText
            quoteMobileText
            quoteAuthor
            calculator {
                ...calculator
            }
            contactBubble {
                text
                phoneNumber
            }
            faqSection {
                ...faqSection
            }
            readAboutUsDesktopTitle
            readAboutUsMobileTitle
            pressReleaseSection {
                ...pressReleaseSection
            }
            contactUsSection {
                ...contactUsSection
            }
            maximumLoanAmount: maxLoanSize
        }
        trustpilot {
            ...trustPilot
        }
    }
`;
