import React from 'react';
import GatsbyImg from 'gatsby-image';
import defaultImg from './defaultImage.png';
import styles from './CTABox.module.scss';
import Button from '../../../../../components/common/Button';
const CTABox = ({ callToAction, copy, image, key }) => {
    return (
        <div key={key} className={styles.CTABox}>
            <div className={styles.LeftPane}>
                <div className={styles.ContentContainer}>
                    <h5>{copy.copy}</h5>
                    {callToAction && (
                        <Button
                            className={styles.CTAButton}
                            href={callToAction.url}
                            title={callToAction.buttonText}
                            colour="primary"
                            big>
                            {callToAction.buttonText}
                        </Button>
                    )}
                </div>
            </div>
            <div className={styles.RightPane}>
                {image ? (
                    <GatsbyImg fluid={image.fluid} className={styles.Image} />
                ) : (
                    <img src={defaultImg} className={styles.Image} />
                )}
            </div>
        </div>
    );
};

export default CTABox;
