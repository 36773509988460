import React from 'react';
import { FormattedMessage } from 'react-intl';
import { getCountryBuild } from '../../../../util/localization';
import styles from './ReadAboutUs.module.scss';
import TrustPilotLogo from '../../../../components/Press/PressLogos/svg/TrustPilotLogo';
import classnames from 'classnames';

const ReadAboutUs = ({
    desktopTitle,
    mobileTitle,
    trustpilotRating,
    trustpilotText,
    pressReleaseIcons,
    className
}) => {
    return (
        <section className={styles.background}>
            <div className={classnames(styles.ReadAboutUs, className)}>
                <h2 className={styles.hideMobile}>{desktopTitle}</h2>
                <h2 className={styles.showMobile}>{mobileTitle}</h2>
                <hr />
                <div className={styles.logosContainer}>
                    <div className={styles.PressLogo}>
                        <img
                            src={pressReleaseIcons[0].photos[0].file.url}
                            className={styles.ForbesLogo}
                        />
                    </div>
                    <div className={styles.PressLogo}>
                        <img
                            src={pressReleaseIcons[2].photos[0].file.url}
                            className={styles.ForbesLogo}
                        />
                    </div>

                    <div className={styles.trustPilot}>
                        <a
                            href={
                                getCountryBuild() === 'uk'
                                    ? 'https://uk.trustpilot.com/review/iwoca.co.uk'
                                    : 'https://de.trustpilot.com/review/www.iwoca.de'
                            }
                            target="_blank"
                            rel="noopener noreferrer"
                            data-ga-id="readAboutUs__trustpilot">
                            <TrustPilotLogo className={styles.trustPilotLogo} />
                            <p>
                                <FormattedMessage
                                    id="trustpilotText"
                                    defaultMessage={trustpilotText}
                                    values={{ trustpilotRating }}
                                />
                            </p>
                        </a>
                    </div>

                    <div className={styles.PressLogo}>
                        <img
                            src={pressReleaseIcons[1].photos[0].file.url}
                            className={styles.ForbesLogo}
                        />
                    </div>
                    <div className={styles.PressLogo}>
                        <img
                            src={pressReleaseIcons[3].photos[0].file.url}
                            className={styles.ForbesLogo}
                        />
                    </div>
                </div>
            </div>
        </section>
    );
};

export default ReadAboutUs;
