import React, { Component } from 'react';
import PropTypes from 'prop-types';
import IwocaImage from '../../common/IwocaImage';

import styles from './HowItWorksSection.module.scss';

class HowItWorksSection extends Component {
    render() {
        const {
            step: { title, description, callToActionLinkText, banner },
            isVertical
        } = this.props;

        return (
            <section
                className={
                    isVertical
                        ? `${styles.container} ${styles.container__vertical}`
                        : `${styles.container}`
                }>
                <div className={styles.content}>
                    <h3>{title}</h3>
                    {description && (
                        <p className={styles.description}>
                            {description.description}
                        </p>
                    )}
                    {callToActionLinkText && (
                        <span className="btn btn--colored">
                            {callToActionLinkText}
                        </span>
                    )}
                </div>
                <figure className={styles.figure}>
                    {banner && (
                        <IwocaImage
                            src={banner.file.url}
                            className={styles.image}
                            noPlaceholder={true}
                            height="400"
                            width="400"
                            alt={title}
                        />
                    )}
                </figure>
            </section>
        );
    }
}

HowItWorksSection.propTypes = {
    step: PropTypes.shape({
        title: PropTypes.string.isRequired,
        description: PropTypes.shape({
            description: PropTypes.string
        }),
        callToActionLinkText: PropTypes.string,
        banner: PropTypes.shape({
            file: PropTypes.shape({
                url: PropTypes.string
            })
        })
    }),
    isVertical: PropTypes.bool
};

export default HowItWorksSection;
