import React, { useContext } from 'react';
import styles from './Questions.module.scss';
import { BasicDoughnut } from '../../../../SVGComponents/shapes';
import TelIcon from '../../../../SVGComponents/TelIconSVG';
import EmailIcon from '../../../../components/Footer/svg/EmailIcon';
import Button from '../../../../components/common/Button';
import IwContext from 'aphrodite-shared/IwContext/IwContext';

import LadyOnPhone from './png/CustomerSupport.png';
import { formatPhoneNumber } from '../../../../util/localization';

const Questions = ({
    title,
    content,
    showApplyButton,
    showJobsButton,
    jobCTAText,
    jobLink,
    loanCTAText,
    email,
    phoneNumber,
    overflowShapes = { BasicDouhnutShape: true }
}) => {
    const { BasicDouhnutShape } = overflowShapes;
    const context = useContext(IwContext);

    function handleOpenStepZero() {
        context.openStepZero();
    }

    return (
        <section className={styles.questionsBackground}>
            <img
                src={LadyOnPhone}
                alt="Lady on phone"
                className={styles.ladyOnPhone}
            />
            {BasicDouhnutShape && (
                <BasicDoughnut
                    color="london75"
                    className={styles.BasicDoughnut}
                />
            )}
            <div className={styles.Questions}>
                <h2>{title}</h2>
                <p>{content}</p>
                <div className={styles.contact}>
                    <div className={styles.tel}>
                        <TelIcon />
                        <a
                            href={formatPhoneNumber(phoneNumber)}
                            dataGaId="questions__phone">
                            {phoneNumber}
                        </a>
                    </div>
                    <hr />
                    <div className={styles.separator} />
                    <div className={styles.email}>
                        <EmailIcon />
                        <a href={`mailto:${email}`} dataGaId="questions__email">
                            {email}
                        </a>
                    </div>
                </div>
                <div className={styles.CTAContainer}>
                    {showJobsButton && jobCTAText && (
                        <Button
                            href={jobLink}
                            colour="secondary"
                            dataGaId="aboutusbottom__careers"
                            className={styles.CTA}>
                            {jobCTAText}
                        </Button>
                    )}
                    {showApplyButton && loanCTAText && (
                        <Button
                            onClick={handleOpenStepZero}
                            colour="primary"
                            dataGaId="aboutusbottom__cta"
                            className={styles.CTA}>
                            {loanCTAText}
                        </Button>
                    )}
                </div>
            </div>
        </section>
    );
};

export default Questions;
