import React from 'react';
import styles from './FeaturedArticle.module.scss';

function FeaturedArticle({ data }) {
    const sectionStyle = {};
    if (data.backgroundImage) {
        sectionStyle.backgroundImage = `url("${data.backgroundImage.file.url}")`;
    }

    return (
        <div className={styles.featuredArticle}>
            <div className={styles.contentContainer}>
                <div className={styles.background} style={sectionStyle}></div>
            </div>
        </div>
    );
}

export default FeaturedArticle;
