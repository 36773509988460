import React from 'react';

class SectionContainer extends React.Component {
    render() {
        const { children, className, innerClassName, style } = this.props;

        return (
            <section className={className} style={style}>
                <div className={innerClassName}>{children}</div>
            </section>
        );
    }
}

SectionContainer.propTypes = {};

export default SectionContainer;
