import React, { PureComponent } from 'react';
import lodashGet from 'lodash/get';
import classnames from 'classnames';
import Step from './HowItWorksStep';
import styles from './HowItWorks.module.scss';
import slideStyles from './HowItWorksSlides.module.scss';

class HowItWorksSlides extends PureComponent {
    constructor() {
        super();
        this.state = {
            currentSlide: 0,
            isHover: false
        };
        this.onMouseEnter = this.onMouseEnter.bind(this);
        this.onMouseLeave = this.onMouseLeave.bind(this);
    }

    componentDidMount() {
        const { data, animationInterval } = this.props;

        this.animationLoop = setInterval(() => {
            if (!this.state.isHover) {
                this.setState((prevState) => {
                    return {
                        currentSlide:
                            (prevState.currentSlide + 1) % data.steps.length
                    };
                });
            }
        }, animationInterval);
    }

    componentWillUnmount() {
        clearInterval(this.animationLoop);
    }

    onMouseEnter(index) {
        this.setState({
            currentSlide: index,
            isHover: true
        });
    }

    onMouseLeave() {
        this.setState({
            isHover: false
        });
    }

    render() {
        const { data } = this.props;
        const { currentSlide } = this.state;
        const imageContainerLgClass = classnames(
            'col-lg-6',
            styles.imageContainer
        );

        return (
            <div>
                <div>
                    <div className={styles.titleBox}>
                        <h2 className={styles.title}>{data.headline}</h2>
                        <p className={styles.subtitle}>{data.subheadline}</p>
                    </div>

                    <div className={slideStyles.content}>
                        <div>
                            {data.steps.map((stepData, index) => (
                                <div
                                    key={index}
                                    onMouseEnter={() =>
                                        this.onMouseEnter(index)
                                    }
                                    onMouseLeave={() => this.onMouseLeave()}
                                    className={
                                        slideStyles.item +
                                        (currentSlide === index
                                            ? ` ${slideStyles.itemActive}`
                                            : '')
                                    }>
                                    <Step data={stepData} key={index} />
                                </div>
                            ))}
                        </div>

                        <div className={slideStyles.images}>
                            <img
                                src={data.deviceImage.file.url}
                                alt="device"
                                className={slideStyles.device}
                            />

                            {data.steps.map((stepData, index) => (
                                <div
                                    key={index}
                                    className={
                                        slideStyles.image +
                                        (currentSlide === index
                                            ? ` ${slideStyles.imageActive}`
                                            : '')
                                    }>
                                    <img
                                        src={stepData.banner.file.url}
                                        alt={stepData.title}
                                    />
                                </div>
                            ))}
                        </div>
                    </div>

                    <div className={styles.stepsTablet}>
                        {data.steps.map((stepData, index) => (
                            <div key={index} className="row">
                                <div className="col-lg-6">
                                    <Step data={stepData} key={index} />
                                </div>
                                <div className={imageContainerLgClass}>
                                    {data.deviceImage && (
                                        <img
                                            className={styles.stepsDevice}
                                            src={data.deviceImage.file.url}
                                            alt={stepData.title}
                                        />
                                    )}
                                    <div
                                        key={index}
                                        className={styles.stepsImageImage}>
                                        <img
                                            src={stepData.banner.file.url}
                                            alt={stepData.title}
                                        />
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>

                    <div className={styles.stepsMobile}>
                        {data.steps.map((stepData, index) => (
                            <div key={index} className="row">
                                <div className="col-lg-6">
                                    <Step data={stepData} key={index} />
                                </div>
                                <div className={imageContainerLgClass}>
                                    {data.deviceImageTablet && (
                                        <img
                                            src={
                                                data.deviceImageMobile.file.url
                                            }
                                            alt={stepData.title}
                                        />
                                    )}
                                    {lodashGet(
                                        stepData,
                                        'bannerMobile.file.url'
                                    ) && (
                                        <img
                                            src={stepData.bannerMobile.file.url}
                                            className={styles.stepsBannerImage}
                                            alt={stepData.title}
                                        />
                                    )}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        );
    }
}

HowItWorksSlides.defaultProps = {
    animationInterval: 3000
};

export default HowItWorksSlides;
