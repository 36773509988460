import React from 'react';
import CalculatorSection from './CalculatorSection';
import { useIntl } from 'react-intl';
import messages from 'aphrodite-shared/components/CalculatorRCF/CalculatorRCF.messages';

const LegacyToNewRCFCalculatorConverter = ({ data }) => {
    const { formatMessage } = useIntl();

    const calculator = data.calculators[0];
    const calculatorCopy = {
        borrowUpToText: calculator.iWantText,
        ctaButton: {
            buttonText: formatMessage({ ...messages.cta_text })
        },
        equalRepaymentText:
            '{months} {months, plural, one {' +
            calculator.monthlyRepaymentText +
            '} other {' +
            calculator.monthlyRepaymentTextPlural +
            '}}  {equalRepayment}',
        footerText: data.footerText,
        forText: calculator.forText,
        helpText: formatMessage({ ...messages.help_text }),
        interestText: ' ',
        mobileTitle: calculator.title,
        subtitle: data.subtitle,
        title: calculator.title,
        totalRepaymentText: formatMessage({
            ...messages.total_repayment_text
        })
    };

    const contactBubble = {
        phoneNumber: formatMessage({
            ...messages.phone_number
        }),
        text: formatMessage({ ...messages.contact_bubble_text })
    };
    return (
        <CalculatorSection
            calculatorCopy={calculatorCopy}
            contactBubble={contactBubble}
            showArrow={false}
            maximumLoanAmount={calculator.maxAmount}
            overflowShapes={false}
        />
    );
};

export default LegacyToNewRCFCalculatorConverter;
