import React from 'react';
import Chevron from '../../../../SVGComponents/Chevron';
import { getContenthubArticleFullSlug } from '../../../../components/ContentHub/ContentHubHelper';
import messages from '../Article.messages';
import { useIntl } from 'react-intl';
import styles from './MoreArticles.module.scss';

const MoreArticles = ({ articles, guide }) => {
    const { formatMessage } = useIntl();

    const renderLinks = () => {
        if (articles.articleLink && articles.articleLink.length > 0) {
            return articles.articleLink.map((article) => (
                <div className={styles.article}>
                    <a href={article.anchorLink}>{article.anchorText}</a>
                    <Chevron />
                </div>
            ));
        } else if (articles.length > 0) {
            return articles.map((article) => (
                <div className={styles.article}>
                    <a
                        href={getContenthubArticleFullSlug(
                            article.categoryTag.categoryTag,
                            article.slug
                        )}>
                        {article.articleTitle}
                    </a>
                    <Chevron />
                </div>
            ));
        }
    };
    return (
        <section className={styles.background}>
            <div className={styles.MoreArticles}>
                <h2> {formatMessage({ ...messages.youMightEnjoy })}</h2>
                <div className={styles.articles}>{renderLinks()}</div>
            </div>
        </section>
    );
};

export default MoreArticles;
