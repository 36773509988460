import React from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import { getFullIwocaUrl } from 'aphrodite-shared/util/helpers';

class MetaData extends React.Component {
    renderMetaDescription = () => {};

    render() {
        const {
            titleTag,
            metaDescription,
            allowSearchEngineScraping = true,
            includeTitlePrefixSuffix = true,
            url,
            canonicalTag,
            slug,
            articleImage,
            structuredData,
            className
        } = this.props.metaData;

        const articleOrPageUrl = slug || url || canonicalTag;

        const canonicalUrl = `https://www.${getFullIwocaUrl(
            `${articleOrPageUrl}`
        )}`;

        return (
            <Helmet
                bodyAttributes={{
                    class: className
                }}>
                <link rel="canonical" href={canonicalUrl} />
                {titleTag && (
                    <title>{`${titleTag} ${
                        includeTitlePrefixSuffix ? '| iwoca' : ''
                    }`}</title>
                )}
                {titleTag && <meta property="og:title" content={titleTag} />}
                {metaDescription && (
                    <meta name="description" content={metaDescription} />
                )}
                {metaDescription && (
                    <meta property="og:description" content={metaDescription} />
                )}
                {articleOrPageUrl && (
                    <meta property="og:url" content={articleOrPageUrl} />
                )}
                {articleImage && (
                    <meta property="og:image" content={articleImage.file.url} />
                )}
                {articleImage && (
                    <meta
                        property="twitter:image"
                        content={articleImage.file.url}
                    />
                )}
                {!allowSearchEngineScraping && (
                    <meta name="robots" content="noindex" />
                )}
                {structuredData &&
                    structuredData.map((item) => {
                        return (
                            <script type="application/ld+json">
                                {JSON.stringify(item)}
                            </script>
                        );
                    })}
                <meta name="twitter:card" content="summary"></meta>
                <meta name="twitter:site" content="@iwoca" />
            </Helmet>
        );
    }
}

MetaData.propTypes = {
    metaData: PropTypes.shape({
        titleTag: PropTypes.string,
        metaDescription: PropTypes.string,

        allowSearchEngineScraping: PropTypes.bool
    })
};

export default MetaData;
