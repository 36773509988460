import React from 'react';
import PressContactBox from '../../../components/Press/PressContactBox';
import CategoryPageHeader from './CategoryPageHeader/CategoryPageHeader';
import ArticleList from '../common/ArticleList';
import ContentHubEmailCapture from '../common/ContentHubEmailCapture';

class CategoryPage extends React.Component {
    render() {
        const { contentfulCategoryPage } = this.props;

        const {
            title,
            category,
            featuredArticles,
            emailCapture,
            paginationCta,
            recentArticlesTitle,
            pressSection
        } = contentfulCategoryPage;

        const articleList = this.props.articleList
            .filter(
                (article) =>
                    !featuredArticles.some(
                        (featuredArticle) => featuredArticle.id === article.id
                    ) && !/.*(SCHEMA).*/gi.test(article.name)
            )
            .sort((articleOne, articleTwo) => {
                return new Date(articleTwo.date) - new Date(articleOne.date);
            });

        return (
            <React.Fragment>
                {pressSection && <PressContactBox data={{ ...pressSection }} />}
                <CategoryPageHeader
                    title={title}
                    category={category}
                    featuredArticles={featuredArticles}
                />
                <ContentHubEmailCapture emailCapture={emailCapture} />
                {articleList && articleList.length > 0 && (
                    <ArticleList
                        articleList={articleList}
                        paginationCta={paginationCta}
                        heading={recentArticlesTitle}
                    />
                )}
            </React.Fragment>
        );
    }
}

export default CategoryPage;
