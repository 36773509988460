import React from 'react';
import lodashGet from 'lodash/get';
import PropTypes from 'prop-types';
import SectionContainer from './SectionContainer';
import styles from './ContactSection.module.scss';

class ContactSection extends React.Component {
    render() {
        if (!this.props.data) {
            console.error(
                'ContactSection: No data found. Please provide an data object'
            );
            return null;
        }

        const { title, subtitleText, legacyImage } = this.props.data;

        return (
            <SectionContainer
                style={
                    lodashGet(legacyImage, 'fixed.src') && {
                        backgroundImage: `url(${legacyImage.fixed.src})`,
                        backgroundPosition: 'center center',
                        backgroundSize: 'cover'
                    }
                }
                innerClassName={styles.section}>
                <h2 className={styles.title}>{title}</h2>
                {subtitleText && subtitleText.subtitle && (
                    <p className={styles.subTitle}>{subtitleText.subtitle}</p>
                )}
            </SectionContainer>
        );
    }
}

ContactSection.propTypes = {
    legacyImage: PropTypes.shape({
        file: PropTypes.shape({
            url: PropTypes.string
        })
    }),
    subtitleText: PropTypes.shape({
        subtitle: PropTypes.string
    }),
    title: PropTypes.string
};

export default ContactSection;
