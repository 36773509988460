import React from 'react';
import Button from '../../common/Button';
import styles from './InfiniteScroll.module.scss';

class InfiniteScroll extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            articlesLoaded: this.props.articlesToLoadPerClick
        };
        this.loadMore = this.loadMore.bind(this);
    }

    loadMore() {
        const { articlesToLoadPerClick } = this.props;

        if (
            this.state.articlesLoaded + articlesToLoadPerClick >=
            this.props.children.length
        ) {
            this.setState({
                articlesLoaded: this.props.children.length
            });
        } else {
            this.setState((currentState) => ({
                articlesLoaded:
                    currentState.articlesLoaded + articlesToLoadPerClick
            }));
        }
    }

    render() {
        const { children, loadMoreText } = this.props;

        return (
            <div className={styles.infiniteScroll}>
                <div>
                    {children &&
                        children.map((child, index) => {
                            if (index < this.state.articlesLoaded) {
                                return child;
                            }
                            return null;
                        })}
                </div>

                {children && this.state.articlesLoaded < children.length && (
                    <Button
                        colour="primary"
                        size="small"
                        onClick={this.loadMore}>
                        {loadMoreText}
                    </Button>
                )}
            </div>
        );
    }
}

InfiniteScroll.defaultProps = {
    loadMoreText: 'Load more',
    articlesToLoadPerClick: 5
};

export default InfiniteScroll;
