import { defineMessages } from 'react-intl';

export default defineMessages({
    phone_number: {
        id: '_.calculator__phone_number',
        defaultMessage: '020 3778 0274'
    },
    total_repayment_text: {
        id: '_.calculator__total_repayment_text',
        defaultMessage: 'Total repayment of {totalRepayment}'
    },
    help_text: {
        id: '_.calculator__help_text',
        defaultMessage: 'Need help? call us'
    },
    contact_bubble_text: {
        id: '_.calculator__contact_bubble_text',
        defaultMessage:
            'Call us from Monday to Friday (8am - 7pm) and Saturday (9am - 3pm). We accept applications over the phone, too.'
    },
    cta_text: {
        id: '_.calculator__cta_text',
        defaultMessage: 'Borrow {amount}'
    }
});
