import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import EmailCaptureForm from './EmailCaptureForm';
import styles from './ContentHubEmailCapture.module.scss';

class ContentHubEmailCapture extends React.Component {
    render() {
        const { emailCapture } = this.props;
        return (
            <div className={styles.emailCapture}>
                {emailCapture.subtitle && (
                    <div className={styles.text}>
                        <h4>{emailCapture.subtitle}</h4>
                    </div>
                )}
                {emailCapture.title && (
                    <div className={styles.title}>
                        <h3 className="title">{emailCapture.title}</h3>
                    </div>
                )}
                {emailCapture.emailField && (
                    <EmailCaptureForm
                        key={emailCapture.emailField.id}
                        placeholder={emailCapture.emailField.placeholder}
                        required={emailCapture.emailField.requiredField}
                        errorMessages={emailCapture.emailField.errorMessages}
                        className={styles.inputContainer}
                        successMessage={emailCapture.successMessage}
                    />
                )}
            </div>
        );
    }
}

ContentHubEmailCapture.propTypes = {
    emailCapture: PropTypes.shape({
        title: PropTypes.string,
        subtitle: PropTypes.string,
        emailField: PropTypes.object,
        successMessage: PropTypes.string
    })
};

export const data = graphql`
    fragment ContentHubEmailCapture on ContentfulBlogArticle {
        emailCapture {
            id
            title
            subtitle
            emailField {
                ...EmailField
            }
            successMessage
        }
    }
    fragment CategoryPageEmailCapture on ContentfulBlogSubPage {
        emailCapture {
            id
            title
            subtitle
            emailField {
                ...EmailField
            }
            successMessage
        }
    }
    fragment ContentHubHomePageEmailCapture on ContentfulBlogHomepage {
        emailCapture {
            id
            title
            subtitle
            emailField {
                ...EmailField
            }
            successMessage
        }
    }

    fragment CareersContentHubHomePageEmailCapture on ContentfulCareersHomepage {
        emailCapture {
            id
            title
            subtitle
            emailField {
                ...EmailField
            }
            successMessage
        }
    }
`;

export default ContentHubEmailCapture;
