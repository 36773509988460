import React from 'react';
import messages from '../../Article.messages';
import { useIntl } from 'react-intl';
import { useStaticQuery, graphql, Link } from 'gatsby';
import styles from './RecentArticles.module.scss';
import { getContenthubArticleFullSlug } from '../../../../../components/ContentHub/ContentHubHelper';

function useRecentArticle(categoryTag) {
    const { allContentfulBlogCategoryTag: data } = useStaticQuery(
        graphql`
            {
                allContentfulBlogCategoryTag {
                    nodes {
                        categoryTag
                        blog_article {
                            name
                            articleTitle
                            slug
                        }
                    }
                }
            }
        `
    );

    return data.nodes.find((item) => item.categoryTag === categoryTag);
}

const RecentArticles = ({ articleName, categoryTag }) => {
    const allBlogArticles = useRecentArticle(categoryTag);
    const articles = allBlogArticles.blog_article
        .filter((article) => !/.*(SCHEMA).*/gi.test(article.name))
        .filter((article) => article.name !== articleName)
        .splice(0, 3);

    const { formatMessage } = useIntl();

    // Don't render if no articles to be found
    if (articles.length === 0) return null;

    return (
        <div className={styles.RecentArticles}>
            <h3>
                {formatMessage({
                    ...messages.recentArticles
                })}
            </h3>
            <div className={styles.Divider}></div>
            <ul>
                {articles.map((article, i) => (
                    <li key={i}>
                        <Link
                            className={styles.Link}
                            to={getContenthubArticleFullSlug(
                                categoryTag,
                                article.slug
                            )}>
                            {article.articleTitle}
                        </Link>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default RecentArticles;
