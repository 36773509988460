import React from 'react';

import styles from './IntroducerSignup.module.scss';

import MetaData from '../../components/Layout/MetaData';
import NavBar from '../../components/NavBar/NavBar';
import IntroducerSignupForm from './IntroducerSignupForm/IntroducerSignupForm';

const StepZeroPage = () => {
    return (
        <div className={styles.StepZeroPage}>
            <MetaData
                metaData={{
                    titleTag: 'iwoca -  Online-Portal für Partner - Anmeldung ',
                    allowSearchEngineScraping: false,
                    url: '/partner/signup'
                }}
            />
            <NavBar
                isLandingPage={true}
                hideLoginLink={false}
                hideSignupLink={true}
                phoneNumber="069 / 9432 3620"
                phoneNumberLink="tel:+496994323620"
            />
            <section className={styles.container}>
                <div className={styles.title}>
                    <h1>Anmeldung</h1>
                </div>
                <div className={styles.mainContainer}>
                    <div className={styles.formContainer}>
                        <IntroducerSignupForm />
                    </div>
                </div>
            </section>
        </div>
    );
};

export default StepZeroPage;
