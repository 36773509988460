import React from 'react';
import styles from '../../../../common/CalculatorSection/CalculatorSection.module.scss';
import ReactMarkdown from 'react-markdown';
import EbayCalculator from './EbayCalculator.jsx';

const EbayCalculatorSection = ({ calculatorCopy, minLoanAmount }) => (
    <section className={styles.CalculatorSectionContainer}>
        <div className={styles.CalculatorSection}>
            <h2 className={styles.hideMobile}>{calculatorCopy.title}</h2>
            <h2 className={styles.showMobile}>{calculatorCopy.mobileTitle}</h2>
            <hr />
            <p className={styles.intro}>{calculatorCopy.subtitle}</p>
            <div className={styles.card}>
                <EbayCalculator minLoanAmount={minLoanAmount} />
            </div>
            <ReactMarkdown
                source={calculatorCopy.footerText.footerText}
                className={styles.content}
            />
        </div>
    </section>
);

export default EbayCalculatorSection;
