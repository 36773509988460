/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import React from 'react';

import '@iwoca/orion/css/colors.css';
import '@iwoca/orion/lib/css/orion.css';

import IwContextProvider from 'aphrodite-shared/IwContext/IwContextProvider';
import { IwIntlProvider } from 'aphrodite-shared/IwIntl/IwIntlProvider';

import { logSessionId } from './src/util/authHelpers';
import 'whatwg-fetch';

import 'aphrodite-shared/scss/typography/font-face.scss';

import IwSplitTestProvider from 'aphrodite-shared/IwSplitTest/IwSplitTestProvider';
import SPLIT_TEST_CONFIG from './src/splitTests';

import { addPageTag } from 'aphrodite-shared/util/pageTagging';
import pageTags from './src/pageTags';

const wrapRootElement = ({ element }) => {
    return (
        <IwContextProvider>
            <IwIntlProvider>
                <IwSplitTestProvider config={SPLIT_TEST_CONFIG}>
                    {element}
                </IwSplitTestProvider>
            </IwIntlProvider>
        </IwContextProvider>
    );
};

const onRouteUpdate = () => {
    logSessionId();
    addPageTag(pageTags);
};

export { onRouteUpdate, wrapRootElement };
