import React from 'react';
import { graphql } from 'gatsby';
import CategoryPage from '../../components/ContentHub/CategoryPage/CategoryPage';
import Layout from '../../components/Layout/Layout';
import { fetchIsLoggedIn } from '../../util/authHelpers';

class CategoryPageWithoutStore extends React.Component {
    render() {
        const {
            contentfulBlogSubPage,
            contentfulGlobalVariables,
            contentfulFeatureManagementSystem,
            contentfulHreflangManagement
        } = this.props.data;

        const applyForm = contentfulBlogSubPage.modal
            ? contentfulBlogSubPage.modal.find(
                  (modal) => modal.type === 'customer_signup_form'
              )
            : null;

        const {
            titleTag,
            metaDescription,
            allowSearchEngineScraping,
            category,
            slug
        } = contentfulBlogSubPage;

        let canonicalTag = slug;
        if (category.blog_article) {
            canonicalTag = `${category.blog_article[0].categoryTag.categoryLink}/`;
        }

        const isLoggedIn = fetchIsLoggedIn();

        const articleList = contentfulBlogSubPage.category.blog_article || [];
        return (
            <Layout
                headerData={contentfulBlogSubPage.header}
                userIsLoggedIn={isLoggedIn}
                globalVariables={contentfulGlobalVariables}
                modals={{ applyForm }}
                metaData={{
                    titleTag,
                    metaDescription,
                    allowSearchEngineScraping,
                    slug: canonicalTag,
                    contentfulHreflangManagement
                }}
                featureFlags={contentfulFeatureManagementSystem}>
                <CategoryPage
                    contentfulCategoryPage={contentfulBlogSubPage}
                    articleList={articleList}
                />
            </Layout>
        );
    }
}

export default CategoryPageWithoutStore;

export const query = graphql`
    query SubPageQuery($id: String!) {
        contentfulBlogSubPage(id: { eq: $id }) {
            id
            slug
            name
            titleTag
            metaDescription
            allowSearchEngineScraping
            ...CatergoryPageHeader
            header {
                ...Header
            }
            modal {
                ...Form
            }
            ...CategoryPageEmailCapture
            paginationCta
            recentArticlesTitle
            category {
                name
                categoryTag
                blog_article {
                    id
                    slug
                    articleTitle
                    articleHeaderImage {
                        file {
                            url
                        }
                        fluid(maxWidth: 600, quality: 80) {
                            ...GatsbyContentfulFluid_withWebp_noBase64
                        }
                    }
                    subtitle {
                        subtitle
                    }
                    ...ArticleContent
                    categoryTag {
                        categoryTag
                        categoryLink
                        blog_sub_page_ {
                            id
                            slug
                        }
                    }
                }
            }
            pressSection {
                title
                subtitle
                contactLink1 {
                    contactLink1
                }
                contactLink2 {
                    contactLink2
                }
                contactLink3 {
                    contactLink3
                }
            }
        }
        contentfulGlobalVariables {
            ...GlobalVariables
        }
        contentfulFeatureManagementSystem(
            name: { eq: "Feature Management System" }
        ) {
            ...FeatureManagementSystem
        }
        contentfulHreflangManagement(name: { eq: "Hreflang Management" }) {
            ...HreflangManagement
        }
    }
`;
