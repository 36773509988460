import React from 'react';
import ReactMarkdown from 'react-markdown';
import breaks from 'remark-breaks';
import styles from './ContentSection.module.scss';

export default ({ data }) => (
    <div className={styles.content}>
        <div className={styles.contentContainer}>
            <h2 className={styles.title}>{data.headline && data.headline}</h2>

            {data.contentBlocks &&
                data.contentBlocks.map((content, index) => (
                    <React.Fragment>
                        {content.title && <h3>{content.title}</h3>}
                        {content.content.content && (
                            <ReactMarkdown
                                source={content.content.content}
                                plugins={[breaks]}
                                escapeHtml={false}
                                skipHtml={false}
                            />
                        )}
                    </React.Fragment>
                ))}

            {data.features && (
                <div className={styles.featureContainer}>
                    {data.features.map((feature, index) => (
                        <div className={styles.feature}>
                            <img
                                src={feature.icon.file.url}
                                alt={feature.featureTitle.featureTitle}
                                className={styles.featureIcon}
                            />
                            <div className={styles.featureDescription}>
                                {feature.description.description}
                            </div>
                        </div>
                    ))}
                </div>
            )}
        </div>
    </div>
);
