import React, { useContext } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { useIntl } from 'react-intl';
import { getCountryBuild } from '../../../util/localization';

import { Amplitude, AmplitudeSignup } from '@iwoca/frontend-tracking-library';
import yup from 'yup';
import NumberFormat from 'react-number-format';
import classnames from 'classnames';

import {
    submitSignupForm,
    sendApplicationDataToGA,
    debouncedEmailValidation
} from '../../../components/Modals/ApplyModalHelpers';
import {
    SIGNUP_PAGE_URL,
    MAX_LOAN_SIZE,
    LOCALE_CURRENCY
} from 'aphrodite-shared/util/iwocaConstants';
import Button from '../../../components/common/Button';
import ErrorIcon from '../../../SVGComponents/ErrorIcon';
import IwContext from 'aphrodite-shared/IwContext/IwContext';

import styles from './StepZeroForm.module.scss';
import messages from './StepZeroForm.messages.js';
import { rawIntl } from 'aphrodite-shared/IwIntl/IwIntlProvider';
import ConfirmedIcon from 'aphrodite-shared/SVGComponents/ConfirmedIcon';
import { logErrorToDataLayer } from '../../../util/googleTracking';
import { AMPLITUDE_EVENT_NAMES } from 'aphrodite-shared/util/tracking/amplitudeEvents';
import { getCurrentLocale } from 'aphrodite-shared/util/localization';

const currency = LOCALE_CURRENCY[rawIntl.locale];
const MAX_LOAN_AMOUNT = MAX_LOAN_SIZE[rawIntl.locale];

function generateMaxLoanValue(maximumLoanAmount = MAX_LOAN_AMOUNT) {
    return rawIntl.formatNumber(maximumLoanAmount, {
        style: 'currency',
        currency: currency,
        maximumFractionDigits: 0,
        minimumFractionDigits: 0
    });
}

export default function StepZeroForm({
    minimumLoanAmount = 1000,
    maximumLoanAmount: suggestedMaxLoanAmount = MAX_LOAN_AMOUNT,
    prefilledOptions
}) {
    let maximumLoanAmount = suggestedMaxLoanAmount;
    if (suggestedMaxLoanAmount <= 0) {
        maximumLoanAmount = MAX_LOAN_AMOUNT;
    }

    const context = useContext(IwContext);
    const { formatMessage } = useIntl();

    const handleSubmitForm = async (values, formik) => {
        // Make sure there is ALWAYS an email
        if (!values['applicantEmailAddress']) {
            formik.setSubmitting(false);
            return;
        }

        try {
            await sendApplicationDataToGA(values);
            await submitSignupForm(values);

            Amplitude.track(
                AmplitudeSignup.signupEventCreator({
                    event:
                        AMPLITUDE_EVENT_NAMES.NEW_APPLICATION_MODAL_SUBMITTED,
                    locale: getCurrentLocale(),
                    payload: {
                        url: window?.location?.href
                    }
                })
            );

            window.location = SIGNUP_PAGE_URL[process.env.GATSBY_COUNTRY_BUILD];
        } catch (error) {
            typeof window !== 'undefined' &&
                window.Raven.captureException(error);
            // TODO: Add toast to log potential error to user
            logErrorToDataLayer('Step Zero - Form submission error');

            // Prevent freeze in submission state
            formik.setSubmitting(false);
        }
    };

    const initialValues = {
        applicantEmailAddress: '',
        requestedAmount: context.borrowAmount,
        purpose: '',
        detailed_purpose: '',
        email_marketing_opt_in: false,
        lengthOfLoan: '',
        ...prefilledOptions
    };

    /*
     * Expected behaviour:
     * - Validate on every input change to prevent persistant errors
     * - Show email error only when done typing
     * - Debounce email validation
     * - Show custom error message when user is currently logged in
     * - Better handling of form submission state: Prevent freezing by ensuring redirect happens
     */

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={(values, formik) => handleSubmitForm(values, formik)}
            validationSchema={generateStepZeroFormSchema({
                minimumLoanAmount,
                maximumLoanAmount
            })}
            validateOnChange={false}>
            {(props) => {
                const { values, isSubmitting } = props;
                const showDetailedPurposeField = values.purpose === 'other';

                return (
                    <Form data-testid="stepZeroForm">
                        <div className={styles.formGroupContainer}>
                            <EmailField form={props} />
                            <AmountField
                                maximumLoanAmount={maximumLoanAmount}
                                minimumLoanAmount={minimumLoanAmount}
                            />
                            <PurposeField />
                            <LengthOfLoanField />
                            {showDetailedPurposeField && (
                                <DetailedPurposeField />
                            )}
                        </div>

                        <p
                            className={styles.personalProcessingAgreement}
                            data-testid="personal-processing-agreement">
                            Hiermit willige ich in die Verarbeitung meiner
                            personenbezogenen Daten gemäß unserer aktuellen{' '}
                            <a
                                href="https://www.iwoca.de/datenschutzhinweis/"
                                target="_blank"
                                rel="noopener noreferrer">
                                Datenschutzerklärung
                            </a>{' '}
                            der iwoca GmbH ein. Die erteilte Einwilligung kann
                            jederzeit widerrufen werden. Mit den{' '}
                            <a
                                href="https://www.iwoca.de/allgemeine_geschaeftsbedingungen/"
                                target="_blank"
                                rel="noopener noreferrer">
                                AGB
                            </a>{' '}
                            der iwoca GmbH erkläre ich mich einverstanden.
                        </p>

                        <div className={styles.bottom}>
                            <MarketingCheckbox />
                            <Button
                                type="submit"
                                colour="primary"
                                disabled={isSubmitting}
                                className={classnames(styles.submitButton, {
                                    [styles.submitting]: isSubmitting
                                })}>
                                {isSubmitting
                                    ? formatMessage({
                                          ...messages.submitting
                                      })
                                    : formatMessage({
                                          ...messages.next
                                      })}
                            </Button>
                        </div>
                    </Form>
                );
            }}
        </Formik>
    );
}

const EmailField = ({ form }) => {
    const { formatMessage } = useIntl();
    return (
        <div className={styles.formGroup}>
            <label htmlFor="applicantEmailAddress">
                {formatMessage({ ...messages.whats_your_email_address })}
            </label>
            <div className={`fs-block ${styles.input}`}>
                <Field
                    name="applicantEmailAddress"
                    validate={(_) => {
                        // Only fires on form submission
                        // Returns errors from email specific validation,
                        // Submissionvalidation will otherwise override
                        if (form.values['applicantEmailAddress'] === '') {
                            return formatMessage({
                                ...messages.please_enter_a_valid_email_address
                            });
                        }
                        return form.errors['applicantEmailAddress'];
                    }}>
                    {({ form, field }) => (
                        <>
                            <input
                                {...field}
                                id="applicantEmailAddress"
                                placeholder={formatMessage({
                                    ...messages.email_placeholder
                                })}
                                type="email"
                                className={`fs-block
                                ${form.errors.applicantEmailAddress &&
                                    form.touched.applicantEmailAddress &&
                                    styles.error}`}
                                onChange={async (e) => {
                                    // Avoid race condition
                                    await form.handleChange(e);

                                    // Reset formstatus for instant feedback (No debounce!)
                                    form.setStatus('EMAIL_VALIDATION_PENDING');
                                    await debouncedEmailValidation(form, e);
                                }}
                                onBlur={async (e) => {
                                    await form.handleBlur(e);
                                    // When jumping to next field, immediatly invoke validation
                                    debouncedEmailValidation.flush();
                                }}
                            />
                            {form.status === 'EMAIL_VALIDATED' && (
                                <div className={styles.emailCheck}>
                                    <ConfirmedIcon
                                        className={styles.emailCheckIcon}
                                    />
                                </div>
                            )}
                        </>
                    )}
                </Field>
                <FormErrorMessage name="applicantEmailAddress" />
            </div>
        </div>
    );
};

const AmountField = ({ minimumLoanAmount, maximumLoanAmount }) => {
    const { formatMessage } = useIntl();

    return (
        <div className={styles.formGroup}>
            <label htmlFor="requestedAmount">
                {formatMessage({ ...messages.how_much_would_you_like })}
            </label>
            <div className={styles.input}>
                <Field name="requestedAmount">
                    {({ form, field }) => (
                        <NumberFormat
                            id="requestedAmount"
                            inputMode="numeric"
                            type="tel"
                            value={field.value}
                            placeholder={formatMessage(
                                { ...messages.any_amount_up_to },
                                {
                                    amount: generateMaxLoanValue(
                                        maximumLoanAmount
                                    )
                                }
                            )}
                            className={
                                form.errors.requestedAmount &&
                                form.touched.requestedAmount &&
                                styles.error
                            }
                            thousandSeparator={
                                getCountryBuild() === 'de' ? '.' : ','
                            }
                            decimalSeparator={
                                getCountryBuild() === 'de' ? ',' : '.'
                            }
                            decimalScale={0}
                            prefix={getCountryBuild() === 'de' ? '€' : '£'}
                            allowNegative={false}
                            isAllowed={({ formattedValue, floatValue }) =>
                                formattedValue === '' ||
                                floatValue <= maximumLoanAmount
                            }
                            onValueChange={(values) => {
                                form.setFieldValue(
                                    'requestedAmount',
                                    values.floatValue ? values.floatValue : null
                                );
                            }}
                        />
                    )}
                </Field>
                <FormErrorMessage name="requestedAmount" />
            </div>
        </div>
    );
};

const PurposeField = () => {
    const { formatMessage } = useIntl();
    return (
        <div className={styles.formGroup}>
            <label htmlFor="purpose">
                {formatMessage({
                    ...messages.how_will_you_use_the_funds
                })}
            </label>
            <div className={styles.input}>
                <Field name="purpose">
                    {({ form, field }) => (
                        <select
                            {...field}
                            id="purpose"
                            placeholder="Please choose an option"
                            onChange={(e) => {
                                form.handleChange(e);
                                if (e.target.value !== 'other') {
                                    form.setFieldValue('detailed_purpose', '');
                                }
                            }}
                            className={
                                form.errors.purpose &&
                                form.touched.purpose &&
                                styles.error
                            }>
                            <option value="">
                                {formatMessage({
                                    ...messages.please_choose_an_option
                                })}
                            </option>
                            <option value="stock_purchase">
                                {formatMessage({
                                    ...messages.inventory_stock
                                })}
                            </option>
                            <option value="bridging_loan">
                                {formatMessage({
                                    ...messages.bridging_loan
                                })}
                            </option>
                            <option value="marketing">
                                {formatMessage({
                                    ...messages.marketing
                                })}
                            </option>
                            <option value="equipment_purchase">
                                {formatMessage({
                                    ...messages.equipment
                                })}
                            </option>
                            <option value="pay_bill">
                                {formatMessage({
                                    ...messages.pay_a_due_bill
                                })}
                            </option>
                            {getCountryBuild() === 'uk' && (
                                <option value="pay_staff">
                                    {formatMessage({
                                        ...messages.pay_staff
                                    })}
                                </option>
                            )}
                            <option value="refinancing_debt">
                                {formatMessage({
                                    ...messages.refinancing_debt
                                })}
                            </option>
                            <option value="other">
                                {formatMessage({
                                    ...messages.other
                                })}
                            </option>
                        </select>
                    )}
                </Field>
                <FormErrorMessage name="purpose" />
            </div>
        </div>
    );
};

const LengthOfLoanField = () => {
    const { formatMessage } = useIntl();
    return (
        <div className={styles.formGroup}>
            <label htmlFor="lengthOfLoan">
                {formatMessage({
                    ...messages.how_long_do_you_need_the_loan_for
                })}
            </label>
            <div className={styles.input}>
                <Field name="lengthOfLoan">
                    {({ form, field }) => (
                        <select
                            {...field}
                            id="lengthOfLoan"
                            onChange={(e) => {
                                form.handleChange(e);
                            }}
                            className={
                                form.errors.purpose &&
                                form.touched.purpose &&
                                styles.error
                            }>
                            <option value="">
                                {formatMessage({
                                    ...messages.please_choose_an_option
                                })}
                            </option>
                            <option value="short_term">
                                {formatMessage({
                                    ...messages.short_term
                                })}
                            </option>
                            <option value="medium_term">
                                {formatMessage({
                                    ...messages.medium_term
                                })}
                            </option>
                            <option value="long_term">
                                {formatMessage({
                                    ...messages.long_term
                                })}
                            </option>
                        </select>
                    )}
                </Field>
                <FormErrorMessage name="lengthOfLoan" />
            </div>
        </div>
    );
};

const DetailedPurposeField = () => {
    const { formatMessage } = useIntl();
    return (
        <div className={styles.formGroup}>
            <label htmlFor="detailed_purpose">
                {formatMessage({ ...messages.other_purpose })}
            </label>
            <div className={styles.input}>
                <Field name="detailed_purpose">
                    {({ form, field }) => (
                        <input
                            {...field}
                            id="detailed_purpose"
                            placeholder={formatMessage({
                                ...messages.tell_us_how
                            })}
                            className={
                                form.errors.detailed_purpose &&
                                form.touched.detailed_purpose &&
                                styles.error
                            }
                        />
                    )}
                </Field>
                <FormErrorMessage name="detailed_purpose" />
            </div>
        </div>
    );
};

const MarketingCheckbox = () => {
    const { formatMessage } = useIntl();

    return (
        <div className={styles.marketingOption}>
            <p>{formatMessage({ ...messages.marketing_sign_up })}</p>
            <Field name="email_marketing_opt_in">
                {({ field }) => (
                    <input
                        {...field}
                        id="email_marketing_opt_in"
                        type="checkbox"
                        checked={field.value}
                    />
                )}
            </Field>
            <label htmlFor="email_marketing_opt_in">
                {formatMessage({ ...messages.marketing_sign_up_action })}
            </label>
        </div>
    );
};

const FormErrorMessage = ({ name, ...restProps }) => (
    <ErrorMessage name={name}>
        {(msg) => (
            <div className={styles.inputFeedback} {...restProps}>
                <ErrorIcon />
                <p dangerouslySetInnerHTML={{ __html: msg }} />
            </div>
        )}
    </ErrorMessage>
);

function generateStepZeroFormSchema(settings = {}) {
    return yup.object({
        requestedAmount: yup
            .number()
            .typeError(
                rawIntl.formatMessage(
                    { ...messages.any_amount_up_to__error },
                    {
                        amount: generateMaxLoanValue(settings.maximumLoanAmount)
                    }
                )
            )
            .min(
                settings.minimumLoanAmount,
                'Bitte geben Sie einen Betrag ab 500€ an'
            )
            .max(settings.maximumLoanAmount)
            .required(
                rawIntl.formatMessage(
                    { ...messages.any_amount_up_to__error },
                    {
                        amount: generateMaxLoanValue(settings.maximumLoanAmount)
                    }
                )
            ),
        purpose: yup.string().required('Zweck ist ein Pflichtfeld'),
        lengthOfLoan: yup.string().notRequired(),
        detailed_purpose: yup
            .string()
            .notRequired()
            .when('purpose', (purpose, detailedPurposeSchema) => {
                return purpose === 'other'
                    ? detailedPurposeSchema.required(
                          rawIntl.formatMessage({
                              ...messages.please_select_the_purpose_for_your_loan
                          })
                      )
                    : detailedPurposeSchema;
            }),
        email_marketing_opt_in: yup.bool().required()
    });
}
