import React from 'react';
import { graphql } from 'gatsby';

import { normalisePath } from 'aphrodite-shared/util/pageTagging';
import { setupArticleSchema, setupVideoSchema } from '../../../util/seoHelpers';
import NavBar from '../../../components/NavBar/NavBar';
import AboveTheFold from './AboveTheFold/AboveTheFold';
import ContentBlock from './ContentBlock/ContentBlock';
import Footer from '../../../components/Footer/Footer';
import MetaData from '../../../components/Layout/MetaData.jsx';
import EmailUs from './EmailUs/EmailUs';
import { Breadcrumb } from 'gatsby-plugin-breadcrumb';
import { getCountryBuild } from '../../../util/localization';
import MoreArticles from './MoreArticles/MoreArticles';

import styles from '../iwocaPage.module.scss';

// Note: Removing EmailUs fold temporarily
const ArticlePage = ({ data, pageContext }) => {
    const { contentfulBlogArticle: pageData, youtubeVideo } = data;

    const {
        titleTag,
        metaDescription,
        allowSearchEngineScraping,
        canonicalTag
    } = pageData;

    const {
        breadcrumb: { crumbs },
        slug
    } = pageContext;

    crumbs.map((crumb) => {
        crumb.crumbLabel = crumb.crumbLabel.replace(/-/g, ' ');
        crumb.pathname = normalisePath(crumb.pathname);
        return crumb;
    });

    const isUk = getCountryBuild() === 'uk';

    const crumbLabel = isUk
        ? crumbs.slice(-1)[0].crumbLabel
        : pageData.articleTitle;

    let fullSlug = slug;
    if (canonicalTag) {
        fullSlug = canonicalTag;
    }

    const videoSchema = setupVideoSchema(youtubeVideo.items, pageData.content);
    const articleSchema = setupArticleSchema(pageData, fullSlug);
    const schema = [...articleSchema, ...videoSchema];

    return (
        <div>
            <MetaData
                metaData={{
                    titleTag,
                    metaDescription,
                    allowSearchEngineScraping,
                    url: fullSlug,
                    structuredData: schema
                }}
            />
            <NavBar className={styles.SpecialNavbar} />
            <div className={styles.iwocaBreadcrumbs}>
                <Breadcrumb
                    crumbs={crumbs}
                    crumbLabel={crumbLabel}
                    className={
                        isUk ? 'breadcrumb__link' : styles.de_breadcrumb__link
                    }
                />
            </div>
            {pageData.subtitle && pageData.categoryTag && (
                <AboveTheFold
                    headerImage={pageData.articleHeaderImage}
                    title={pageData.articleTitle}
                    subtitle={pageData.subtitle.subtitle}
                    date={pageData.date}
                    categoryTag={pageData.categoryTag.name}
                    content={pageData.content}
                />
            )}
            {pageData.articleHeaderImage && pageData.categoryTag && (
                <ContentBlock
                    headerImage={pageData.articleHeaderImage}
                    content={pageData.content}
                    author={pageData.author}
                    updatedAt={pageData.updatedAt}
                    articleName={pageData.name}
                    categoryTag={pageData.categoryTag.categoryTag}
                    links={pageData.articleNavigator}
                    fullSlug={fullSlug}
                />
            )}
            {(pageData.articleLinks || pageData.readNext) && (
                <MoreArticles
                    articles={
                        pageData.articleLinks
                            ? pageData.articleLinks
                            : pageData.readNext
                    }
                    guide={pageData.financeGuide}
                />
            )}
            {/* <EmailUs /> */}
            <Footer />
        </div>
    );
};

export default ArticlePage;

export const query = graphql`
    query BlogArticleQuery($id: String!) {
        contentfulBlogArticle(id: { eq: $id }) {
            id
            slug
            canonicalTag
            name
            titleTag
            metaDescription
            allowSearchEngineScraping
            header {
                ...Header
            }
            financeGuide {
                heading
                bookHeading
                bookSubHeading
                description
                ctaText
            }
            author {
                authorImage {
                    fluid(maxWidth: 600, quality: 80) {
                        ...GatsbyContentfulFluid_withWebp_noBase64
                    }
                }
                authorName
                authorBio {
                    authorBio
                }
                twitterUrl
                linkedinUrl
            }
            date
            categoryTag {
                categoryTag
                categoryLink
            }
            ...ArticleHeader
            ...ArticleContent
            ...ArticleAside
            ...ArticleFooter
            articleLinks {
                ...ArticleLinkingModule
            }
            articleNavigator {
                displayName
                link
            }
            modals {
                ...Form
            }
            schemaType
            updatedAt
        }
        youtubeVideo {
            kind
            items {
                id
                snippet {
                    title
                    description
                    thumbnails {
                        default {
                            url
                        }
                    }
                    publishedAt
                }
                contentDetails {
                    duration
                }
                statistics {
                    viewCount
                }
            }
        }
    }

    fragment ArticleLinkingModule on ContentfulArticleLinkingModule {
        articleLink {
            ...ArticleLink
        }
    }

    fragment ArticleLink on ContentfulArticleLink {
        anchorLink
        anchorText
    }
`;
