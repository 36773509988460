import React from 'react';
import './GenericLandingEbayTheme.scss';
import classnames from 'classnames';
import MetaData from '../../../../../components/Layout/MetaData.jsx';
import NavBar from '../../../../../components/NavBar/NavBar';
import HowItWorks from '../../HowItWorks/HowItWorks';
import Header from '../../Header/Header';
import Features from '../../Features/Features';
import Quote from '../../Quote/Quote';
import WantToKnowMore from '../../../common/WantToKnowMore/WantToKnowMore';
import ReadAboutUs from '../../../common/ReadAboutUs/ReadAboutUs';
import Questions from '../../../common/Questions/Questions';
import SimpleFooter from '../../../../../components/Footer/SimpleFooter/SimpleFooter';
import { fetchIsLoggedIn } from '../../../../../util/authHelpers';
import styles from '../../GenericLanding.module.scss';
import EbayCalculatorSection from './EbayCalculator/EbayCalculatorSection.jsx';
import EbayBanner from './EbayBanner.png';

const GenericLandingEbayTheme = ({ data }) => {
    const { contentfulPageGenericLanding: pageData, trustpilot } = data;
    const {
        title,
        metaDescription,
        allowSearchEngineScraping,
        url,
        theme
    } = pageData;

    const isLoggedIn = fetchIsLoggedIn();
    const mainCtaButton = {
        text: pageData.headerButton,
        href:
            url === '/ebay-flexikapital' &&
            'https://cloud.forbusiness.ebay.com/flexi-kapital-by-iwoca#section-kredit'
    };

    const themeName = theme === 'Ebay' ? 'ebay' : 'qonto';

    return (
        <div
            class={classnames(
                styles.GenericLanding,
                themeName && `generic_landing--${themeName}`
            )}>
            <MetaData
                metaData={{
                    titleTag: title,
                    metaDescription,
                    allowSearchEngineScraping,
                    url,
                    className: `${themeName}-theme`
                }}
            />
            <NavBar
                loggedIn={isLoggedIn}
                isLandingPage={true}
                hideLoginLink={false}
                hideSignupLink={false}
                partnershipLogo={pageData.partnershipLogo}
                primaryCtaLink={mainCtaButton.href}
                maximumLoanAmount={pageData.maximumLoanAmount}
            />
            <Header
                title={pageData.headerTitle}
                subtitle={pageData.headerSubtitle}
                button={mainCtaButton}
                image={pageData.headerImage}
                theme={theme}
                trustpilot={pageData.trustpilot}
                trustpilotRating={trustpilot.score.trustScore}
                trustpilotReviews={null}
                disclaimerText={pageData.headerDisclaimerText}
                additionalTrustElements={pageData.additionalTrustElements}
                partnershipLogo={pageData.partnershipLogo}
            />
            <Features
                title={pageData.featureTitle}
                featureText={pageData.featureText.featureText}
                mobileTitle={pageData.featureMobileTitle}
                features={pageData.features}
            />
            {url !== '/ebay-flexikapital' && !pageData.calculator && (
                <EbayCalculatorSection
                    calculatorCopy={pageData.calculator}
                    maximumLoanAmount={pageData.maximumLoanAmount}
                    minLoanAmount={theme === 'Ebay' ? 500 : 1000}
                />
            )}
            <HowItWorks
                howItWorksTitle={pageData.howItWorksTitle}
                image={pageData.howItWorksImage}
                steps={pageData.howItWorksSteps}
            />
            <Quote
                image={pageData.quoteImage}
                quote={pageData.quoteText}
                mobileQuote={pageData.quoteMobileText}
                author={pageData.quoteAuthor}
            />

            <div
                className={classnames(
                    themeName && `generic-landing__${themeName}_questions`
                )}>
                <Questions
                    title={pageData.contactUsSection.title}
                    content={pageData.contactUsSection.content.content}
                    email={pageData.contactUsSection.email}
                    phoneNumber={pageData.contactUsSection.phoneNumber}
                    showApplyButton={pageData.contactUsSection.showApplyButton}
                    showJobsButton={pageData.contactUsSection.showJobsButton}
                    jobCTAText={pageData.jobCtaText}
                    jobLink={pageData.jobLink}
                    loanCTAText={pageData.loanCtaText}
                    overflowShapes={{ BasicDoughnutShaoe: false }}
                />
            </div>

            <WantToKnowMore
                faq={pageData.faqSection.faqs}
                title={pageData.faqSection.title}
                subtitle={pageData.faqSection.subtitle.subtitle}
            />
            {url === '/ebay/' && (
                <section style={{ paddingBottom: '4rem' }}>
                    <a
                        className="imageBanner"
                        aria-label="link to ebay for business"
                        href="https://cloud.forbusiness.ebay.com/flexi-kapital-by-iwoca#section-kredit">
                        <img src={EbayBanner} alt="ebay banner" />
                    </a>
                </section>
            )}
            <ReadAboutUs
                desktopTitle={pageData.readAboutUsDesktopTitle}
                mobileTitle={pageData.readAboutUsMobileTitle}
                trustpilotRating={trustpilot.score.trustScore}
                pressReleaseIcons={pageData.pressReleaseSection.pressPhotos}
                trustpilotText={pageData.pressReleaseSection.trustpilotText}
            />
            <SimpleFooter />
        </div>
    );
};

export default GenericLandingEbayTheme;
