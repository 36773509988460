import React from 'react';
import Navbar from '../../components/NavBar/NavBar';
import MetaData from './MetaData';
import Footer from '../Footer/Footer';

// import styles from './LegacyPageLayout.module.scss';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

class LegacyPageLayout extends React.Component {
    render() {
        const { children, userIsLoggedIn, metaData } = this.props;

        return (
            <React.Fragment>
                {metaData && <MetaData metaData={metaData} />}
                <Navbar loggedIn={userIsLoggedIn} />
                {children}
                <Footer />
            </React.Fragment>
        );
    }
}

LegacyPageLayout.defaultProps = {
    menu: null,
    data: {},
    userState: false,
    globalVariables: {}
};

export default LegacyPageLayout;
