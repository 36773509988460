import React from 'react';
import { match } from '@reach/router/lib/utils';

import styles from './IntroducerSignup.module.scss';

import MetaData from '../../components/Layout/MetaData';
import NavBar from '../../components/NavBar/NavBar';
import IntroducerSignupInviteForm from './IntroducerSignupForm/IntroducerSignupInviteForm';

const StepZeroPage = ({ pageContext, location }) => {
    function getId() {
        if (pageContext.matchPath) {
            const result = match(pageContext.matchPath, location.pathname);
            if (result && result.params) {
                return result.params;
            }
        }

        return {};
    }

    const { id: introducerUuid } = getId();

    return (
        <div className={styles.StepZeroPage}>
            <MetaData
                metaData={{
                    titleTag: 'Introducer Signup',
                    allowSearchEngineScraping: false,
                    url: '/introducer-signup'
                }}
            />
            <NavBar
                isLandingPage={true}
                hideLoginLink={false}
                hideSignupLink={true}
                phoneNumber="069 / 9432 3620"
                phoneNumberLink="tel:+496994323620"
            />
            <section className={styles.container}>
                <div className={styles.title}>
                    <h1>Anmeldung</h1>
                </div>

                <div className={styles.mainContainer}>
                    <div className={styles.formContainer}>
                        <IntroducerSignupInviteForm
                            introducerUuid={introducerUuid}
                        />
                    </div>
                </div>
            </section>
        </div>
    );
};

export default StepZeroPage;
