import React, { useContext } from 'react';
import ReactMarkdown from 'react-markdown';
import { FormattedMessage } from 'react-intl';
import Img from 'gatsby-image';
import Button from '../../../../components/common/Button';
import TrustPilotLogoInline from '../../../../SVGComponents/TrustPilotLogoInline';
import { BasicDoughnut } from '../../../../SVGComponents/shapes';
import { getCountryBuild } from '../../../../util/localization';
import IwContext from 'aphrodite-shared/IwContext/IwContext';

import styles from './Header.module.scss';
import GatsbyImage from 'gatsby-image';
import classnames from 'classnames';

const Header = ({
    title,
    subtitle,
    button,
    image,
    trustpilot,
    trustpilotRating,
    trustpilotReviews,
    disclaimerText,
    additionalTrustElements,
    partnershipLogo
}) => {
    const context = useContext(IwContext);
    const trustElementsClassname = classnames(
        styles.additionalTrustElements,
        styles.TrustElement
    );

    function handleOpenStepZero() {
        context.openStepZero();
    }

    const headerCtaProps = {
        colour: 'primary',
        dataGaId: 'hero__cta'
    };

    return (
        <section>
            <BasicDoughnut
                color="mercury"
                className={styles.BasicMercuryDoughnut}
                sm="sm"
            />
            <div className={styles.Header}>
                {partnershipLogo && (
                    <GatsbyImage
                        className={styles.PartnershipLogo}
                        fluid={partnershipLogo.fluid}
                        imgStyle={{ objectFit: 'scale-down' }}
                    />
                )}
                <div className={styles.info}>
                    <h1>{title}</h1>
                    {subtitle && (
                        <ReactMarkdown source={subtitle.headerSubtitle} />
                    )}
                    {button.href ? (
                        <Button {...headerCtaProps} href={button.href}>
                            {button.text}
                        </Button>
                    ) : (
                        <Button
                            {...headerCtaProps}
                            onClick={handleOpenStepZero}>
                            {button.text}
                        </Button>
                    )}
                    {disclaimerText && disclaimerText.headerDisclaimerText && (
                        <ReactMarkdown
                            source={disclaimerText.headerDisclaimerText}
                            className={styles.DisclaimerText}
                        />
                    )}
                    <hr />
                </div>
                <div className={styles.image}>
                    <Img fluid={image.fluid} />
                    {typeof window !== 'undefined' &&
                        window.location.pathname.includes('fyrst') && (
                            <a
                                colour="primary"
                                className={styles.pdf}
                                href="https://static.iwoca.com/pdf/de/iwoca-Merkblatt_FYRST-v5.pdf"
                                target="_blank"
                                rel="noopener noreferrer">
                                Produktmerkblatt
                            </a>
                        )}
                    {typeof window !== 'undefined' &&
                        window.location.pathname.includes('airbank') && (
                            <a
                                colour="primary"
                                className={classnames(styles.pdf, styles.grey)}
                                href="https://static.iwoca.com/pdf/de/iwoca_Airbank_Merkblatt-v4.pdf"
                                target="_blank"
                                rel="noopener noreferrer">
                                Produktmerkblatt
                            </a>
                        )}
                    {typeof window !== 'undefined' &&
                        window.location.pathname.includes('fridayfinance') && (
                            <a
                                colour="primary"
                                className={styles.pdf}
                                href="https://static.iwoca.com/pdf/de/iwoca-Merkblatt_FridayFinance-v5.pdf"
                                target="_blank"
                                rel="noopener noreferrer">
                                Produktmerkblatt
                            </a>
                        )}
                </div>
            </div>

            <div className={styles.trustElements}>
                {trustpilotReviews && (
                    <a
                        className={styles.trustpilot}
                        href={
                            getCountryBuild() === 'uk'
                                ? 'https://uk.trustpilot.com/review/iwoca.co.uk'
                                : 'https://de.trustpilot.com/review/www.iwoca.de'
                        }
                        target="_blank"
                        rel="noopener noreferrer">
                        <TrustPilotLogoInline />
                        <p className={styles.trustpilotInfo}>
                            <FormattedMessage
                                id="trustPilot"
                                defaultMessage={trustpilot}
                                values={{
                                    trustpilotReviews: (
                                        <span>{trustpilotReviews}</span>
                                    ),
                                    trustpilotRating: (
                                        <span>{trustpilotRating}</span>
                                    )
                                }}
                            />
                        </p>
                    </a>
                )}
                {additionalTrustElements &&
                    additionalTrustElements.length > 0 &&
                    additionalTrustElements.map((trustElement) => {
                        return typeof window !== 'undefined' &&
                            window.location.pathname.includes('fyrst') ? (
                            <GatsbyImage
                                fluid={trustElement.fluid}
                                className={trustElementsClassname}
                                key={trustElement.id}
                            />
                        ) : (
                            <GatsbyImage
                                fluid={trustElement.fluid}
                                className={styles.TrustElement}
                                key={trustElement.id}
                            />
                        );
                    })}
            </div>
        </section>
    );
};

export default Header;
