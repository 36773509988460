import React from 'react';
import { BasicCircle, WedgedArch } from '../../../../SVGComponents/shapes';
import ReactMarkdown from 'react-markdown';
import styles from './PartnersDocuments.module.scss';
import PartnersSingleAmount from './PartnersSingleAmount';

const PartnersDocuments = (props) => {
    const {
        documentsTitle,
        documentsIntroText,
        documentsDetails,
        firstAmountTitle,
        firstAmountDetails,
        secondAmountTitle,
        secondAmountDetails,
        thirdAmountTitle,
        thirdAmountDetails
    } = props;
    return (
        <div className={styles.PartnersDocuments}>
            <div className={styles.wrapper}>
                <div className={styles.intro}>
                    <h2>{documentsTitle}</h2>
                    <ReactMarkdown
                        source={documentsIntroText.documentsIntroText}
                        escapeHtml={false}
                    />
                </div>
                <div className={styles.column}>
                    <div className={styles.detail}>
                        <ReactMarkdown
                            source={documentsDetails.documentsDetails}
                            escapeHtml={false}
                        />
                    </div>
                </div>
                <div className={styles.column}>
                    <PartnersSingleAmount
                        title={firstAmountTitle}
                        text={firstAmountDetails.firstAmountDetails}
                    />
                    <PartnersSingleAmount
                        title={secondAmountTitle}
                        text={secondAmountDetails.secondAmountDetails}
                    />
                    <PartnersSingleAmount
                        title={thirdAmountTitle}
                        text={thirdAmountDetails.thirdAmountDetails}
                    />
                </div>
            </div>
            <aside className={styles.shapes}>
                <BasicCircle color="denim" className={styles.circle} />
                <WedgedArch className={styles.arch} />
            </aside>
        </div>
    );
};

export default PartnersDocuments;
