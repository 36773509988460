import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import styles from './FooterLinks.module.scss';

import IwocaLogoSVG from '../../../SVGComponents/IwocaLogoSVG';
import IwLink from '../../IwLink/IwLink';

function useFooterData() {
    const { contentfulFooterLinks } = useStaticQuery(
        graphql`
            {
                contentfulFooterLinks {
                    section1 {
                        title
                        url
                        dataGaId
                    }
                    section1Title
                    section2 {
                        title
                        url
                        dataGaId
                    }
                    section2Title
                    section3 {
                        title
                        url
                        dataGaId
                    }
                    section3Title
                    section4 {
                        title
                        url
                        dataGaId
                    }
                    section4Title
                    section5 {
                        title
                        url
                        dataGaId
                    }
                    section5Title
                    section6 {
                        title
                        url
                        dataGaId
                    }
                    section6Title
                }
            }
        `
    );

    return contentfulFooterLinks;
}

const FooterLinks = () => {
    const footerData = useFooterData();
    let footerElements = [];
    for (let key in footerData) {
        if (Array.isArray(footerData[key])) {
            // Because of the way we pull in title and linked blogs from contentful we need to filter it out
            const title = footerData[key + 'Title'];

            footerElements.push(
                <LinkColumn
                    column={footerData[key]}
                    title={title}
                    dataGaId={footerData[key]['dataGaId']}
                />
            );
        }
    }

    return (
        <section className={styles.background}>
            <div className={styles.FooterLinks}>
                <IwLink to="/">
                    <IwocaLogoSVG className={styles.logo} />
                </IwLink>
                <div className={styles.footerLinksContainer}>
                    {footerElements}
                </div>
            </div>
        </section>
    );
};

const LinkColumn = ({ column, title }) => {
    const linkGaId = 'footer__link';

    return (
        <div className={styles.footerLinksList}>
            <h4>{title}</h4>
            {column.map((link) => {
                if (
                    link.url === '/verwendung_von_cookies/' ||
                    link.url === '/verwendung_von_cookies'
                ) {
                    return (
                        <a
                            dataGaId={link.dataGaId ? link.dataGaId : linkGaId}
                            href={link.url}>
                            {link.title}
                        </a>
                    );
                }
                return (
                    <IwLink
                        to={link.url}
                        dataGaId={link.dataGaId ? link.dataGaId : linkGaId}>
                        {link.title}
                    </IwLink>
                );
            })}
        </div>
    );
};

export default FooterLinks;
