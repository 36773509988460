import React from 'react';

import styles from './Metrics.module.scss';

const Metrics = ({ data, score = '', reviewsTotal }) => {
    const setScore = (text) =>
        text.replace(/{{trustpilot_score}}/i, score || '');
    const setReviewsTotal = (text) =>
        text.replace(/{{trustpilot_review_count}}/i, reviewsTotal || '');

    return (
        <div className={styles.metrics}>
            <div className={styles.container}>
                {data.feature.map((feature) => (
                    <div key={feature.id} className={styles.metricsItem}>
                        <img
                            className={styles.metricsIcon}
                            src={feature.icon.file.url}
                            alt="Metrics icon"
                        />
                        <div className={styles.metricsContent}>
                            <div
                                className={styles.metricsTitle}
                                dangerouslySetInnerHTML={{
                                    __html: setScore(
                                        feature.featureTitle.featureTitle
                                    )
                                }}
                            />
                            <div className={styles.metricsDescription}>
                                <p
                                    dangerouslySetInnerHTML={{
                                        __html: setReviewsTotal(
                                            setScore(
                                                feature.description.description
                                            )
                                        )
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Metrics;
