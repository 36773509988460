import React from 'react';
import ReactMarkdown from 'react-markdown';
import styles from './PartnersDocuments.module.scss';

const PartnersSingleAmount = (props) => {
    const { title, text } = props;
    return (
        <div className={styles.PartnersSingleAmount}>
            <h4>{title}</h4>
            <hr />
            <ReactMarkdown source={text} escapeHtml={false} />
        </div>
    );
};

export default PartnersSingleAmount;
