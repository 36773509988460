import React from 'react';
import PropTypes from 'prop-types';
import lodashGet from 'lodash/get';
import { BasicDoughnut, BasicArch } from '../../SVGComponents/shapes';
import styles from './HeroBannerSection.module.scss';
import Button from '../common/Button';

class HeroBannerSection extends React.Component {
    renderBackgroundImage(imageArray) {
        return (
            <div
                key={imageArray[0].id}
                className={styles.heroBackgroundImage}
                style={
                    lodashGet(imageArray[0].image, 'fixed.src') && {
                        backgroundImage: `url(${imageArray[0].image.fixed.src})`
                    }
                }
            />
        );
    }

    render() {
        const {
            data,
            overflowShapes = { BasicDoughnut: true, BasicArch: true }
        } = this.props;

        const { headline, subtitleText, content, button } = data;

        return (
            <>
                <section className={styles.heroBanner}>
                    {this.renderBackgroundImage(content)}
                    <div className={styles.heroContentContainer}>
                        <div className={styles.heroContent}>
                            {headline && <h1>{headline}</h1>}
                            {subtitleText && (
                                <h4 className={styles.subtitle}>
                                    {subtitleText.subtitle}
                                </h4>
                            )}
                            <div className={styles.buttonContainer}>
                                {button &&
                                    (Array.isArray(button) ? (
                                        button.map((btn, index) => (
                                            <Button
                                                href={btn.url}
                                                colored
                                                colour={btn.colour || 'primary'}
                                                dataGaId={
                                                    btn.dataGaId ||
                                                    (index === 0
                                                        ? 'hero__cta'
                                                        : 'hero2__cta')
                                                }
                                                className={styles.button}
                                                title={btn.buttonText}
                                                outline={
                                                    btn.colour === 'white'
                                                }>
                                                {btn.buttonText}
                                            </Button>
                                        ))
                                    ) : (
                                        <Button
                                            href={button.url}
                                            colored
                                            colour="primary"
                                            dataGaId={
                                                button.dataGaId || 'hero__cta'
                                            }
                                            className={styles.button}
                                            title={button.buttonText}>
                                            {button.buttonText}
                                        </Button>
                                    ))}
                            </div>
                        </div>
                    </div>
                </section>
                {overflowShapes.BasicArch && (
                    <BasicArch className={styles.BasicArch} />
                )}
                {overflowShapes.BasicDoughnut && (
                    <BasicDoughnut
                        color="london"
                        className={styles.BasicDoughnut}
                    />
                )}
            </>
        );
    }
}

HeroBannerSection.defaultProps = {
    timeToTransition: 5000,
    loggedIn: false
};

HeroBannerSection.propTypes = {
    data: PropTypes.shape({
        headline: PropTypes.string,
        subtitleText: PropTypes.shape({
            subtitle: PropTypes.string
        }),
        content: PropTypes.arrayOf(
            PropTypes.shape({
                image: PropTypes.shape({
                    file: PropTypes.shape({
                        url: PropTypes.string
                    })
                })
            })
        ),
        button: PropTypes.shape({
            url: PropTypes.string,
            buttonText: PropTypes.string
        })
    })
};

export default HeroBannerSection;
