import React from 'react';

import FooterLinks from './FooterLinks/FooterLinks';
import FooterContact from './FooterContact/FooterContact';
import FooterDisclaimer from './FooterDisclaimer/FooterDisclaimer';

const Footer = ({
    ctaButton,
    phoneNumber,
    phoneNumberLink,
    email,
    hideCta = false,
    hidePhoneNumber = false
}) => {
    return (
        <footer>
            <FooterLinks />
            <FooterContact
                ctaButton={ctaButton}
                phoneNumber={phoneNumber}
                phoneNumberLink={phoneNumberLink}
                email={email}
                hidePhoneNumber={hidePhoneNumber}
                hideCta={hideCta}
            />
            <FooterDisclaimer />
        </footer>
    );
};

export default Footer;
