import Cookies from 'js-cookie';
import { buildLendingAPI } from './lendingApiHelper.js';

export async function lendingAPIPostState(signUpData) {
    return await buildLendingAPI('/api/lending/v1/state/', {
        method: 'POST',
        body: JSON.stringify({
            data: signUpData
        })
    });
}

export async function lendingAPIPutState(stateKey, signUpData) {
    return await buildLendingAPI(
        `/api/lending/v1/state/${stateKey}/`,
        {
            method: 'PUT',
            body: JSON.stringify({
                data: signUpData
            })
        },
        { 'x-csrftoken': Cookies.get('csrftoken') }
    );
}

export async function lendingAPIPutApplication(stateKey, signUpData) {
    return await buildLendingAPI(
        `/api/lending/edge/application/${stateKey}/`,
        {
            method: 'PUT',
            body: JSON.stringify({
                data: signUpData
            })
        },
        { 'x-csrftoken': Cookies.get('csrftoken') }
    );
}

export async function lendingAPIGetEmailAvailability(emailAddress) {
    const encodedEmailAddress = encodeURIComponent(emailAddress);
    return await buildLendingAPI(
        `/api/lending/v1/email_availability/?email=${encodedEmailAddress}`,
        {
            method: 'GET'
        }
    );
}

export async function lendingAPIPostSplitTest(data) {
    return await buildLendingAPI('/api/split_tests/add_to_split_test/', {
        method: 'POST',
        body: JSON.stringify({
            ...data
        }),
        credentials: 'same-origin'
    });
}
