import React from 'react';
import ReactMarkdown from 'react-markdown';
import ShortBlockQuote from './Quotes/ShortBlockQuote/ShortBlockQuote';
import LongInlineQuote from './Quotes/LongInlineQuote/LongInlineQuote';
import CTABox from './CTABox/CTABox';
import Author from './Author/Author';
import MediaAndCaption from './MediaAndCaption/MediaAndCaption';
import Navigator from './Navigator/Navigator';
import RecentArticles from './RecentArticles/RecentArticles';
import styles from './ContentBlock.module.scss';
import GatsbyImg from 'gatsby-image';
import Spacer from './Spacer/Spacer';
import ContentfulBlogContentBlock from './ContentfulBlogContentBlock/ContentfulBlogContentBlock.jsx';
import Social from './Social/Social';

export function renderContent(allContent) {
    if (!allContent) {
        console.error('Article Page: No content provided');
        return [];
    }

    const result = allContent.map((content) => {
        let component;

        switch (content.__typename) {
            case 'ContentfulBlogContentBlock':
                component = (
                    <ContentfulBlogContentBlock
                        blogContent={content.blogContent.blogContent}
                    />
                );
                break;
            case 'ContentfulBlogCallToActionBox':
                component = (
                    <CTABox
                        callToAction={content.callToAction}
                        copy={content.copy}
                        key={content.id}
                        image={content.image}
                    />
                );
                break;
            case 'ContentfulBlogMediaAndCaption':
                component = <MediaAndCaption data={content} key={content.id} />;
                break;
            default:
                component = null;
                break;
        }
        return component;
    });
    return result;
}
const ContentBlock = ({
    content,
    author,
    links,
    categoryTag,
    articleName,
    headerImage,
    fullSlug,
    updatedAt
}) => {
    return (
        <div className={styles.contentWrapper}>
            <Spacer />
            <div className={styles.headerImageWrapper}>
                <GatsbyImg
                    className={styles.HeaderImage}
                    fluid={headerImage.fluid}
                />
            </div>

            <div className={styles.ContentBlock}>
                {renderContent(content)}
                <RecentArticles
                    articleName={articleName}
                    categoryTag={categoryTag}
                />

                <Navigator links={links} fullSlug={fullSlug} />
                <Social fullSlug={fullSlug} />
                {author && <Author author={author} date={updatedAt} />}
            </div>
        </div>
    );
};

export default ContentBlock;
