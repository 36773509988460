import React, { useContext } from 'react';
import { Field, Form, Formik } from 'formik';
import lodashStartCase from 'lodash/startCase';
import yup from 'yup';

import styles from './IntroducerSignupForm.module.scss';
import IwContext from 'aphrodite-shared/IwContext/IwContext';
import { logErrorToDataLayer } from '../../../util/googleTracking';
import { Input, Label, Button, Select, FormErrorMessage } from '@iwoca/orion';
import * as questions from './IntroducerSignupFormQuestions';
import { submitIntrodcerSignup } from './IntroducerSignupFormHelper';

const IntroducerSignupInviteForm = ({ introducerUuid }) => {
    const context = useContext(IwContext);

    const handleSubmitForm = async (values, formik) => {
        try {
            await submitIntrodcerSignup(values);
            window.location.href = '/partner/account';
        } catch (error) {
            context.showApiError('Hmm, something went wrong. Try again?');
            logErrorToDataLayer('Introducer Signup - Form submission error');

            // Prevent freeze in submission state
            formik.setSubmitting(false);
            if (typeof window !== 'undefined') {
                window.Raven.captureException(error);
            }
        }
    };
    return (
        <Formik
            initialValues={{
                [questions.TITLE]: questions.TITLE_OPTIONS[0],
                [questions.FIRST_NAME]: '',
                [questions.LAST_NAME]: '',
                [questions.COMPANY_EMAIL]: '',
                [questions.PHONE_NUMBER]: '',
                [questions.TERMS]: false,
                [questions.UUID]: introducerUuid
            }}
            onSubmit={(values, formik) => handleSubmitForm(values, formik)}
            validationSchema={introducerSignupFormSchema()}
            validateOnChange={false}>
            {(props) => {
                const { isSubmitting, touched, errors, handleSubmit } = props;

                return (
                    <Form
                        onSubmit={(e) => {
                            e.preventDefault();
                            handleSubmit();
                        }}>
                        <div className={styles.question}>
                            <Label htmlFor={questions.TITLE}>Anrede</Label>
                            <Field id={questions.TITLE} name={questions.TITLE}>
                                {({ field, meta }) => (
                                    <div className={styles.inputArea}>
                                        <Select {...field}>
                                            {questions.TITLE_OPTIONS.map(
                                                (option) => {
                                                    return (
                                                        <option value={option}>
                                                            {lodashStartCase(
                                                                option
                                                            )}
                                                        </option>
                                                    );
                                                }
                                            )}
                                        </Select>
                                        {meta.touched && meta.error && (
                                            <FormErrorMessage
                                                message={meta.error}
                                            />
                                        )}
                                    </div>
                                )}
                            </Field>
                        </div>

                        <div className={styles.question}>
                            <Label htmlFor={questions.FIRST_NAME}>
                                Vorname
                            </Label>
                            <Field
                                id={questions.FIRST_NAME}
                                name={questions.FIRST_NAME}>
                                {({ field, meta }) => (
                                    <div className={styles.inputArea}>
                                        <Input {...field} />
                                        {meta.touched && meta.error && (
                                            <FormErrorMessage
                                                message={meta.error}
                                            />
                                        )}
                                    </div>
                                )}
                            </Field>
                        </div>

                        <div className={styles.question}>
                            <Label htmlFor={questions.LAST_NAME}>
                                Nachname
                            </Label>

                            <Field
                                id={questions.LAST_NAME}
                                name={questions.LAST_NAME}>
                                {({ field, meta }) => (
                                    <div className={styles.inputArea}>
                                        <Input {...field} />
                                        {meta.touched && meta.error && (
                                            <FormErrorMessage
                                                message={meta.error}
                                            />
                                        )}
                                    </div>
                                )}
                            </Field>
                        </div>

                        <div className={styles.question}>
                            <Label htmlFor={questions.COMPANY_EMAIL}>
                                E-Mail
                            </Label>
                            <Field
                                id={questions.COMPANY_EMAIL}
                                name={questions.COMPANY_EMAIL}>
                                {({ field, meta }) => (
                                    <div className={styles.inputArea}>
                                        <Input {...field} />
                                        {meta.touched && meta.error && (
                                            <FormErrorMessage
                                                message={meta.error}
                                            />
                                        )}
                                    </div>
                                )}
                            </Field>
                        </div>

                        <div className={styles.question}>
                            <Label htmlFor={questions.PHONE_NUMBER}>
                                Telefonnummer
                            </Label>
                            <Field
                                id={questions.PHONE_NUMBER}
                                name={questions.PHONE_NUMBER}>
                                {({ field, meta }) => (
                                    <div className={styles.inputArea}>
                                        <Input {...field} />
                                        {meta.touched && meta.error && (
                                            <FormErrorMessage
                                                message={meta.error}
                                            />
                                        )}
                                    </div>
                                )}
                            </Field>
                        </div>

                        <div className={styles.submitArea}>
                            <div className={styles.terms}>
                                <Field
                                    id={questions.TERMS}
                                    name={questions.TERMS}>
                                    {({ field, meta }) => (
                                        <Input type="checkbox" {...field} />
                                    )}
                                </Field>

                                <div>
                                    <Label
                                        className={styles.termsLabel}
                                        htmlFor={questions.TERMS}>
                                        Ich habe die{' '}
                                        <a
                                            href="https://assets.ctfassets.net/wt7ul1fonsxk/11lNLHYaSsVOp8FHGllsT0/2ab2ff094b10f501a05dd6b77aa4c1b9/AGB_des_iwoca_Produktpartnerprogramms__1_.pdf"
                                            target="_blank"
                                            rel="noopener noreferrer">
                                            iwoca Partner AGB
                                        </a>{' '}
                                        gelesen und stimme diesen zu.
                                    </Label>
                                    {touched[questions.TERMS] &&
                                        errors[questions.TERMS] && (
                                            <FormErrorMessage
                                                message={
                                                    errors[questions.TERMS]
                                                }
                                            />
                                        )}
                                </div>
                            </div>
                            <Field id={questions.UUID} name={questions.UUID}>
                                {({ field, meta }) => (
                                    <Input type="hidden" {...field} />
                                )}
                            </Field>
                            <Button
                                disabled={isSubmitting}
                                variant="primary"
                                type="submit">
                                Anmeldung
                            </Button>
                        </div>
                    </Form>
                );
            }}
        </Formik>
    );
};

function introducerSignupFormSchema() {
    return yup.object({
        [questions.TITLE]: yup
            .string()
            .required()
            .oneOf(questions.TITLE_OPTIONS)
            .required('Dies ist ein Pflichtfeld'),
        [questions.FIRST_NAME]: yup
            .string()
            .required()
            .min(0)
            .required('Dies ist ein Pflichtfeld'),
        [questions.LAST_NAME]: yup
            .string()
            .required()
            .min(0)
            .required('Dies ist ein Pflichtfeld'),
        [questions.COMPANY_EMAIL]: yup
            .string()
            .email()
            .required()
            .min(0)
            .required('Dies ist ein Pflichtfeld'),
        [questions.PHONE_NUMBER]: yup
            .string()
            .required()
            .min(0)
            .required('Dies ist ein Pflichtfeld'),
        [questions.TERMS]: yup.bool().oneOf([true], 'Dies ist ein Pflichtfeld')
    });
}

export default IntroducerSignupInviteForm;
