import React, { useState, useContext } from 'react';
import classnames from 'classnames';

import Button from 'aphrodite-shared/ui/Button/Button';
import IwContext from 'aphrodite-shared/IwContext/IwContext';
import IwSlider from 'aphrodite-shared/components/IwSlider/IwSlider';

import { formatMoney } from 'aphrodite-shared/util/helpers';
import {
    getRepayments,
    sendCalculatorInteractionToGA
} from 'aphrodite-shared/util/loanCalculator';

import styles from 'aphrodite-shared/components/CalculatorRCF/CalculatorRCF.module.scss';
import ebayCalcStyles from './EbayCalculator.module.scss';

const MIN_LOAN_AMOUNT = 500;
const MAX_LOAN_AMOUNT = 200000;
const DEFAULT_AMOUNT = 1000;

const MAX_MONTHS = 12;
const DEFAULT_MONTHS = 6;

const INTEREST_RATE = 1.75;

const EbayCalculator = ({ minLoanAmount }) => {
    const [amount, setAmount] = useState(DEFAULT_AMOUNT);
    const [months, setMonths] = useState(DEFAULT_MONTHS);
    const [buttonAmount, setButtonAmount] = useState(DEFAULT_AMOUNT);

    const { equalRepayment, totalRepayment } = getRepayments({
        amount,
        months,
        interestRate: INTEREST_RATE
    });

    const { totalRepayment: totalRepaymentPrev } = getRepayments({
        amount,
        months: months - 1,
        interestRate: INTEREST_RATE
    });

    const moneySavedWhenRepayEarly = totalRepayment - totalRepaymentPrev;

    const context = useContext(IwContext);

    function handleOpenStepZero() {
        context.openStepZero();
        context.setBorrowAmount(buttonAmount);
    }

    const afterAmountSliderChange = (value) => {
        sendCalculatorInteractionToGA('ebay-slider', 'slider', 'amount', value);
        setButtonAmount(value);
    };

    const afterDurationSliderChange = (value) => {
        sendCalculatorInteractionToGA(
            'ebay-slider',
            'slider',
            'duration',
            value
        );
    };

    return (
        <div className={styles.CalculatorRCF}>
            <div className={styles.sliders}>
                <div className={styles.slider}>
                    <IwSlider
                        type="currency"
                        onChange={(amount) => setAmount(amount)}
                        onAfterChange={afterAmountSliderChange}
                        ease={MAX_LOAN_AMOUNT >= 50000}
                        min={minLoanAmount ? minLoanAmount : MIN_LOAN_AMOUNT}
                        max={MAX_LOAN_AMOUNT}
                        defaultValue={DEFAULT_AMOUNT}
                        step={MIN_LOAN_AMOUNT}
                        color="blue"
                    />
                    <p>Kredithöhe</p>
                </div>
                <div className={styles.slider}>
                    <IwSlider
                        type="months"
                        onChange={(months) => setMonths(months)}
                        onAfterChange={afterDurationSliderChange}
                        min={1}
                        max={MAX_MONTHS}
                        defaultValue={DEFAULT_MONTHS}
                        step={1}
                        color="orange"
                    />
                    <p>Laufzeit</p>
                </div>
            </div>

            <table className={ebayCalcStyles.repaymentBreakdown}>
                <tbody>
                    <tr
                        className={ebayCalcStyles.repaymentBreakdownCost}
                        data-testid="ebayCalculator-repaymentBreakdown">
                        <td>Gesamt&shy;kosten</td>
                        <td>
                            <span
                                className={classnames(
                                    styles.blue,
                                    styles.monthlyRepayment
                                )}>
                                {formatMoney(totalRepayment - amount)}
                            </span>
                        </td>
                    </tr>
                    <tr data-testid="ebayCalculator-totalRepayment">
                        <td>Gesamt&shy;rückzahlung</td>
                        <td>{formatMoney(totalRepayment)}</td>
                    </tr>
                    <tr data-testid="ebayCalculator-monthlyRepayment">
                        <td>
                            {months} monatliche Rückzahlung
                            {months > 1 ? 'en' : ''} von
                        </td>
                        <td>{equalRepayment}</td>
                    </tr>
                </tbody>
            </table>

            <p
                className={ebayCalcStyles.repayEarly}
                data-testid="ebayCalculator-repayEarly">
                Sie würden{' '}
                <strong className={styles.blue}>
                    {formatMoney(moneySavedWhenRepayEarly)}
                </strong>{' '}
                an Zinskosten sparen, wenn Sie einen Monat früher zurückzahlen.
            </p>

            <div className={styles.actions}>
                <Button
                    onClick={handleOpenStepZero}
                    data-testid="ebayCalculator-borrowButton">
                    <span className={styles.MoneyButton}>
                        {formatMoney(buttonAmount)}
                    </span>{' '}
                    anfragen
                </Button>
            </div>
        </div>
    );
};

export default EbayCalculator;
