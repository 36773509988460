import React from 'react';

const Chevron = ({ className }) => {
    return (
        <svg
            data-testid="Chevron"
            className={className}
            width="18"
            height="9"
            viewBox="0 0 18 9"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M2 1.96387L8.82518 6.6077L15.5165 1.96387"
                stroke="#143B6B"
                strokeWidth="3"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export default Chevron;
