import React from 'react';
import { graphql } from 'gatsby';
import Website from '../templates/websitePage';

export default (props) => <Website {...props} />;

export const query = graphql`
    query ErrorPage {
        contentfulPage(url: { eq: "/404" }) {
            ...PageData
        }
        contentfulGlobalVariables {
            labelCurrency
            labelMonthSingular
            labelMonthPlural
            thousandsSeparator
            decimalSeparator
        }
        allContentfulAsset {
            edges {
                node {
                    file {
                        url
                        fileName
                    }
                }
            }
        }
        applyForm: contentfulForm(type: { eq: "customer_signup_form" }) {
            id
            type
            title
            subtitle {
                subtitle
            }
            submitButtonText
            contentfulfields {
                __typename
                ...FieldCheckbox
                ...FieldText
                ...FieldSelect
            }
        }
        allContentfulVariable {
            edges {
                node {
                    id
                    name
                    variableId
                    initialValue {
                        initialValue
                    }
                    fallbackValue {
                        fallbackValue
                    }
                }
            }
        }
        contentfulHreflangManagement(name: { eq: "Hreflang Management" }) {
            ...HreflangManagement
        }
        site {
            siteMetadata {
                title
            }
        }
    }
`;
