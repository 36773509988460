import React, { useState } from 'react';
import styles from './Bubble.module.scss';
import GatsbyImg from 'gatsby-image';
import classnames from 'classnames';

function Bubble({ id, testimonial }) {
    const [isHover, setHover] = useState(false);

    const isHovered = isHover ? styles.Hovered : '';
    return (
        <div
            className={classnames(
                styles.bubble,
                isHovered,
                `${styles[`Bubble_${id}`]}`
            )}
            data-ga-id="testimonial">
            <div
                className={styles.hover}
                onMouseEnter={() => setHover(true)}
                onMouseLeave={() => setHover(false)}>
                <GatsbyImg
                    fluid={testimonial.bubble.fluid}
                    className={styles.BubbleImg}
                />
            </div>
            <div
                className={classnames(
                    styles.bubblePopup,
                    `${styles[`bubblePopup_${id}`]}`
                )}>
                <h3>{testimonial.name}</h3>
                <h4>{testimonial.companyName}</h4>
                <p>{testimonial.testimony}</p>
                <span className={styles.bubbleArrow}></span>
            </div>
        </div>
    );
}

export default Bubble;
