import React from 'react';
import ReactMarkdown from 'react-markdown';
import ConstellationSquares from './shapes/shapes';
import Bubble from './Bubbble/Bubble';
import styles from './Testimonials.module.scss';

const Testimonials = ({ testimonials, heading, paragraph }) => {
    return (
        <div className={styles.Testimonials}>
            <ConstellationSquares>
                <div className={styles.upperRow}>
                    {testimonials.slice(0, 6).map((testimonial, i) => (
                        <Bubble key={i} id={i + 1} testimonial={testimonial} />
                    ))}
                </div>
                <div className={styles.middleRow}>
                    <h2>{heading}</h2>
                    <p>
                        <ReactMarkdown source={paragraph} />
                    </p>
                </div>
                <div className={styles.lowerRow}>
                    {testimonials.slice(6, 12).map((testimonial, i) => (
                        <Bubble
                            key={i + 6}
                            id={i + 7}
                            testimonial={testimonial}
                        />
                    ))}
                </div>
            </ConstellationSquares>
        </div>
    );
};

export default Testimonials;
