import { defineMessages } from 'react-intl';

export default defineMessages({
    phone_number: {
        id: '_.generic_landing__navbar_phone_number',
        defaultMessage: '069 / 9432 3620'
    },
    phone_number_link: {
        id: '_.generic_landing__navbar_phone_number_link',
        defaultMessage: 'tel:+496994323620'
    },
    apply: {
        id: '_.generic_landing__navbar_apply',
        defaultMessage: 'Apply'
    },
    login: {
        id: '_.navbar_login',
        defaultMessage: 'Log in'
    },
    my_account: {
        id: '_.generic_landing__navbar_my_account',
        defaultMessage: 'My account'
    }
});
