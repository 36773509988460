import React from 'react';
import SVG from 'react-inlinesvg';
import styles from './HowItWorksStep.module.scss';

function Step({ data }) {
    return (
        <div className={styles.step}>
            <div className={styles.text}>
                <SVG className={styles.icon} src={data.stepNumber.file.url} />
                <h4 className={styles.title}>{data.title}</h4>
                <p className={styles.description}>
                    {data.description && data.description.description}
                </p>
                {data.callToActionLinkText && (
                    <a
                        className={styles.action}
                        href={data.callToActionLinkUrl}>
                        {data.callToActionLinkText}
                        {data.callToActionLinkIcon && (
                            <SVG
                                className={styles.icon}
                                src={data.callToActionLinkIcon.file.url}
                            />
                        )}
                    </a>
                )}
            </div>
        </div>
    );
}

export default Step;
