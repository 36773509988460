import React, { useState } from 'react';
import DepartmentSelector from './DepartmentSelector/DepartmentSelector';
import MobileDepartmentSelector from './MobileDepartmentSelector/MobileDeptSelector';
import { BasicSquare, BasicDoughnut } from '../../../../SVGComponents/shapes';
import JobListing from './JobListing/JobListing';
import styles from './JobPosting.module.scss';
import { Element } from 'react-scroll';
import { getCountryBuild } from '../../../../util/localization';

const JobPosting = ({ jobs, title }) => {
    const allText = getCountryBuild() === 'uk' ? 'All' : 'Alle';

    const [selectedJobs, setJobs] = useState(jobs);
    const [departments, cities] = getCitiesDepartments(jobs);
    const [currDepartment, setDepartment] = useState(allText);
    const jobDepartmentTable = getJobDepartmentTable(jobs);

    const selectDepartment = (department) => {
        setDepartment(department);
        setJobs(jobDepartmentTable[department]);
    };

    return (
        <Element name="AllJobs">
            <div className={styles.JobPosting}>
                <BasicSquare
                    className={styles.BasicCoralSquareRight}
                    size="sm"
                />
                <BasicSquare
                    className={styles.BasicCoralSquareLeft}
                    size="sm"
                />

                <BasicSquare
                    className={styles.BasicDenimSquare}
                    size="sm"
                    color="denim"
                />

                <h2>{title}</h2>
                <DepartmentSelector
                    departments={departments}
                    setDepartment={selectDepartment}
                    jobTable={jobDepartmentTable}
                />
                <MobileDepartmentSelector
                    departments={departments}
                    setDepartment={selectDepartment}
                    jobTable={jobDepartmentTable}
                />
                <JobListing
                    jobs={selectedJobs}
                    cities={cities}
                    department={currDepartment}
                />
            </div>
            <BasicDoughnut
                color="london95"
                className={styles.BasicDoughnut}
                size="sm"
            />
        </Element>
    );
};

const getCitiesDepartments = (jobs) => {
    let departments = [];
    let cities = [];

    jobs.forEach((job) => {
        const department = job.department;
        if (!departments.includes(department) && department !== 'ANY') {
            departments.push(department);
        }

        const city = job.location.telecommuting ? 'Remote' : job.location.city;
        if (!cities.includes(city) && city !== job.location.country) {
            cities.push(city);
        }
    });

    return [departments, cities];
};

const getJobDepartmentTable = (jobs) => {
    let jobDepartmentTable = {};
    // SORT
    jobs.forEach((job) => {
        let department = job.department;
        if (jobDepartmentTable[department]) {
            jobDepartmentTable[department].push(job);
        } else {
            jobDepartmentTable[department] = [job];
        }
    });

    jobDepartmentTable.All = jobs;

    return jobDepartmentTable;
};

export default JobPosting;
