import React from 'react';
import { LongArch } from '../../../../SVGComponents/shapes';
import WantoToKnowMoreShapes from './shapes/shapes';
import styles from './WantToKnowMore.module.scss';
import IwAccordion from '../../../../components/IwAccordion/IwAccordion';
import ReactMarkdown from 'react-markdown';
import classnames from 'classnames';

const WantToKnowMore = ({ faq, title, subtitle, className }) => {
    return (
        <section className={classnames(styles.WantToKnowMore, className)}>
            <LongArch className={styles.LongArch} />
            <WantoToKnowMoreShapes>
                <h2>{title}</h2>
                <div className={styles.sublineWrapper}>
                    <ReactMarkdown
                        source={subtitle}
                        className={styles.subtitle}
                    />
                </div>
                <div className={styles.AccordionContainer}>
                    {faq.map((item, i) => (
                        <IwAccordion
                            key={i}
                            title={item.question}
                            content={item.answer}
                            className={styles.Accordion}
                            color={item.color}
                        />
                    ))}
                </div>
            </WantoToKnowMoreShapes>
        </section>
    );
};

export default WantToKnowMore;
