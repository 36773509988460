import React from 'react';
import Img from 'gatsby-image';
import ReactMarkdown from 'react-markdown';
import { BasicCircle, BasicDoughnut } from '../../../../SVGComponents/shapes';

import styles from './Features.module.scss';

const Features = ({ title, featureText, mobileTitle, features }) => {
    return (
        <section>
            <BasicCircle
                color="london75"
                className={styles.BasicLondonCircle}
                size="sm"
            />
            <BasicDoughnut
                color="denim45"
                className={styles.BasicDenimDoughnut}
            />
            <BasicCircle className={styles.BasicCorcalCircle} size="sm" />
            <div className={styles.Features}>
                <h2 className={styles.title}>{title}</h2>
                <h2 className={styles.mobileTitle}>{mobileTitle}</h2>
                <p className={styles.featureText}>{featureText}</p>
                <hr />
                <div className={styles.infoContainer}>
                    {features &&
                        features.map((feature, i) => (
                            <div className={styles.info} key={i}>
                                {feature.icon && (
                                    <div className={styles.icon}>
                                        <Img fluid={feature.icon.fluid} />
                                    </div>
                                )}

                                <h3>{feature.title}</h3>
                                <ReactMarkdown
                                    className={styles.description}
                                    source={feature.description.description}
                                />
                            </div>
                        ))}
                </div>
            </div>
        </section>
    );
};

export default Features;
