import React from 'react';

const LeftQuote = ({}) => {
    return (
        <svg
            width="237"
            height="191"
            viewBox="0 0 237 191"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M-36.5274 93.0111C-36.5274 67.0251 -29.2854 43.1691 -14.8014 21.4431C-0.317447 -0.28289 19.2786 -16.4709 43.9866 -27.1209C45.6906 -27.9729 48.0336 -28.3989 51.0156 -28.3989C58.2576 -28.3989 63.1566 -24.7779 65.7126 -17.5359L75.2976 6.74611C76.5756 9.7281 77.2146 12.2841 77.2146 14.4141C77.2146 19.9521 73.1676 25.2771 65.0736 30.3891C52.2936 38.9091 42.9216 47.4291 36.9576 55.9491C31.4196 64.0431 28.6506 72.9891 28.6506 82.7871V91.0941C46.9686 91.0941 61.4526 95.5671 72.1026 104.513C82.7526 113.033 88.0776 124.748 88.0776 139.658C88.0776 154.994 82.7526 167.348 72.1026 176.72C61.8786 186.092 48.4596 190.778 31.8456 190.778C10.5456 190.778 -6.28145 182.471 -18.6354 165.857C-30.5634 149.243 -36.5274 126.452 -36.5274 97.4841V93.0111ZM111.721 93.0111C111.721 67.0251 118.963 43.1691 133.447 21.4431C147.931 -0.28289 167.527 -16.4709 192.235 -27.1209C193.939 -27.9729 196.282 -28.3989 199.264 -28.3989C206.506 -28.3989 211.405 -24.7779 213.961 -17.5359L223.546 6.74611C224.824 9.7281 225.463 12.2841 225.463 14.4141C225.463 19.9521 221.416 25.2771 213.322 30.3891C200.542 38.9091 191.17 47.4291 185.206 55.9491C179.668 64.0431 176.899 72.9891 176.899 82.7871V91.0941C195.217 91.0941 209.701 95.5671 220.351 104.513C231.001 113.033 236.326 124.748 236.326 139.658C236.326 154.994 231.001 167.348 220.351 176.72C210.127 186.092 196.708 190.778 180.094 190.778C158.794 190.778 141.967 182.471 129.613 165.857C117.685 149.243 111.721 126.452 111.721 97.4841V93.0111Z"
                fill="#234152"
            />
        </svg>
    );
};

export default LeftQuote;
