import React from 'react';
import {
    BasicCircle,
    BasicDoughnut,
    BasicGrid,
    BasicSquare
} from '../../../../../SVGComponents/shapes';
import styles from './shapes.module.scss';

const WantoToKnowMoreShapes = ({ children }) => {
    return (
        <div className={styles.shapeContainer}>
            <BasicDoughnut
                color="denim45"
                className={styles.BasicDoughnut}
                size="sm"
            />
            <BasicCircle
                color="denim"
                className={styles.BasicCircle}
                size="sm"
            />

            <BasicGrid className={styles.BasicGrid} />
            <BasicSquare
                color="london"
                className={styles.BasicSquare}
                rotate="left"
                size="sm"
            />
            {children}
        </div>
    );
};

export default WantoToKnowMoreShapes;
