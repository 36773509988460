import React from 'react';

import SocialMediaBar from './SocialMediaBar/SocialMediaBar';
import Chevron from '../../../../../SVGComponents/Chevron';
import TrustPilotInlineRating from '../TrustPilotInlineRating/TrustPilotInlineRating';
import styles from './Navigator.module.scss';

const Navigator = ({ links, fullSlug }) => {
    return (
        <div className={styles.Container}>
            <div className={styles.NavigatorWrapper}>
                <div className={styles.Navigator}>
                    {links && (
                        <ul>
                            {links.map((link, i) => {
                                return (
                                    <li key={i} className={styles.Link}>
                                        <a href={link.link}>
                                            {link.displayName}
                                        </a>
                                        <Chevron className={styles.Chevron} />
                                    </li>
                                );
                            })}
                        </ul>
                    )}
                    <SocialMediaBar fullSlug={fullSlug} />
                </div>
            </div>
        </div>
    );
};

export default Navigator;
