import React from 'react';

import Reviews from '../components/LegacyPage/Reviews/Reviews';
import ContactSection from '../components/LegacyPage/ContactSection';
import CTASection from '../components/LegacyPage/CtaButtonSection';
import FeaturesBarSection from '../components/LegacyPage/FeaturesBarSection';
import HeroBannerSection from '../components/LegacyPage/HeroBannerSection';
import HowItWorksSection from '../components/LegacyPage/HowItWorksSection';
import CustomerVideoSection from '../components/LegacyPage/CustomerVideoSection';
import RepaymentCalculatorSection from '../components/LegacyPage/RepaymentCalculatorSection';

export default function legacyPageComponentParser(typename, data) {
    const { props, sectionData, index } = data;

    // Match react component
    switch (typename) {
        case 'ContentfulSectionHeroBanner':
            return (
                <HeroBannerSection
                    data={sectionData}
                    loggedIn={props.isLoggedIn}
                    currentPage={props.pageData}
                    key={index}
                    overflowShapes={{}}
                />
            );
        case 'ContentfulSectionFeaturesBar':
            return (
                <FeaturesBarSection
                    data={sectionData}
                    key={index}
                    score={props.reviewsScore}
                    reviewsTotal={props.reviewsTotal}
                />
            );
        case 'ContentfulSectionHowItWorksScroll':
            return <HowItWorksSection data={sectionData} key={index} />;
        case 'ContentfulSectionCustomerReviews':
            return (
                <Reviews
                    data={sectionData}
                    key={index}
                    reviews={props.reviews}
                />
            );
        case 'ContentfulSectionRepaymentCalculator':
            return (
                <RepaymentCalculatorSection
                    data={sectionData}
                    key={index}
                    enableTable={sectionData.showTable}
                />
            );
        case 'ContentfulSectionCustomerVideo':
            return <CustomerVideoSection data={sectionData} key={index} />;
        case 'ContentfulSectionCta':
            return <CTASection data={sectionData} key={index} />;

        case 'ContentfulSectionContact':
            return <ContactSection data={sectionData} key={index} />;
        default:
            return null;
    }
}
