import React from 'react';
import ReactMarkdown from 'react-markdown';
import styles from './MessageSection.module.scss';

class MessageSection extends React.Component {
    render() {
        const { data } = this.props;

        return (
            <div className={styles.errorPage}>
                <div className={styles.content}>
                    {data.supportingImage && (
                        <img
                            className={styles.image}
                            src={data.supportingImage.file.url}
                            alt="error icon"
                        />
                    )}
                    <h1 className={styles.title}>{data.title}</h1>
                    <ReactMarkdown source={data.description.description} />
                </div>
            </div>
        );
    }
}

export default MessageSection;
