import React from 'react';
import { graphql } from 'gatsby';
import { Formik } from 'formik';
import Proptypes from 'prop-types';
import yup from 'yup';
import styles from './EmailCaptureForm.module.scss';
import { postEmailHubSpot } from '../../../api/hubSpot';

class EmailCaptureForm extends React.Component {
    constructor() {
        super();
        this.state = {
            submitted: false
        };

        this.handleSubmit = this.handleSubmit.bind(this);
    }

    async handleSubmit(values) {
        this.refs.form.getFormikBag().validateForm();
        this.setState({ submitted: true });
        if (values.email) {
            const hubspotNewsLetterId = '3901e8c2-c504-45a6-854c-cc64bec91fc8';
            await postEmailHubSpot(values.email, hubspotNewsLetterId);
        }
    }

    generateValidationSchema() {
        const schema = yup.object().shape({
            email: yup.string().email('Please enter valid email')
        });

        return schema;
    }

    render() {
        const { placeholder, required, className, successMessage } = this.props;

        let formClassName = this.state.submitted
            ? `${styles.inputContainer} ${styles.inputContainerSubmitted}`
            : `${styles.inputContainer}`;

        return (
            <div className={`${className && className}`}>
                <Formik
                    ref="form"
                    onSubmit={this.handleSubmit}
                    validationSchema={this.generateValidationSchema()}>
                    {({
                        values = {},
                        errors = {},
                        touched = {},
                        handleBlur,
                        handleSubmit,
                        isSubmitting,
                        setFieldValue
                    }) => (
                        <React.Fragment>
                            <form
                                className={formClassName}
                                onSubmit={handleSubmit}>
                                <input
                                    type="email"
                                    name="email"
                                    value={values.email}
                                    placeholder={placeholder}
                                    required={required}
                                    error={errors['email']}
                                    onKeyDown={(e) => {
                                        if (e.keyCode === 32)
                                            e.preventDefault();
                                    }}
                                    onChange={(e) =>
                                        setFieldValue(
                                            'email',
                                            e.target.value.replace(/\s/g, '')
                                        )
                                    }
                                    onBlur={handleBlur}
                                    className={`fs-block ${styles.input}`}
                                />
                                <button
                                    type="submit"
                                    disabled={isSubmitting}
                                    className={styles.submitButton}
                                />
                                {successMessage && (
                                    <h3 className={styles.thankYou}>
                                        {successMessage}
                                    </h3>
                                )}
                            </form>
                            {touched['email'] && errors['email'] && (
                                <div className={styles.error}>
                                    {errors['email']}
                                </div>
                            )}
                        </React.Fragment>
                    )}
                </Formik>
            </div>
        );
    }
}

EmailCaptureForm.propTypes = {
    placeholder: Proptypes.string,
    required: Proptypes.bool,
    errorMessages: Proptypes.string,
    className: Proptypes.string,
    successMessage: Proptypes.string
};

EmailCaptureForm.defaultProps = {
    placeholder: ''
};

export const data = graphql`
    fragment EmailField on ContentfulFieldText {
        id
        fieldType
        label
        placeholder
        errorMessages {
            id
            name
            condition
            errorMessage {
                errorMessage
            }
        }
        requiredField
    }
`;

export default EmailCaptureForm;
