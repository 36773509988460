import React from 'react';

import styles from './oneTrustCookies.module.scss';

import MetaData from '../../components/Layout/MetaData';
import NavBar from '../../components/NavBar/NavBar';
import Footer from '../../components/Footer/Footer';

const OneTrustCookies = () => {
    return (
        <div>
            <div className={styles.StepZeroPage}>
                <MetaData
                    metaData={{
                        titleTag:
                            'iwoca -  Online-Portal für Partner - Anmeldung ',
                        allowSearchEngineScraping: false,
                        url: '/partner/signup'
                    }}
                />
                <NavBar />
                <section className={styles.container}>
                    <div className={styles.title}>
                        {/* <!-- OneTrust Cookies List start --> */}
                        <div id="ot-sdk-cookie-policy"></div>
                        {/* <!-- OneTrust Cookies List end --> */}
                    </div>
                </section>
            </div>
            <Footer />
        </div>
    );
};

export default OneTrustCookies;
