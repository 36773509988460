import React, { useContext } from 'react';

import Button from '../../common/Button';

import styles from './FooterContact.module.scss';

import IwContext from 'aphrodite-shared/IwContext/IwContext';

import FacebookIcon from '../svg/FacebookIcon';
import LinkedInIcon from '../svg/LinkedInIcon';
import InstagramIcon from '../svg/InstagramIcon';
import TelIconSVG from '../../../SVGComponents/TelIconSVG';
import EmailIcon from '../svg/EmailIcon';

import { useIntl } from 'react-intl';
import messages from '../Footer.messages';
import { formatPhoneNumber } from '../../../util/localization';

const FooterContact = ({
    ctaButton,
    phoneNumber,
    phoneNumberLink,
    email,
    hidePhoneNumber,
    hideCta
}) => {
    const context = useContext(IwContext);
    const { formatMessage } = useIntl();

    function handleOpenStepZero() {
        context.openStepZero();
    }

    const ctaButtonProps = {
        colour: 'primary',
        dataGaId: 'footer__cta'
    };

    const ctaButtonText =
        (ctaButton && ctaButton.text) ||
        formatMessage({
            ...messages.apply
        });

    return (
        <section className={styles.background}>
            <div className={styles.FooterContact}>
                <div className={styles.social}>
                    <FacebookIcon />
                    <LinkedInIcon />
                    <InstagramIcon />
                </div>
                {!hidePhoneNumber && (
                    <div className={styles.tel}>
                        <TelIconSVG />
                        <a
                            href={
                                phoneNumberLink ||
                                formatPhoneNumber(
                                    formatMessage({
                                        ...messages.number
                                    })
                                )
                            }
                            dataGaId="footer__phone">
                            {phoneNumber ||
                                formatMessage({
                                    ...messages.number
                                })}
                        </a>
                    </div>
                )}
                <div className={styles.email}>
                    <EmailIcon />
                    <a
                        href={`mailto:${email ||
                            formatMessage({
                                ...messages.email
                            })}`}
                        dataGaId="footer__email">
                        {email ||
                            formatMessage({
                                ...messages.email
                            })}
                    </a>
                </div>
                {!hideCta && (
                    <div className={styles.apply}>
                        {ctaButton && ctaButton.href ? (
                            <Button href={ctaButton.href} {...ctaButtonProps}>
                                {ctaButtonText}
                            </Button>
                        ) : (
                            <Button
                                onClick={handleOpenStepZero}
                                {...ctaButtonProps}>
                                {ctaButtonText}
                            </Button>
                        )}{' '}
                    </div>
                )}{' '}
            </div>{' '}
        </section>
    );
};

export default FooterContact;
