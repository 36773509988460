import React from 'react';
import ReactMarkdown from 'react-markdown';

function HeroBannerSmall({ data, currentPage }) {
    const content = data.content && data.content[0];

    let heroBannerClassName = '';
    let heroBannerContentClassName = '';
    let backgroundImage = '';

    if (data.content) {
        switch (content.__typename) {
            default:
                heroBannerClassName = 'hero-banner-small--image';
                heroBannerContentClassName =
                    'hero-banner-small__content-container--image';
                break;
        }
        backgroundImage = content.image && `url("${content.image.file.url}")`;
    } else {
        heroBannerContentClassName =
            'hero-banner-small__content-container--no-image';
    }

    return (
        <div
            className={`hero-banner-small ${!data.selectTextAlignment &&
                'hero-banner-small--centre-align'}`}>
            <div
                className={`hero-banner-small__content-container ${heroBannerContentClassName}`}>
                <h1
                    className={`hero-banner-small__title ${
                        data.selectTextColourScheme
                            ? 'hero-banner-small__title--white'
                            : 'hero-banner-small__title--primary'
                    }`}>
                    {data.headline}
                </h1>
                {data.subtitleText && data.subtitleText.subtitle && (
                    <ReactMarkdown
                        className={`hero-banner-small__subtitle ${
                            data.selectTextColourScheme
                                ? 'hero-banner-small__subtitle--white'
                                : 'hero-banner-small__subtitle--primary'
                        }`}
                        source={data.subtitleText.subtitle}
                    />
                )}
                {data.button && (
                    <a
                        href={data.button.url}
                        className="hero-banner-small__cta btn btn--big btn--colored">
                        {data.button.buttonText}
                    </a>
                )}
            </div>
            <div
                className={`hero-banner-small__banner ${heroBannerClassName}`}
                style={{ backgroundImage }}
            />
        </div>
    );
}

export default HeroBannerSmall;
