import React, { Component } from 'react';
import classnames from 'classnames';
import Button from './Button';
import styles from './CTASection.module.scss';

class CTASection extends Component {
    render() {
        const { data, loggedIn } = this.props;

        return (
            <div className={styles.section}>
                <div className={styles.container}>
                    <div className={styles.titleBox}>
                        <h3 className={styles.title}>{data.title}</h3>
                        <p className={styles.subtitle}>{data.subtitle}</p>
                    </div>
                    <div>
                        <Button
                            colour="primary"
                            className={classnames('btn', styles.HeightAuto)}
                            href={
                                loggedIn
                                    ? data.ctaButtonLoggedIn.url
                                    : data.ctaButtonStandard.url
                            }>
                            {loggedIn
                                ? data.ctaButtonLoggedIn.buttonText
                                : data.ctaButtonStandard.buttonText}
                        </Button>
                    </div>
                </div>
            </div>
        );
    }
}

export default CTASection;
