import React from 'react';

import HeroBannerSmall from '../components/common/HeroBannerSmall';
import FeaturedArticle from '../components/common/FeaturedArticle';
import PressContactBox from '../components/Press/PressContactBox';

export default function pressPageComponentParser(typename, data) {
    const { props, sectionData, index } = data;

    switch (typename) {
        case 'ContentfulSectionHeroBannerSmall':
            return (
                <HeroBannerSmall
                    key={index}
                    data={sectionData}
                    currentPage={props.pageData}
                />
            );
        case 'ContentfulSectionFeaturedArticle':
            return <FeaturedArticle key={index} data={sectionData} />;
        case 'ContentfulPressContactBox':
            return <PressContactBox key={index} data={sectionData} />;
        default:
            return null;
    }
}
