import React from 'react';
import ReactMarkdown from 'react-markdown';
import LinkCard from './LinkCard/LinkCard';
import styles from './LinksCards.module.scss';

function LinksCards({ data, jobs }) {
    if (!data) return null;

    return (
        <div className={styles.background}>
            <div className={styles.container}>
                <div className={styles.title}>
                    <h2>{data.title}</h2>
                    {data.subtitleText && (
                        <ReactMarkdown
                            source={data.subtitleText.subtitleText}
                        />
                    )}
                </div>

                <div className={styles.list}>
                    {data.linkCards.map((card) => {
                        const totalNumberOfJobs = jobs.filter(
                            (job) => job.department === card.title
                        ).length;

                        return (
                            <LinkCard
                                key={card.id}
                                card={card}
                                cardCallToActionIcon={data.cardCallToActionIcon}
                                totalNumberOfJobs={totalNumberOfJobs}
                            />
                        );
                    })}
                </div>
            </div>
        </div>
    );
}

export default LinksCards;
