import React, { useState } from 'react';
import Chevron from '../../SVGComponents/Chevron';
import styles from './IwAccordion.module.scss';
import classnames from 'classnames';

function IwAccordion({ className, title, content, children, color }) {
    const [isActive, setActive] = useState(false);

    const onClick = () => {
        setActive(!isActive);
    };

    const panelStyle = isActive ? styles.ActivePanel : '';
    const chevronStyle = isActive ? styles.ActiveChevron : '';

    return (
        <div className={classnames(styles.IwAccordionWrapper, className)}>
            <button
                className={styles.IwAccordion}
                onClick={onClick}
                data-ga-id="wantToKnowMore__question">
                {title}
                <Chevron className={`${styles.Chevron} ${chevronStyle}`} />
            </button>
            <div
                className={`${styles.Panel} ${panelStyle}`}
                data-testid="Panel">
                <div
                    className={styles.Divider}
                    style={{ backgroundColor: color }}></div>
                {children || (
                    <p
                        dangerouslySetInnerHTML={{
                            __html: content
                        }}></p>
                )}
            </div>
        </div>
    );
}

export default IwAccordion;
