import React, { useState, useContext, useEffect } from 'react';
import { graphql } from 'gatsby';
import MetaData from '../../../components/Layout/MetaData.jsx';
import HowItWorks from '../common/HowItWorks/HowItWorks';
import CalculatorSection from '../common/CalculatorSection/CalculatorSection';
import WhyIwoca from '../common/WhyIwoca/WhyIwoca';
import Testimonials from '../common/Testimonials/Testimonials';
import WantToKnowMore from '../common/WantToKnowMore/WantToKnowMore';
import ReadAboutUs from '../common/ReadAboutUs/ReadAboutUs';
import Questions from '../common/Questions/Questions';
import Footer from '../../../components/Footer/Footer';
import { fetchIsLoggedIn } from '../../../util/authHelpers';
import HeroBannerSection from '../../../components/LegacyPage/HeroBannerSection';
import styles from '../iwocaPage.module.scss';

import NavBar from '../../../components/NavBar/NavBar';

const Homepage = ({ data, path }) => {
    const { contentfulPageHomepage: pageData, trustpilot } = data;
    const {
        title,
        metaDescription,
        contactUsSection,
        allowSearchEngineScraping = true,
        includeTitlePrefixSuffix,
        url
    } = pageData;

    const isLoggedIn = fetchIsLoggedIn();

    const financeSchema = setupFinancialServiceSchema(trustpilot);
    const webpageSchema = setupWebpageSchema();

    // Object is in replacement of header data having to come from legacy page in contentful
    const headerBannerData = {
        headline: pageData.heroBusinessOwnerTitle,
        subtitleText: { subtitle: pageData.heroBusinessOwnerSubtitle },
        content: [{ image: pageData.heroBusinessOwnerImage }],
        button: {
            url: '#apply',
            buttonText: pageData.heroBusinessOwnerButton
        }
    };

    return (
        <div className={styles.Page}>
            <MetaData
                metaData={{
                    titleTag: title,
                    metaDescription,
                    allowSearchEngineScraping,
                    includeTitlePrefixSuffix,
                    url,
                    structuredData: [financeSchema, webpageSchema]
                }}
            />

            <NavBar />

            <HeroBannerSection data={headerBannerData} loggedIn={isLoggedIn} />

            <HowItWorks
                title={pageData.howItWorksTitle}
                steps={pageData.howItWorksSteps}
            />
            <CalculatorSection
                calculatorCopy={pageData.calculator}
                title={pageData.calculatorSectionTitle}
                subtitle={pageData.calculatorSectionSubtitle}
                intro={pageData.calculatorSectionIntro}
                contactBubble={pageData.contactBubble}
            />
            <WhyIwoca
                title={pageData.whyIwocaTitle}
                points={pageData.whyIwocaPoints}
                button={pageData.whyIwocaButton}
            />
            <Testimonials
                testimonials={pageData.testimonials}
                heading={pageData.testimonialsHeading}
                paragraph={pageData.testimonialsParagraph.testimonialsParagraph}
            />
            <WantToKnowMore
                faq={pageData.faqSection.faqs}
                title={pageData.faqSection.title}
                subtitle={pageData.faqSection.subtitle.subtitle}
            />
            <ReadAboutUs
                desktopTitle={pageData.readAboutUsDesktopTitle}
                mobileTitle={pageData.readAboutUsMobileTitle}
                trustpilotRating={trustpilot.score.trustScore}
                pressReleaseIcons={pageData.pressReleaseSection.pressPhotos}
                trustpilotText={pageData.pressReleaseSection.trustpilotText}
            />
            <Questions
                title={contactUsSection.title}
                content={contactUsSection.content.content}
                email={pageData.contactUsSection.email}
                phoneNumber={pageData.contactUsSection.phoneNumber}
                showApplyButton={pageData.contactUsSection.showApplyButton}
                showJobsButton={pageData.contactUsSection.showJobsButton}
                jobCTAText={pageData.jobCtaText}
                jobLink={pageData.jobLink}
                loanCTAText={pageData.loanCtaText}
            />
            <Footer />
        </div>
    );
};

const setupFinancialServiceSchema = (trustpilot) => {
    return {
        '@context': 'https://schema.org',
        '@type': 'FinancialService',
        name: 'iwoca',
        description: 'Award-winning small business finance provider.',
        url: ' https://www.iwoca.de ',
        image: {
            '@type': 'ImageObject',
            url:
                'https://images.ctfassets.net/835lsoe3tdrh/60hozXHqoyXt2BpOMzmF2L/60004d18bd71bc07058f65091d2d69cd/logo-x2-doors.png'
        },
        logo: {
            '@type': 'ImageObject',
            url:
                'https://images.ctfassets.net/835lsoe3tdrh/60hozXHqoyXt2BpOMzmF2L/60004d18bd71bc07058f65091d2d69cd/logo-x2-doors.png'
        },
        address: {
            '@type': 'PostalAddress',
            streetAddress: '247 Tottenham Court Road',
            addressLocality: 'Bloomsbury',
            addressRegion: 'London',
            postalCode: 'W1T 7QX',
            addressCountry: 'UK'
        },
        telephone: '020 3397 3375',
        aggregateRating: {
            '@type': 'AggregateRating',
            ratingValue: trustpilot.score.trustScor,
            bestRating: 5,
            worstRating: 1,
            ratingCount: trustpilot.numberOfReviews.total
        }
    };
};

const setupWebpageSchema = () => {
    return {
        '@context': 'https://schema.org',
        '@graph': [
            {
                '@type': 'Organization',
                '@id': 'https://iwoca.de/#organization',
                name: 'iwoca',
                url: 'https://iwoca.de',
                sameAs: [
                    'https://www.linkedin.com/company/iwoca/',
                    'https://www.facebook.com/iwoca/',
                    'https://twitter.com/iwoca',
                    'https://www.youtube.com/user/iwocauk ',
                    'https://www.instagram.com/iwoca/',
                    'https://en.wikipedia.org/wiki/Iwoca'
                ],
                logo: {
                    '@type': 'ImageObject',
                    '@id': 'https://iwoca.de/#logo',
                    url:
                        ' https://images.ctfassets.net/835lsoe3tdrh/60hozXHqoyXt2BpOMzmF2L/60004d18bd71bc07058f65091d2d69cd/logo-x2-doors.png ',
                    caption: 'iwoca'
                },
                image: { '@id': 'https://iwoca.de/#logo' }
            },
            {
                '@type': 'WebSite',
                '@id': 'https://iwoca.de/#website',
                url: 'https://iwoca.de/',
                name: 'iwoca',
                publisher: {
                    '@id': 'https://iwoca.co.uk/#organization'
                }
            },
            {
                '@type': 'WebPage',
                '@id': 'https://iwoca.de/#webpage',
                url: 'https://iwoca.de/',
                inLanguage: 'en-GB',
                name: 'Small business finance solutions & business funding',
                isPartOf: { '@id': 'https://iwoca.de/#website' },
                about: { '@id': 'https://iwoca.de/#organization' },
                description:
                    'Borrow £1,000 - £200,000 for cash flow, stock or investments. Fast and fair decisions.'
            }
        ]
    };
};

export default Homepage;

export const query = graphql`
    query HomepagePageQuery($id: String!) {
        contentfulPageHomepage(id: { eq: $id }) {
            title
            url
            metaDescription
            allowSearchEngineScraping
            includeTitlePrefixSuffix
            navigation {
                ... on Node {
                    ... on ContentfulNavbar {
                        name
                        menuItems {
                            name
                            item {
                                url
                                title
                            }
                        }
                    }
                    ... on ContentfulHeader {
                        ...Header
                    }
                }
            }
            heroBusinessOwnerTitle
            heroBusinessOwnerSubtitle
            heroBusinessOwnerButton
            heroBusinessOwnerSecondaryButton
            heroBusinessOwnerImage {
                fixed(width: 1800, quality: 90) {
                    ...GatsbyContentfulFixed
                }
            }
            barWidgetUspTitle1
            barWidgetUspContent1
            barWidgetUspTitle2
            barWidgetUspContent2
            barWidgetUspTitle3
            barWidgetUspContent3
            barWidgetTrustpilotRating {
                ...trustpilotRating
            }
            howItWorksTitle
            howItWorksSteps {
                ...HowItWorksStep
            }
            calculator {
                ...calculator
            }
            contactBubble {
                text
                phoneNumber
            }
            whyIwocaTitle
            whyIwocaPoints {
                ...whyIwoca
            }
            whyIwocaButton
            testimonialsHeading
            testimonialsHeading
            testimonialsParagraph {
                testimonialsParagraph
            }
            testimonials {
                id
                name
                testimony
                companyName
                bubble {
                    fluid(maxWidth: 300, quality: 90) {
                        ...GatsbyContentfulFluid_withWebp_noBase64
                    }
                }
            }
            faqSection {
                ...faqSection
            }
            readAboutUsDesktopTitle
            readAboutUsMobileTitle
            pressReleaseSection {
                ...pressReleaseSection
            }
            contactUsSection {
                ...contactUsSection
            }
        }
        trustpilot {
            ...trustPilot
        }
    }

    fragment trustpilotRating on ContentfulTrustpilotRating {
        trustpilotRating
        numberOfReviews
        copy
    }

    fragment whyIwoca on ContentfulWhyIwoca {
        title
        description
    }

    fragment contactUsSection on ContentfulContactUsQuestions {
        id
        title
        showApplyButton
        showJobsButton
        email
        phoneNumber
        content {
            content
        }
        email
        showApplyButton
        showJobsButton
        phoneNumber
    }
    fragment calculator on ContentfulNewRepaymentCalculator {
        borrowUpToText
        title
        mobileTitle
        totalRepaymentText
        subtitle
        equalRepaymentText
        forText
        helpText
        repaymentText
        footerText {
            footerText
        }
        ctaButton {
            buttonText
        }
        interestText
    }
    fragment pressReleaseSection on ContentfulPressReleaseSection {
        trustpilotText
        pressPhotos {
            name
            photos {
                file {
                    url
                }
            }
        }
        trustpilotRating {
            ...trustpilotRating
        }
    }
    fragment faqSection on ContentfulFaqSection {
        title
        subtitle {
            subtitle
        }
        faqs {
            question
            answer
            color
        }
    }
    fragment trustPilot on trustpilot {
        numberOfReviews {
            total
        }
        score {
            trustScore
        }
    }
`;
