import React, { useContext, useEffect } from 'react';
import ReactModal from 'react-modal';
import { graphql, navigate, useStaticQuery } from 'gatsby';
import { useIntl } from 'react-intl';

import styles from './StepZeroModal.module.scss';

import IwContext from 'aphrodite-shared/IwContext/IwContext';
import StepZeroForm from './StepZeroForm/StepZeroForm';
import TrustPilotLogoInline from '../../components/Press/PressLogos/svg/TrustPilotLogoInline';
import { MODALS } from 'aphrodite-shared/util/iwocaConstants';

import Cross from '../svg/cross.svg';
import Tick from '../svg/tick.svg';
import TelIcon from '../../SVGComponents/TelIconSVG';
import CloseIcon from '../../SVGComponents/CloseIcon';
import { postToDataLayer } from '../../util/googleTracking';

import messages from './StepZeroModal.messages';
import { formatPhoneNumber } from '../../util/localization';

ReactModal.defaultStyles.overlay.zIndex = '20';
ReactModal.defaultStyles.overlay.backgroundColor = 'rgba(0, 0, 0, 0.8)';

const useTrustPilotData = () => {
    const { trustpilot } = useStaticQuery(
        graphql`
            {
                trustpilot {
                    ...trustPilot
                }
            }
        `
    );

    return trustpilot;
};

const StepZeroModal = ({ isOpen, maximumLoanAmount, prefilledOptions }) => {
    const data = useTrustPilotData();
    const context = useContext(IwContext);

    const { formatMessage } = useIntl();

    function handleCloseStepZero() {
        const { pathname, hash } = window.location;
        postToDataLayer({ event: 'closedStep0' });
        context.closeModal(MODALS.signupStepZero);
        if (hash.length > 0) {
            navigate(pathname);
        }
    }

    function handleCloseModal() {
        const { pathname, hash } = window.location;
        context.closeModal();
        if (hash.length > 0) {
            navigate(pathname);
        }
    }

    useEffect(() => {
        checkHash();
        window.addEventListener('hashchange', checkHash, false);
        return () => {
            window.removeEventListener('hashchange', checkHash, false);
        };
    }, []);

    function checkHash() {
        if (window.location.hash === '#apply') {
            context.openStepZero();
        }
    }

    return (
        <div>
            <ReactModal
                onRequestClose={handleCloseModal}
                className={styles.modal}
                isOpen={isOpen}>
                <div className={styles.modalContent}>
                    <div className={styles.header}>
                        <div
                            className={styles.crossContainer}
                            onClick={handleCloseStepZero}>
                            <CloseIcon
                                src={Cross}
                                alt="Close icon"
                                className={styles.cross}
                            />
                        </div>
                        <h1>
                            {formatMessage({
                                ...messages.lets_get_started
                            })}
                        </h1>
                        <h2>
                            {formatMessage({
                                ...messages.wont_affect_credit_score
                            })}
                        </h2>
                    </div>
                    <div className={styles.formContainer}>
                        <StepZeroForm
                            maximumLoanAmount={maximumLoanAmount}
                            minimumLoanAmount={500}
                            prefilledOptions={prefilledOptions}
                        />
                        <div className={styles.callUs}>
                            {formatMessage({
                                ...messages.call_us
                            })}
                            <a
                                href={formatPhoneNumber(
                                    formatMessage({
                                        ...messages.phone_number
                                    })
                                )}>
                                <TelIcon />
                                {formatMessage({
                                    ...messages.phone_number
                                })}
                            </a>
                        </div>
                    </div>
                </div>
                <div className={styles.info}>
                    <h3>
                        {formatMessage({
                            ...messages.whats_next
                        })}
                    </h3>
                    <ul>
                        <li>
                            <img
                                src={Tick}
                                alt="Tick icon"
                                className={styles.tick}
                            />
                            {formatMessage({
                                ...messages.apply_in_five_minutes
                            })}
                        </li>
                        <li>
                            <img
                                src={Tick}
                                alt="Tick icon"
                                className={styles.tick}
                            />
                            {formatMessage({
                                ...messages.account_manager
                            })}
                        </li>
                        <li>
                            <img
                                src={Tick}
                                alt="Tick icon"
                                className={styles.tick}
                            />
                            {formatMessage({
                                ...messages.money_in_the_bank
                            })}
                        </li>
                    </ul>
                    <div className={styles.trustpilotLogoContainer}>
                        <TrustPilotLogoInline />

                        <p>
                            {formatMessage(
                                {
                                    ...messages.trustpilot
                                },
                                {
                                    span: (msg) => <span>{msg}</span>,
                                    reviews: data.numberOfReviews.total
                                }
                            )}{' '}
                            {formatMessage(
                                {
                                    ...messages.trustpilot_out_of
                                },
                                {
                                    span: (msg) => <span>{msg}</span>,
                                    score: data.score.trustScore
                                }
                            )}
                        </p>
                    </div>
                    <div className={styles.callUs}>
                        {formatMessage({
                            ...messages.call_us
                        })}
                        <a
                            href={formatPhoneNumber(
                                formatMessage({
                                    ...messages.phone_number
                                })
                            )}>
                            <TelIcon />
                            {formatMessage({
                                ...messages.phone_number
                            })}
                        </a>
                    </div>
                </div>
            </ReactModal>
        </div>
    );
};

export default StepZeroModal;
