import React from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import styles from './FeaturesAndReviewsSection.module.scss';

class FeaturesAndReviewSection extends React.Component {
    renderTestimonial(testimonial) {
        const {
            id,
            customerName,
            customerImage,
            customerJobAndCompany,
            text
        } = testimonial;
        return (
            <div key={id} className={styles.testimonial}>
                {text && <p>{text.text}</p>}
                {customerImage && customerImage.file && (
                    <div
                        style={{
                            backgroundImage: `url(${customerImage.file.url})`
                        }}
                        className={styles.customerImage}
                        aria-label={customerName}
                        role="img"
                    />
                )}
                <div className={styles.customer}>
                    {customerName && (
                        <h4 className={styles.customerName}>{customerName}</h4>
                    )}
                    {customerJobAndCompany && (
                        <small>{customerJobAndCompany}</small>
                    )}
                </div>
            </div>
        );
    }

    renderFeature(feature) {
        const { id, featureTitle, description } = feature;
        return (
            <div key={id} className={styles.feature}>
                {featureTitle && (
                    <h2 className={styles.featureTitle}>
                        {featureTitle.featureTitle}
                    </h2>
                )}
                {description && (
                    <span className={styles.featureDescription}>
                        {description.description}
                    </span>
                )}
            </div>
        );
    }

    render() {
        const {
            backgroundImage,
            title,
            features,
            testimonials
        } = this.props.data;

        const sliderSettings = {
            autoplaySpeed: 8000,
            autoplay:
                typeof window !== 'undefined'
                    ? !window.DISABLE_ANIMATIONS
                    : true,
            slidesToShow: 1,
            arrows: false
        };

        return (
            <section
                style={
                    backgroundImage && backgroundImage.file
                        ? {
                              backgroundImage: `url(${backgroundImage.file.url})`
                          }
                        : {}
                }
                className={styles.featuresAndReviews}>
                <div className={styles.featuresAndReviewsContainer}>
                    <h2>{title}</h2>

                    {features && features.length > 0 && (
                        <div className={styles.features}>
                            {features.map((feature) =>
                                this.renderFeature(feature)
                            )}
                        </div>
                    )}

                    {testimonials && testimonials.length > 0 && (
                        <React.Fragment>
                            <Slider
                                {...sliderSettings}
                                className={styles.testimonialSlider}>
                                {testimonials.map((testimonial) => (
                                    <div
                                        className={styles.testimonialSlide}
                                        key={testimonial.id}>
                                        {this.renderTestimonial(testimonial)}
                                    </div>
                                ))}
                            </Slider>

                            <div className={styles.testimonials}>
                                {testimonials.map((testimonial) =>
                                    this.renderTestimonial(testimonial)
                                )}
                            </div>
                        </React.Fragment>
                    )}
                </div>
            </section>
        );
    }
}

FeaturesAndReviewSection.propTypes = {
    data: PropTypes.shape({
        backgroundImage: PropTypes.shape({
            file: PropTypes.shape({
                url: PropTypes.string.isRequired
            })
        }),
        title: PropTypes.string.isRequired,
        features: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.string,
                featureTitle: PropTypes.shape({
                    featureTitle: PropTypes.string
                }),
                description: PropTypes.shape({
                    description: PropTypes.string
                })
            })
        ),
        testimonials: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.string,
                customerName: PropTypes.string,
                customerImage: PropTypes.shape({
                    file: PropTypes.shape({
                        url: PropTypes.string.isRequired
                    })
                }),
                customerJobAndCompany: PropTypes.string,
                text: PropTypes.shape({
                    text: PropTypes.string
                })
            })
        )
    })
};

export default FeaturesAndReviewSection;
