import { lendingAPIPostIntroducerState } from 'aphrodite-shared/api/lendingApi';
import * as questions from './IntroducerSignupFormQuestions';

export async function submitIntrodcerSignup(values) {
    try {
        const serialisedSignupValues = serialiseIntroducerStatePost(values);
        await lendingAPIPostIntroducerState(serialisedSignupValues);
    } catch (error) {
        throw error;
    }
}

const serialiseIntroducerStatePost = function(values) {
    return {
        introducer: {
            email: values[questions.COMPANY_EMAIL],
            first_name: values[questions.FIRST_NAME],
            last_name: values[questions.LAST_NAME],
            phone_number: values[questions.PHONE_NUMBER],
            title: values[questions.TITLE]
        },
        introducer_company: {
            address: values[questions.ADDRESS],
            company_name: values[questions.COMPANY_NAME],
            vat_number: values[questions.VAT_NUMBER],
            company_number: values[questions.COMPANY_NUMBER],
            fca_reference: values[questions.FCA_REFERENCE],
            introducer_type: values[questions.INTRODUCER_TYPE],
            post_code: values[questions.POSTCODE],
            website: values[questions.WEBSITE],
            id: values[questions.UUID]
        }
    };
};
