import React, { useEffect, useState } from 'react';
import IwLink from '../../IwLink/IwLink';
import styles from './DropdownMenu.module.scss';
import Button from '../../common/Button';
import TelIconSVG from '../../../SVGComponents/TelIconSVG';
import MenuShapes from './shapes/MenuShapes';
import Chevron from '../../../SVGComponents/Chevron';
import classnames from 'classnames';

import messages from '../NavBar.messages';
import { useIntl } from 'react-intl';
import { getFullIwocaUrl } from 'aphrodite-shared/util/helpers';

const useDropDown = (isOpen) => {
    useEffect(() => {
        if (typeof document !== 'undefined') {
            if (isOpen) {
                // // make the menu the focus when open
                document.body.style.overflow = 'hidden';
                document.documentElement.style.overflow = 'hidden';
            } else {
                document.body.style.overflow = 'auto';
                document.documentElement.style.overflow = 'auto';
            }
        }

        return function cleanup() {
            document.body.style.overflow = 'auto';
            document.documentElement.style.overflow = 'auto';
        };
    });
};

const DropdownMenu = ({
    isOpen,
    menuSections,
    isLandingPage,
    loggedIn,
    primaryCta
}) => {
    const { formatMessage } = useIntl();
    const activeStyle = isOpen ? styles.Active : '';
    const linkGaId = 'mobileMenu__link';

    const primaryBtn = loggedIn
        ? {
              txt: 'My account',
              href: '/my_account/',
              gaID: linkGaId,
              id: 'my_account'
          }
        : {
              txt: 'Apply',
              href: (primaryCta && primaryCta.href) || '#apply',
              gaID: 'mobileMenu__cta',
              id: 'apply'
          };

    useDropDown(isOpen);
    return (
        <section
            className={`${styles.Menu} ${activeStyle}`}
            data-testid="DropdownMenu">
            <MenuShapes isOpen={isOpen}>
                {!isLandingPage && (
                    <ul className={styles.MenuOptions}>
                        {menuSections.map((section) => {
                            if (
                                typeof section.item === 'undefined' ||
                                !section.item ||
                                section.item.length === 0
                            ) {
                                return (
                                    <li className={styles.StandaloneLink}>
                                        <IwLink
                                            to={section.url}
                                            className={styles.Slug}
                                            data-ga-id={
                                                section.dataGaId
                                                    ? section.dataGaId
                                                    : linkGaId
                                            }>
                                            {section.name}
                                        </IwLink>
                                    </li>
                                );
                            }
                            return (
                                <DropdownLink
                                    menuItems={section.item}
                                    sectionTitle={section.name}
                                />
                            );
                        })}
                        <li className={styles.DropdownLink}>
                            <IwLink
                                to={`https://${getFullIwocaUrl('/login/')}`}
                                className={styles.LoginLink}
                                data-ga-id={linkGaId}>
                                Log in
                            </IwLink>
                        </li>
                    </ul>
                )}

                <ul className={styles.ExtraOptions}>
                    <li>
                        <Button
                            href={primaryBtn.href}
                            colour="primary"
                            className={styles.ApplyButton}
                            dataGaId={primaryBtn.gaID}>
                            {formatMessage({
                                ...messages[primaryBtn.id]
                            })}
                        </Button>
                    </li>
                    <li>
                        <section className={styles.Telephone}>
                            <TelIconSVG />
                            <a
                                href={formatMessage({
                                    ...messages.phone_number_link
                                })}
                                data-ga-id="mobileMenu__phone">
                                {formatMessage({
                                    ...messages.phone_number
                                })}
                            </a>
                        </section>
                    </li>
                </ul>
            </MenuShapes>
        </section>
    );
};

const DropdownLink = ({ menuItems, sectionTitle }) => {
    const [isActive, setActive] = useState(false);
    const linkGaId = 'mobileMenu__link';

    const onClick = () => {
        setActive(!isActive);
    };

    const panelStyle = isActive ? styles.DropdownActive : '';
    const chevronStyle = isActive ? styles.ActiveChevron : '';
    return (
        <li className={styles.DropdownLink}>
            <button className={styles.DropdownButton} onClick={onClick}>
                {sectionTitle}
                <Chevron className={`${styles.Chevron} ${chevronStyle}`} />
            </button>
            <div className={classnames(styles.Dropdown, panelStyle)}>
                {menuItems &&
                    menuItems.length > 0 &&
                    menuItems.map((link) => {
                        return (
                            <IwLink
                                to={link.url}
                                className={styles.Slug}
                                data-ga-id={
                                    link.dataGaId ? link.dataGaId : linkGaId
                                }>
                                {link.title}
                            </IwLink>
                        );
                    })}
            </div>
        </li>
    );
};

export default DropdownMenu;
