import { buildLendingAPI } from './lendingApiHelper.js';

export async function postEmailHubSpot(email, hubspotId) {
    return await buildLendingAPI(
        `https://api.hsforms.com/submissions/v3/integration/submit/6334445/${hubspotId}`,
        {
            method: 'POST',
            body: JSON.stringify({
                fields: [
                    {
                        name: 'email',
                        value: email
                    }
                ]
            })
        }
    );
}
