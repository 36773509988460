// Multiple references
import React, { PureComponent } from 'react';
import classnames from 'classnames';
import styles from './ComparisonTableSection.module.scss';

function ComparisonTableSection({ data }) {
    return (
        <div className={styles.comparisonTable}>
            <div className={styles.titleBox}>
                <h2>{data.title}</h2>
                {data.subheading && <p>{data.subheading}</p>}
            </div>
            {data.options && (
                <div className={styles.optionsContainer}>
                    {data.options.map((option) => (
                        <ComparisonOption key={option.id} data={option} />
                    ))}
                </div>
            )}
        </div>
    );
}

class ComparisonOption extends PureComponent {
    constructor() {
        super();
        this.state = {
            fullyVisible: false
        };
        this.toggleFindOutMore = this.toggleFindOutMore.bind(this);
    }

    toggleFindOutMore() {
        this.setState((prevState) => ({
            fullyVisible: !prevState.fullyVisible
        }));
    }

    render() {
        const { data } = this.props;
        const { fullyVisible } = this.state;
        return (
            <div className={styles.comparisonTableOption}>
                <div className={styles.optionIntro}>
                    {data.title && (
                        <h3 className={styles.optionIntroTitle}>
                            {data.title}
                        </h3>
                    )}
                    {data.subheading && (
                        <p className={styles.optionIntroSubTitle}>
                            {data.subheading}
                        </p>
                    )}
                    {data.text && (
                        <p className={styles.optionIntroSubText}>{data.text}</p>
                    )}
                    {data.ctaButton && (
                        <button
                            className={styles.optionIntroCta}
                            href={data.ctaButton.url}
                            title={data.ctaButton.buttonText}
                            type="button">
                            {data.ctaButton.buttonText}
                        </button>
                    )}

                    <span
                        onClick={() => this.toggleFindOutMore()}
                        className={styles.findOutMoreToggle}>
                        {data.findOutMoreText}
                    </span>
                </div>

                {data.bulletpoints && (
                    <div
                        className={`${styles.optionIntroBulletPoints} ${
                            fullyVisible
                                ? `${styles.optionIntroBulletPointsVisible}`
                                : ''
                        }`}>
                        <div className={styles.optionIntroBulletPointContainer}>
                            {data.bulletpointsHeadline && (
                                <p
                                    className={
                                        styles.optionIntroBulletPointHeading
                                    }>
                                    {data.bulletpointsHeadline}
                                </p>
                            )}
                            {data.bulletpoints.map((bulletpoint) => (
                                <Bulletpoint
                                    key={bulletpoint.id}
                                    data={bulletpoint}
                                    className={styles.optionBulletpoint}
                                />
                            ))}
                        </div>
                    </div>
                )}
            </div>
        );
    }
}

function Bulletpoint({ data, className }) {
    const bulletpointClass = classnames(styles.bulletpoint, className);

    return (
        <div className={bulletpointClass}>
            {data.icon && (
                <img
                    className={styles.bulletpointIcon}
                    src={data.icon.file.url}
                    alt={data.text}
                    height="21"
                    width="21"
                />
            )}
            {data.text && (
                <span className={styles.bulletpointText}>{data.text}</span>
            )}
        </div>
    );
}

export default ComparisonTableSection;
