import React from 'react';
import PropTypes from 'prop-types';
import styles from './LinkCollectionSection.module.scss';
import Button from '../common/Button';
import { translate } from '../../util/localization';
import * as variables from '../../variables.json';

class LinkCollectionSection extends React.Component {
    renderBlogArticle(blogArticleData) {
        const {
            id,
            articleTitle,
            slug,
            subtitle,
            articleHeaderImage
        } = blogArticleData;

        const data = {
            id,
            title: articleTitle,
            url:
                variables.contentHubPath[process.env.GATSBY_COUNTRY_BUILD] +
                slug,
            description: subtitle.subtitle,
            ctaText: translate('readArticle'),
            preTitle: 'iwoca insights'
        };

        if (articleHeaderImage && articleHeaderImage.file) {
            data.imageUrl = articleHeaderImage.file.url;
        }

        return this.renderLinkContainer(data);
    }

    renderFeaturedArticle(featuredArticleData) {
        const {
            id,
            articleTopic,
            articleTitle,
            articleUrl,
            articleDescription,
            backgroundImage
        } = featuredArticleData;

        const data = {
            id,
            preTitle: articleTopic,
            title: articleTitle,
            url: articleUrl,
            description: articleDescription,
            ctaText: translate('readArticle')
        };

        if (backgroundImage && backgroundImage.file) {
            data.imageUrl = backgroundImage.file.url;
        }

        return this.renderLinkContainer(data);
    }

    renderLinkCard(linkData) {
        const {
            id,
            callToAction,
            description,
            title,
            url,
            preTitle,
            image
        } = linkData;

        const data = {
            id,
            title,
            preTitle,
            url,
            description: description.description,
            ctaText: callToAction
        };

        if (image && image.file) {
            data.imageUrl = image.file.url;
        }

        return this.renderLinkContainer(data);
    }

    renderLinkContainer(data) {
        return (
            <div className={styles.link} key={data.id}>
                <div className={styles.linkImageContainer}>
                    <div
                        className={styles.linkImage}
                        style={{
                            backgroundImage: `url(${data.imageUrl}?fm=jpg&q=80&w=492)`
                        }}
                        role="img"
                        aria-label={data.title}
                    />
                </div>
                <div className={styles.linkContent}>
                    <div>
                        <span className={styles.preTitle}>{data.preTitle}</span>
                        <h3>{data.title}</h3>
                    </div>
                    <p>{data.description}</p>
                    <Button
                        colour="primary"
                        className={styles.linkButton}
                        href={data.url}>
                        {data.ctaText}
                    </Button>
                </div>
            </div>
        );
    }

    renderLink(link) {
        let renderedLink = null;

        switch (link.__typename) {
            case 'ContentfulBlogArticle':
                renderedLink = this.renderBlogArticle(link);
                break;

            case 'ContentfulLinkCard':
                renderedLink = this.renderLinkCard(link);
                break;

            default:
                break;
        }

        return renderedLink;
    }

    render() {
        if (!this.props.data) {
            console.error(
                'Link Collection Section: No data object provided -> Section not rendered'
            );
            return null;
        }

        const { title, links } = this.props.data;

        return (
            <section className={styles.linkCollection}>
                {title && <h2>{title}</h2>}
                {links && links.map((link) => this.renderLink(link))}
            </section>
        );
    }
}

LinkCollectionSection.propTypes = {
    data: PropTypes.shape({
        title: PropTypes.string,
        links: PropTypes.arrayOf(
            PropTypes.oneOf([
                // Blog Article
                PropTypes.shape({
                    __typename: PropTypes.string,
                    id: PropTypes.string,
                    articleTitle: PropTypes.string,
                    slug: PropTypes.string,
                    subtitle: PropTypes.shape({
                        subtitle: PropTypes.string
                    }),
                    articleHeaderImage: PropTypes.shape({
                        file: PropTypes.shape({
                            url: PropTypes.string
                        })
                    })
                }),
                // Featured Article
                PropTypes.shape({
                    __typename: PropTypes.string,
                    id: PropTypes.string,
                    articleTopic: PropTypes.string,
                    articleTitle: PropTypes.string,
                    articleUrl: PropTypes.string,
                    articleDescription: PropTypes.string,
                    backgroundImage: PropTypes.shape({
                        file: PropTypes.shape({
                            url: PropTypes.string
                        })
                    })
                }),
                // Link Card
                PropTypes.shape({
                    __typename: PropTypes.string,
                    id: PropTypes.string,
                    callToAction: PropTypes.string,
                    description: PropTypes.shape({
                        description: PropTypes.string
                    }),
                    title: PropTypes.string,
                    url: PropTypes.string,
                    preTitle: PropTypes.string,
                    image: PropTypes.shape({
                        file: PropTypes.shape({
                            url: PropTypes.string
                        })
                    })
                })
            ])
        )
    })
};

export default LinkCollectionSection;
