import React, { useState, useEffect, useRef } from 'react';
import IwLink from '../../IwLink/IwLink';
import styles from './InlineMenu.module.scss';
import Chevron from '../../../SVGComponents/Chevron';
import classnames from 'classnames';

const InlineMenu = ({ menuSections }) => {
    return (
        <section className={styles.MenuItems}>
            {menuSections.map((section) => (
                <MenuItem
                    name={section.name}
                    url={section.url}
                    links={section.item}
                />
            ))}
        </section>
    );
};

const MenuItem = ({ name, links, url }) => {
    const gaId = 'navbar__link';
    const node = useRef();

    const [isDropdownOpen, setDropdown] = useState(false);
    const onClick = () => {
        setDropdown(!isDropdownOpen);
    };

    const displayDropdown = isDropdownOpen ? styles.OpenDropwdown : '';

    useEffect(() => {
        const handleOutsideClick = (e) => {
            if (isDropdownOpen && !node.current.contains(e.target)) {
                setDropdown(false);
                return;
            }
        };
        document.addEventListener('mousedown', handleOutsideClick);
        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, [isDropdownOpen]);

    // Render single link, when no sublinks available
    if (!links || typeof links === 'undefined' || links.length === 0) {
        return (
            <IwLink
                className={classnames(styles.DropdownButton, styles.MenuItem)}
                data-ga-id={gaId}
                to={url}>
                {name}
                {isActive([{ url }]) && (
                    <div className={styles.ActiveOption}></div>
                )}
            </IwLink>
        );
    }
    return (
        <div className={styles.MenuItem}>
            <button
                onClick={onClick}
                className={styles.DropdownButton}
                data-ga-id={gaId}>
                {name}
                <Chevron className={styles.Chevron} />
                {isActive(links) && <div className={styles.ActiveOption}></div>}
            </button>
            <div
                className={classnames(styles.DropdownWrapper, displayDropdown)}
                ref={node}>
                <div className={styles.DropdownContent}>
                    <div className={styles.Arrow}></div>
                    {links.map((link) => {
                        return (
                            <div className={styles.DropdownLink}>
                                <IwLink
                                    to={link.url}
                                    data-ga-id={
                                        link.dataGaId ? link.dataGaId : gaId
                                    }>
                                    {link.title}
                                </IwLink>
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

const isActive = (links) => {
    let isActive = false;

    links.forEach((link) => {
        if (
            typeof window !== 'undefined' &&
            link.url === window.location.pathname
        ) {
            isActive = true;
        }
    });

    return isActive;
};

export default InlineMenu;
