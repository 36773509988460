import React from 'react';
import ReactMarkdown from 'react-markdown';
import PropTypes, { shape } from 'prop-types';
import SVG from 'react-inlinesvg';
import styles from './LinkCard.module.scss';

class LinkCard extends React.Component {
    render() {
        const { card, cardCallToActionIcon, totalNumberOfJobs } = this.props;

        return (
            <div key={card.id} className={styles.container}>
                <a href={card.url} className={styles.link}>
                    <div className={styles.careerCard}>
                        <div className={styles.header}>
                            <h3 className={styles.title}>{card.title}</h3>
                            {card.description && (
                                <ReactMarkdown
                                    className={styles.description}
                                    source={card.description.description}
                                />
                            )}
                        </div>

                        <div className={styles.footer}>
                            <div className={styles.more}>
                                <SVG
                                    className={styles.moreArrow}
                                    src={cardCallToActionIcon.file.url}
                                />
                                {`${totalNumberOfJobs} ${card.callToAction}`}
                            </div>
                        </div>
                    </div>
                </a>
            </div>
        );
    }
}

LinkCard.propTypes = {
    card: shape({
        id: PropTypes.string,
        url: PropTypes.string,
        title: PropTypes.string,
        callToAction: PropTypes.string,
        description: shape({
            description: PropTypes.string
        }),
        cardCallToActionIcon: shape({
            file: shape({
                url: PropTypes.string
            })
        }),
        totalNumberOfJobs: PropTypes.number
    })
};

export default LinkCard;
