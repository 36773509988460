import React from 'react';
import ReactMarkdown from 'react-markdown';
import PropTypes from 'prop-types';
import CreditLineCalculator from './LoanCalculator/CreditLineCalculator';
import styles from './RepaymentCalculatorSection.module.scss';
import SectionContainer from './SectionContainer';

class RepaymentCalculatorSection extends React.Component {
    renderTitle(title) {
        return title && <h3 className={styles.title}>{title}</h3>;
    }

    renderSubtitle(subtitle) {
        return subtitle && <p className={styles.subtitle}>{subtitle}</p>;
    }

    renderFooterText(footerText) {
        return (
            footerText &&
            footerText.footerText && (
                <p className={styles.footerText}>{footerText.footerText}</p>
            )
        );
    }

    renderBorrowText(legacyBorrowText) {
        return (
            legacyBorrowText &&
            legacyBorrowText.legacyBorrowText && (
                <div className={styles.borrowText}>
                    <ReactMarkdown
                        source={legacyBorrowText.legacyBorrowText}
                        escapeHtml={false}
                    />
                </div>
            )
        );
    }

    renderLegacyText(legacyEuText, legacyEuLogo, legacyEuLogo2) {
        return (
            legacyEuText &&
            legacyEuText.legacyEuText && (
                <div className={styles.legacyContainer}>
                    <figure className={styles.euLogoContainer}>
                        {legacyEuLogo && (
                            <img
                                className={styles.legacyImage}
                                src={legacyEuLogo.file.url}
                                alt="EU logo"
                            />
                        )}
                        {legacyEuLogo2 && (
                            <img
                                className={styles.legacyImage2}
                                src={legacyEuLogo2.file.url}
                                alt="logo"
                            />
                        )}
                    </figure>
                    <small className={styles.legacyContainerText}>
                        <ReactMarkdown
                            source={legacyEuText.legacyEuText}
                            escapeHtml={false}
                        />
                    </small>
                </div>
            )
        );
    }

    render() {
        if (!this.props.data) {
            console.error(
                'RepaymentCalculatorSection: No data found. Please provide an data object'
            );
            return null;
        }

        const {
            title,
            subtitle,
            footerText,
            legacyEuLogo,
            legacyEuLogo2,
            legacyEuText,
            legacyBorrowText,
            calculators,
            quickJumpLink = {}
        } = this.props.data;

        const calculator =
            calculators &&
            calculators.map((calculator) =>
                calculator.type === 'standard' ? (
                    <CreditLineCalculator
                        data={calculator}
                        key={calculator.id}
                        globalData={this.props.data}
                    />
                ) : null
            );

        return (
            <SectionContainer
                className={styles.section}
                quickJumpHash={quickJumpLink.hash}
                innerClassName={styles.innerContainer}>
                {this.renderTitle(title)}
                {this.renderSubtitle(subtitle)}
                {calculator}
                {this.renderFooterText(footerText)}
                {this.renderBorrowText(legacyBorrowText)}
                {this.renderLegacyText(
                    legacyEuText,
                    legacyEuLogo,
                    legacyEuLogo2
                )}
            </SectionContainer>
        );
    }
}

RepaymentCalculatorSection.propTypes = {
    title: PropTypes.string,
    subtitle: PropTypes.string,
    footerText: PropTypes.shape({
        footerText: PropTypes.string
    }),
    legacyEuLogo: PropTypes.shape({
        file: PropTypes.shape({
            url: PropTypes.string
        })
    }),
    legacyEuLogo2: PropTypes.shape({
        file: PropTypes.shape({
            url: PropTypes.string
        })
    }),
    legacyEuText: PropTypes.shape({
        footerText: PropTypes.string
    }),
    legacyBorrowText: PropTypes.shape({
        footerText: PropTypes.string
    }),
    calculators: PropTypes.arrayOf(
        PropTypes.shape({
            ctaButton: PropTypes.shape({}),
            id: PropTypes.string,
            fees: PropTypes.string,
            feesPercentage: PropTypes.number,
            maxTime: PropTypes.number,
            minAmount: PropTypes.number,
            minTime: PropTypes.number,
            monthlyRepaymentText: PropTypes.string,
            monthlyRepaymentTextPlural: PropTypes.string,
            showInterestRateSlider: PropTypes.bool,
            subtitle: PropTypes.string,
            title: PropTypes.string
        })
    )
};

export default RepaymentCalculatorSection;
