export default {
    colors: {
        coral: '#fb534a',
        snow: '#F2F2F2',
        mercury: '#E5E5E5',
        swamp: '#1B2D37',
        denim: '#102F56',
        denim10: '#08172B',
        denim45: '#4D809E',
        denim65: '#699FE3',
        london: '#58A1FA',
        london75: '#ADC8D7',
        london95: '#BFD4E0'
    }
};
