import React from 'react';
import format from 'date-fns/format';
import en from 'date-fns/locale/en';
import de from 'date-fns/locale/de';
import TimeIcon from './TimeIcon';
import messages from '../Article.messages';
import classnames from 'classnames';
import { useIntl } from 'react-intl';
import styles from './AboveTheFold.module.scss';
import Spacer from '../ContentBlock/Spacer/Spacer';

const locales = {
    de,
    en
};

const AboveTheFold = ({ title, subtitle, date, categoryTag, content }) => {
    const { formatMessage } = useIntl();

    function formattedDate() {
        let [year, month, day] = date.split('-');
        return format(new Date(year, month - 1, Number(day)), 'D MMMM YYYY', {
            locale: locales[process.env.GATSBY_COUNTRY_BUILD]
        });
    }

    const readTime = calculateReadingTime(content);

    return (
        <div
            className={classnames(
                styles.AboveTheFold,
                styles[`Category${categoryTag}`]
            )}>
            <div className={styles.contentWrapper}>
                <div className={styles.ArticleInfo}>
                    <span className={styles.LeftPane}>
                        <span className={styles.CategoryTag}>
                            {categoryTag}
                        </span>
                        <span className={styles.readingTime}>
                            <TimeIcon className={styles.TimeIcon} />
                            <p>
                                {formatMessage(
                                    { ...messages.readTime },
                                    { value: readTime }
                                )}
                            </p>
                        </span>
                    </span>
                    {date && (
                        <span className={styles.formattedDesktopDate}>
                            {formattedDate()}
                        </span>
                    )}
                </div>
                <h1>{title}</h1>
                <div className={styles.Divider}></div>
                <p className={styles.Subtitle}>{subtitle}</p>
                {date && (
                    <span className={styles.formattedMobileDate}>
                        {formattedDate()}
                    </span>
                )}
            </div>
            <Spacer />
        </div>
    );
};

const calculateReadingTime = (content) => {
    let allText = '';
    content.map((data) => {
        allText += data.blogContent && data.blogContent.blogContent;
    });
    const words = allText.split(' ');
    return Math.round(words.length / 240); // 265WPM is average reading speed of an adult
};

export default AboveTheFold;
