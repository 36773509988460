import React, { useState, useContext } from 'react';
import GatsbyImage from 'gatsby-image';
import messages from './NavBar.messages';
import { useIntl } from 'react-intl';
import { useStaticQuery, graphql } from 'gatsby';
import lodashDebounce from 'lodash/debounce';
import styles from './NavBar.module.scss';
import IwLink from '../IwLink/IwLink';
import IwocaLogoSVG from '../../SVGComponents/IwocaLogoSVG';
import Button from '../common/Button';
import TelIconSVG from '../../SVGComponents/TelIconSVG';
import DropdownMenu from './DropdownMenu/DropdownMenu';
import InlineMenu from './InlineMenu/InlineMenu';
import HamburgerMenu from './DropdownMenu/HamburgerMenu/HamburgerMenu';
import IwContext from 'aphrodite-shared/IwContext/IwContext';
import LockIcon from '../../SVGComponents/LockIcon';
import SubNav from './SubNav/SubNav';
import classnames from 'classnames';
import { fetchIsLoggedIn } from '../../util/authHelpers';
import { getFullIwocaUrl } from 'aphrodite-shared/util/helpers';
import ErrorBar from 'aphrodite-shared/components/ErrorBar/ErrorBar';
import IwModals from '../../IwModals/IwModals';

let hasScrolled = false;

const useIsScroll = () => {
    const [navBar, setNavBar] = useState(true);

    if (typeof window !== 'undefined') {
        let prevScrollpos = window.pageYOffset;
        window.onscroll = lodashDebounce(() => {
            let currentScrollPos = window.pageYOffset;
            if (prevScrollpos > currentScrollPos || currentScrollPos < 50) {
                setNavBar(true);
            } else {
                setNavBar(false);
            }
            prevScrollpos = currentScrollPos;
        }, 1);
    }

    return navBar;
};

function useNavbarData() {
    const { contentfulNavbar } = useStaticQuery(
        graphql`
            {
                contentfulNavbar {
                    name
                    menuItems {
                        name
                        url
                        item {
                            url
                            title
                            dataGaId
                        }
                    }
                }
            }
        `
    );

    return contentfulNavbar;
}

const NavBar = ({
    loggedIn = fetchIsLoggedIn(),
    className,
    containerClassName,
    isLandingPage = false,
    partnershipLogo,
    ctaButton,
    subNavData,
    hidePhoneNumber = false,
    hideLoginLink = false,
    hideSignupLink = false,
    phoneNumber: phoneNumberTextOverride,
    phoneNumberLink: phoneNumberLinkOverride,
    primaryCtaLink,
    loginButton,
    maximumLoanAmount
}) => {
    const [isOpen, setOpen] = useState(false);
    const { formatMessage } = useIntl();

    const onClick = () => setOpen(!isOpen);

    const globalContext = useContext(IwContext);

    const primaryBtn = loggedIn
        ? {
              txt: 'My account',
              href: '/my_account/',
              gaID: 'navbar__account',
              id: 'my_account'
          }
        : { txt: 'Apply', href: '#apply', gaID: 'navbar__cta', id: 'apply' };

    function handleOpenStepZero() {
        if (loggedIn) {
            window.location.href = '/my_account/';
        } else {
            window.location.href = '#apply';
        }
    }

    const isScroll = useIsScroll();

    const navBarActivity = isScroll ? '' : styles.InactiveNavBar;
    const primaryCtaButtonProps = {
        colour: 'primary',
        className: classnames(
            styles.ApplyButton,
            isLandingPage && styles.landingApplyButton,
            hideLoginLink && hidePhoneNumber && styles.standaloneCta
        ),
        dataGaId: primaryBtn.gaID
    };

    const data = useNavbarData();

    // Handling phone number translation in advance and ensureing phoneNumber object is always available
    const phoneNumber = {
        link:
            phoneNumberLinkOverride ||
            formatMessage({
                ...messages.phone_number_link
            }),

        text:
            phoneNumberTextOverride ||
            formatMessage({
                ...messages.phone_number
            })
    };

    return (
        <>
            <div style={{ position: 'relative', overflowX: 'hidden' }}>
                <IwModals maximumLoanAmount={maximumLoanAmount} />
                <div
                    className={`${styles.NavBarContainer} ${navBarActivity} ${containerClassName}`}>
                    <nav
                        className={`${styles.NavBar} ${className} ${
                            isLandingPage && !hasScrolled
                                ? styles.landingPageNav
                                : ''
                        }`}>
                        <div className={styles.paneContainer}>
                            <section className={styles.LeftPane}>
                                <IwLink to="/" className={styles.logoLink}>
                                    <IwocaLogoSVG className={styles.logo} />
                                </IwLink>
                                {partnershipLogo && (
                                    <GatsbyImage
                                        className={styles.PartnershipLogo}
                                        fluid={partnershipLogo.fluid}
                                        imgStyle={{ objectFit: 'scale-down' }}
                                    />
                                )}
                                {!isLandingPage && (
                                    <InlineMenu menuSections={data.menuItems} />
                                )}
                            </section>
                            <section className={styles.Actions}>
                                <span
                                    className={classnames(
                                        styles.ActionsWrapper,
                                        {
                                            [styles.landingActionsWrapper]:
                                                isLandingPage &&
                                                hideLoginLink &&
                                                hideSignupLink,
                                            [styles.singleLogin]:
                                                !hideLoginLink && hideSignupLink
                                        }
                                    )}>
                                    {!loggedIn && !hideLoginLink && (
                                        <IwLink
                                            to={`https://${getFullIwocaUrl(
                                                (loginButton &&
                                                    loginButton.href) ||
                                                    '/login/'
                                            )}`}
                                            className={styles.Login}
                                            data-ga-id="navbar__link">
                                            <LockIcon
                                                className={styles.LockIcon}
                                            />
                                            {formatMessage(messages.login)}
                                        </IwLink>
                                    )}
                                    {(!hideSignupLink ||
                                        (hideSignupLink && hasScrolled)) &&
                                        (ctaButton ? (
                                            <Button
                                                href={ctaButton.href}
                                                {...primaryCtaButtonProps}>
                                                {ctaButton.text ||
                                                    formatMessage({
                                                        ...messages[
                                                            primaryBtn.id
                                                        ]
                                                    })}
                                            </Button>
                                        ) : (
                                            <Button
                                                onClick={handleOpenStepZero}
                                                {...primaryCtaButtonProps}>
                                                {formatMessage({
                                                    ...messages[primaryBtn.id]
                                                })}
                                            </Button>
                                        ))}
                                    <HamburgerMenu
                                        isOpen={isOpen}
                                        onClick={onClick}
                                    />
                                    {!hidePhoneNumber && (
                                        <>
                                            {typeof window !== 'undefined' &&
                                                (window.location.pathname ===
                                                    '/fyrst' ||
                                                    window.location.pathname ===
                                                        '/fyrst/') && (
                                                    <section
                                                        className={
                                                            styles.Telephone
                                                        }>
                                                        <a
                                                            href="support@iwoca.de"
                                                            data-ga-id="navbar__phone">
                                                            support@iwoca.de
                                                        </a>
                                                    </section>
                                                )}
                                            <section
                                                className={styles.Telephone}>
                                                <TelIconSVG />
                                                <a
                                                    href={phoneNumber.link}
                                                    data-ga-id="navbar__phone">
                                                    {phoneNumber.text}
                                                </a>
                                            </section>
                                        </>
                                    )}
                                </span>
                            </section>
                        </div>
                        {subNavData && subNavData.length > 0 && (
                            <SubNav subNavData={subNavData} />
                        )}
                    </nav>
                    <DropdownMenu
                        isOpen={isOpen}
                        menuSections={data.menuItems}
                        isLandingPage={isLandingPage}
                        isLoggedIn={loggedIn}
                        phoneNumber={phoneNumber}
                        primaryCta={{
                            href: primaryCtaLink
                        }}
                    />
                    {globalContext.apiErrorMessage && (
                        <ErrorBar message={globalContext.apiErrorMessage} />
                    )}
                </div>
            </div>
        </>
    );
};

export default NavBar;
