import React from 'react';
import GatsbyImg from 'gatsby-image';
import styles from './TheTeam.module.scss';
import ReactMarkdown from 'react-markdown';
import { BubbleArch } from '../../../../SVGComponents/shapes';
import Button from '../../../../components/common/Button';

const TheTeam = ({
    heading,
    copy,
    ctaText,
    image1,
    image2,
    image3,
    jobLink
}) => {
    return (
        <div className={styles.TheTeam}>
            <div className={styles.LeftPane}>
                <GatsbyImg
                    fluid={image1.fluid}
                    className={styles.mobileImage1}
                />
                <div className={styles.ContentWrapper}>
                    <h2>{heading}</h2>
                    <div className={styles.Divider}></div>
                    <ReactMarkdown source={copy} />
                    <BubbleArch
                        className={styles.BubbleArch}
                        primaryColor="london75"
                    />
                    <Button
                        href={jobLink}
                        colour="primary"
                        dataGaId="aboutus__careers"
                        className={styles.CTA}>
                        {ctaText}
                    </Button>
                </div>
                <GatsbyImg
                    fluid={image3.fluid}
                    className={styles.mobileImage3}
                />
            </div>
            <div className={styles.RightPane}>
                <GatsbyImg fluid={image1.fluid} className={styles.image1} />
                <GatsbyImg fluid={image2.fluid} className={styles.image2} />
                <GatsbyImg fluid={image3.fluid} className={styles.image3} />
            </div>
        </div>
    );
};

export default TheTeam;
