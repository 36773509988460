import React from 'react';
import { graphql } from 'gatsby';
import CategoryPageStrapline from '../../common/Strapline';
import styles from './CategoryPageHeader.module.scss';
import FeaturedArticles from '../../common/FeaturedArticles/FeaturedArticles';

class CategoryPageHeader extends React.Component {
    render() {
        const { category, featuredArticles } = this.props;

        return (
            <React.Fragment>
                <header className={styles.header}>
                    <CategoryPageStrapline category={category} />
                    {featuredArticles && (
                        <FeaturedArticles
                            featuredArticles={featuredArticles.slice(0, 1)}
                        />
                    )}
                </header>
                {featuredArticles && featuredArticles.length > 1 && (
                    <FeaturedArticles
                        featuredArticles={featuredArticles.slice(1, 4)}
                        alternativeLayout
                    />
                )}
            </React.Fragment>
        );
    }
}

export default CategoryPageHeader;

export const data = graphql`
    fragment CatergoryPageHeader on ContentfulBlogSubPage {
        title
        category {
            name
            categoryTag
            categoryDescription {
                id
                categoryDescription
            }
        }
        featuredArticles {
            id
            slug
            articleTitle
            subtitle {
                subtitle
            }
            content {
                ... on ContentfulBlogContentBlock {
                    blogContent {
                        blogContent
                    }
                }
            }
            articleHeaderImage {
                file {
                    url
                }
                fluid(maxWidth: 600, quality: 80) {
                    ...GatsbyContentfulFluid_withWebp_noBase64
                }
                title
            }
        }
    }
`;
