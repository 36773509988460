import React, { useContext } from 'react';
import { LongArch } from '../../../../SVGComponents/shapes';
import styles from './WhyIwoca.module.scss';

import IwContext from 'aphrodite-shared/IwContext/IwContext';
import Button from '../../../../components/common/Button';

const WhyIwoca = ({ title, points, button, buttonCtaLink }) => {
    const context = useContext(IwContext);

    function handleOpenStepZero() {
        context.openStepZero();
    }

    const ctaButtonProps = {
        colour: 'primary',
        dataGaId: 'whyIwoca__cta'
    };

    return (
        <section className={styles.background}>
            <div className={styles.WhyIwoca}>
                <LongArch rotate="right" className={styles.LongArch} />
                <h2>{title}</h2>
                <hr />
                <div className={styles.whyIwocaContainer}>
                    {points &&
                        points.map((point, i) => (
                            <div className={styles.whyIwocaList} key={i}>
                                <h3>{point.title}</h3>
                                <p>{point.description}</p>
                            </div>
                        ))}
                </div>
                {button &&
                    (buttonCtaLink ? (
                        <Button href={buttonCtaLink} {...ctaButtonProps}>
                            {button}
                        </Button>
                    ) : (
                        <Button
                            onClick={handleOpenStepZero}
                            {...ctaButtonProps}>
                            {button}
                        </Button>
                    ))}
            </div>
        </section>
    );
};

export default WhyIwoca;
