import React from 'react';
import classnames from 'classnames';
import styles from './PartnersCTABox.module.scss';

import Button from '../../../../components/common/Button';
import DownloadSVG from '../../../../SVGComponents/DownloadSVG';

const PartnersCTABox = ({
    firstPDFLabel,
    firstPDF,
    secondPDFLabel,
    secondPDF,
    thirdPDFLabel,
    thirdPDF,
    partnerSignupLabel,
    partnerSignupLink
}) => {
    return (
        <React.Fragment>
            <div className={styles.PartnersCTABox}>
                <div className={styles.box}>
                    {firstPDF && (
                        <Button
                            href={firstPDF.file.url}
                            className={styles.button}
                            colour="link"
                            icon={<DownloadSVG />}
                            newTab>
                            {firstPDFLabel}
                        </Button>
                    )}
                    {secondPDF && (
                        <Button
                            href={secondPDF.file.url}
                            className={styles.button}
                            colour="link"
                            icon={<DownloadSVG />}
                            newTab>
                            {secondPDFLabel}
                        </Button>
                    )}
                    {thirdPDF && (
                        <Button
                            href={thirdPDF.file.url}
                            className={styles.button}
                            colour="link"
                            icon={<DownloadSVG />}
                            newTab>
                            {thirdPDFLabel}
                        </Button>
                    )}
                    <Button
                        className={classnames(
                            styles.button,
                            styles.signupButon
                        )}
                        colour="secondary"
                        href={partnerSignupLink}
                        newTab>
                        {partnerSignupLabel}
                    </Button>
                </div>
            </div>
            <div className={styles.PartnersCTABoxMobile}>
                <p>Laden Sie die Formulare herunter, um loszulegen.</p>
                <Button
                    href={firstPDF.file.url}
                    colour="secondary"
                    icon={<DownloadSVG />}
                    className={styles.mobileButton}
                    newTab>
                    {firstPDFLabel}
                </Button>
                {secondPDF && (
                    <Button
                        href={secondPDF.file.url}
                        colour="secondary"
                        icon={<DownloadSVG />}
                        className={styles.mobileButton}
                        newTab>
                        {secondPDFLabel}
                    </Button>
                )}
                {thirdPDF && (
                    <Button
                        href={thirdPDF.file.url}
                        className={styles.mobileButton}
                        colour="secondary"
                        icon={<DownloadSVG />}
                        newTab>
                        {thirdPDFLabel}
                    </Button>
                )}
            </div>
        </React.Fragment>
    );
};

export default PartnersCTABox;
