import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import TrustPilotName from '../../../../../SVGComponents/TrustPilotName';
import TrustPilotStars from '../../../../../SVGComponents/TrustPilotStars';

import styles from './TrustPilotInlineRating.module.scss';

const useTrustPilotData = () => {
    const { trustpilot } = useStaticQuery(
        graphql`
            {
                trustpilot {
                    ...trustPilot
                }
            }
        `
    );

    return trustpilot;
};

const TrustPilotInlineRating = ({ nameWidth, starsWidth }) => {
    const data = useTrustPilotData();
    return (
        <div className={styles.TrustPilotInlineRating}>
            <a
                href="https://de.trustpilot.com/review/www.iwoca.de"
                target="_blank"
                rel="noopener noreferrer">
                <TrustPilotName width={nameWidth} />
                <div className={styles.stars}>
                    <TrustPilotStars width={starsWidth} />
                    <p>
                        <strong>{data.score.trustScore}</strong> von 5 Punkten
                    </p>
                </div>
            </a>
        </div>
    );
};

export default TrustPilotInlineRating;
