import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../../components/Layout/Layout';
import ContentHubHomePage from '../../components/ContentHub/ContentHubHomePage/ContentHubHomePage';
import '../../scss/legacy/globalStyles/globalStyles.scss';
import lodashGet from 'lodash/get';

const careersHubCategories = [
    'Engineering and Data Science',
    'Product and Design',
    'People of iwoca'
];
class ContentHubHomePageWrapper extends React.Component {
    constructor(props) {
        super(props);
        const {
            contentfulBlogHomepage,
            contentfulCareersHomepage,
            allContentfulBlogArticle
        } = this.props.data;

        const isCareersBlog =
            !contentfulBlogHomepage && !!contentfulCareersHomepage;

        this.allBlogArticles = !isCareersBlog
            ? this.extractContentHubBlogArticles(allContentfulBlogArticle)
            : this.extractCareersBlogArticles(allContentfulBlogArticle);
    }

    extractCareersBlogArticles(blogArticles) {
        return {
            edges: blogArticles.edges.filter((blogArticle) =>
                this.doesArticleHaveCareersHubCategory(blogArticle)
            )
        };
    }

    extractContentHubBlogArticles(blogArticles) {
        return {
            edges: blogArticles.edges.filter(
                (blogArticle) =>
                    !this.doesArticleHaveCareersHubCategory(blogArticle)
            )
        };
    }

    doesArticleHaveCareersHubCategory(blogArticle) {
        return careersHubCategories.includes(
            lodashGet(blogArticle, 'node.categoryTag.categoryTag')
        );
    }

    render() {
        const { pageContext } = this.props;
        const {
            contentfulBlogHomepage,
            contentfulCareersHomepage,
            contentfulHreflangManagement
        } = this.props.data;

        const contentPageData =
            contentfulBlogHomepage || contentfulCareersHomepage;

        const {
            titleTag,
            metaDescription,
            allowSearchEngineScraping,
            slug
        } = contentPageData;

        const fullSlug = pageContext.slug || slug;

        return (
            <Layout
                metaData={{
                    titleTag,
                    metaDescription,
                    allowSearchEngineScraping,
                    slug: fullSlug,
                    contentfulHreflangManagement
                }}>
                <ContentHubHomePage
                    contentfulBlogHomepage={contentPageData}
                    allContentfulBlogArticle={this.allBlogArticles}
                />
            </Layout>
        );
    }
}

export default ContentHubHomePageWrapper;

export const query = graphql`
    query ContentHubHomePageQuery($id: String!) {
        contentfulBlogHomepage(id: { eq: $id }) {
            id
            slug
            name
            titleTag
            metaDescription
            allowSearchEngineScraping
            pageTitle
            subHeading
            featuredArticles {
                id
                name
                slug
                articleTitle
                categoryTag {
                    categoryTag
                }
                articleHeaderImage {
                    file {
                        url
                    }
                    fluid(maxWidth: 600, quality: 80) {
                        ...GatsbyContentfulFluid_withWebp_noBase64
                    }
                }
                subtitle {
                    subtitle
                }
                ...ArticleContent
            }
            ...ContentHubHomePageEmailCapture
            header {
                ...Header
            }
            modal {
                ...Form
            }
        }
        contentfulCareersHomepage {
            id
            slug
            name
            titleTag
            metaDescription
            allowSearchEngineScraping
            pageTitle
            subHeading
            featuredArticles {
                id
                name
                slug
                articleTitle
                categoryTag {
                    categoryTag
                }
                articleHeaderImage {
                    file {
                        url
                    }
                    fluid(maxWidth: 600, quality: 80) {
                        ...GatsbyContentfulFluid_withWebp_noBase64
                    }
                }
                subtitle {
                    subtitle
                }
                ...ArticleContent
            }
            ...CareersContentHubHomePageEmailCapture
            header {
                ...Header
            }
            modal {
                ...Form
            }
        }
        allContentfulBlogArticle {
            edges {
                node {
                    id
                    name
                    slug
                    articleTitle
                    date
                    categoryTag {
                        categoryTag
                    }
                    articleHeaderImage {
                        file {
                            url
                        }
                        fluid(maxWidth: 600, quality: 80) {
                            ...GatsbyContentfulFluid_withWebp_noBase64
                        }
                    }
                    subtitle {
                        subtitle
                    }
                    ...ArticleContent
                    categoryTag {
                        categoryTag
                        categoryLink
                        blog_sub_page_ {
                            id
                            slug
                        }
                    }
                    excludeFromHomepage
                }
            }
        }
        contentfulGlobalVariables {
            ...GlobalVariables
        }
        contentfulFeatureManagementSystem(
            name: { eq: "Feature Management System" }
        ) {
            ...FeatureManagementSystem
        }
        contentfulHreflangManagement(name: { eq: "Hreflang Management" }) {
            ...HreflangManagement
        }
    }
`;
