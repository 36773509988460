import React from 'react';

import styles from './VideoPlayIcon.module.scss';

class VideoPlayIcon extends React.PureComponent {
    render() {
        return (
            <span
                className={styles.svgContainer}
                dangerouslySetInnerHTML={{
                    __html: `
          <svg version="1.1" class=${styles.playButton} id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="64px" height="64px" viewBox="0 0 64 64" enable-background="new 0 0 64 64" xml:space="preserve">
            <g>
              <polygon fill="none" stroke-linejoin="bevel" stroke-miterlimit="10" points="27,21 41,32
                  27,43   "></polygon>
              <path fill="none" stroke-miterlimit="10" d="M53.92,10.081
                  c12.107,12.105,12.107,31.732,0,43.838c-12.106,12.108-31.734,12.108-43.839,0c-12.107-12.105-12.107-31.732,0-43.838
                  C22.186-2.027,41.813-2.027,53.92,10.081z"></path>
            </g>
          </svg>
        `
                }}
            />
        );
    }
}

export default VideoPlayIcon;
