import React from 'react';

import styles from './Social.module.scss';
import SocialMediaBar from '../Navigator/SocialMediaBar/SocialMediaBar';

import TrustPilotInlineRating from '../TrustPilotInlineRating/TrustPilotInlineRating';

const Social = ({ fullSlug }) => {
    return (
        <div className={styles.Social}>
            <SocialMediaBar fullSlug={fullSlug} />
            <TrustPilotInlineRating />
        </div>
    );
};

export default Social;
