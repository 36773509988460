import React from 'react';
import MetaData from './MetaData';
import Footer from '../Footer/Footer';
import Navbar from '../../components/NavBar/NavBar';

import 'bootstrap/dist/css/bootstrap-grid.css';
import 'pretty-checkbox/dist/pretty-checkbox.min.css';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import '../../css/styles.styl';
import styles from './Layout.module.scss';
import { fetchIsLoggedIn } from '../../util/authHelpers';

if (process.env.NODE_ENV !== 'production' && typeof window !== 'undefined') {
    const { registerObserver } = require('react-perf-devtool');
    registerObserver();
}

class Layout extends React.Component {
    render() {
        const userIsLoggedIn = fetchIsLoggedIn();

        const {
            children,
            metaData,
            structuredData,
            useBigFooter = true,
            navbarOverride = {} // Used to override the navbar when page is using the Layout Component
        } = this.props;

        return (
            <React.Fragment>
                {metaData && (
                    <MetaData
                        metaData={metaData}
                        structuredData={structuredData}
                    />
                )}
                <Navbar loggedIn={userIsLoggedIn} {...navbarOverride} />
                <div className={`layout ${styles.layout}`}>
                    <div
                        className="layout__content"
                        onClick={this.handleContentClick}>
                        {children}
                        {useBigFooter && <Footer />}
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

Layout.defaultProps = {
    menu: null,
    footerData: {},
    data: {},
    userState: false,
    globalVariables: {}
};

Layout.propTypes = {};

export default Layout;
