import variables from '../../variables.json';
import { getCountryBuild } from '../../util/localization';

export function getContenthubArticleFullSlug(category, slug) {
    if (getCountryBuild() === 'uk') {
        if (category === 'Finance') {
            return `/finance-explained${slug}`;
        } else if (
            category === 'Engineering and Data Science' ||
            category === 'Product and Design' ||
            category === 'People of iwoca'
        ) {
            return `/inside-iwoca${slug}`;
        }
    }

    return `${
        variables.contentHubPath[process.env.GATSBY_COUNTRY_BUILD]
    }${slug}`;
}
