export default {
    url: {
        '/kreditzugang/': 'kredit_zugang',
        '/qonto/': 'qonto',
        '/ebay/': 'ebay',
        '/fyrst/': 'fyrst',
        '/airbank/': 'airbank',
        '/axa/': 'axa'
    },
    param: {
        dfkp: 'dfkp'
    }
};
