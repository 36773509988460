import React from 'react';
import messages from '../../../Article.messages';
import { useIntl } from 'react-intl';
import { useStaticQuery, graphql } from 'gatsby';
import MediaIcon from '../MediaIcon/MediaIcon';
import CopyMediaIcon from '../CopyMediaIcon/CopyMediaIcon';
import styles from './SocialMediaBar.module.scss';

import { getCountryBuild } from '../../../../../../util/localization';

const isUk = getCountryBuild() === 'uk';
const domain = isUk ? 'www.iwoca.co.uk' : 'www.iwoca.de';

function useSocialMediaLinks() {
    const { contentfulArticleSocialMediaLinks } = useStaticQuery(
        graphql`
            {
                contentfulArticleSocialMediaLinks {
                    socialMediaLink {
                        socialMediaIcon {
                            fluid(maxWidth: 600, quality: 80) {
                                ...GatsbyContentfulFluid_withWebp_noBase64
                            }
                        }
                        name
                    }
                }
            }
        `
    );

    return contentfulArticleSocialMediaLinks;
}

const SocialMediaBar = ({ fullSlug }) => {
    const data = useSocialMediaLinks();
    const { formatMessage } = useIntl();

    return (
        <div className={styles.SocialMediaContainer}>
            <h3>
                {formatMessage({
                    ...messages.shareArticle
                })}
            </h3>
            <div className={styles.SocialMedia}>
                {data.socialMediaLink.map((social) => {
                    if (social.name === 'Facebook - Article') {
                        return handleFacebook(social, fullSlug);
                    } else if (social.name === 'Twitter - Article') {
                        return handleTwitter(social, fullSlug);
                    } else if (social.name === 'Linkedin - Article') {
                        return handleLinkedIn(social, fullSlug);
                    } else if (social.name === 'Whatsapp - Article') {
                        return handleWhatsapp(social, fullSlug);
                    }

                    return (
                        <MediaIcon
                            image={social.socialMediaIcon}
                            url={fullSlug}
                        />
                    );
                })}
                <CopyMediaIcon />
            </div>
        </div>
    );
};

const handleFacebook = ({ socialMediaIcon }, fullSlug) => {
    return (
        <MediaIcon
            image={socialMediaIcon}
            url={`https://www.facebook.com/sharer/sharer.php?u=${domain}${fullSlug}`}
        />
    );
};

const handleTwitter = ({ socialMediaIcon }, fullSlug) => {
    return (
        <MediaIcon
            image={socialMediaIcon}
            url={`https://twitter.com/intent/tweet?text=${domain}${fullSlug}`}
        />
    );
};

const handleLinkedIn = ({ socialMediaIcon }, fullSlug) => {
    return (
        <MediaIcon
            image={socialMediaIcon}
            url={`https://www.linkedin.com/shareArticle?mini=true&url=${domain}${fullSlug}`}
        />
    );
};

const handleWhatsapp = ({ socialMediaIcon }, fullSlug) => {
    return (
        <MediaIcon
            image={socialMediaIcon}
            url={`https://wa.me/?text=${domain}${fullSlug}`}
        />
    );
};

export default SocialMediaBar;
