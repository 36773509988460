import React, { Component } from 'react';
import PropTypes from 'prop-types';
import HowItWorksSection from './HowItWorksSection';
import styles from './HowItWorks.module.scss';

class HowItWorks extends Component {
    render() {
        const { data } = this.props;
        const { headline, subheadline, steps } = data;
        const isVertical = steps && steps.length > 2;

        return (
            <div className={styles.section}>
                <section className={styles.container}>
                    <header className={styles.header}>
                        <h2 className={styles.title}>{headline}</h2>
                        <p className={styles.subtitle}>{subheadline}</p>
                    </header>
                    {steps && (
                        <div
                            className={
                                isVertical
                                    ? `${styles.sections} ${styles.sections__vertical}`
                                    : `${styles.sections}`
                            }>
                            {steps.map((stepData) => (
                                <HowItWorksSection
                                    step={stepData}
                                    key={stepData.title}
                                    isVertical={isVertical}
                                />
                            ))}
                        </div>
                    )}
                </section>
            </div>
        );
    }
}

HowItWorks.propTypes = {
    data: PropTypes.shape({
        steps: PropTypes.arrayOf(
            PropTypes.shape({
                title: PropTypes.string,
                description: PropTypes.shape({
                    description: PropTypes.string
                }),
                callToActionLinkText: PropTypes.string,
                banner: PropTypes.shape({
                    file: PropTypes.shape({
                        url: PropTypes.string
                    })
                })
            })
        ),
        headline: PropTypes.string.isRequired,
        subheadline: PropTypes.string.isRequired
    })
};

export default HowItWorks;
