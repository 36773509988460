import { formatMoney } from 'aphrodite-shared/util/helpers';
import { postToDataLayer } from './googleTracking';

// http://financeformulas.net/Loan_Payment_Formula.html
export function calculateMonthlyEqualRepayment(
    presentValue,
    interest,
    numberOfPeriods
) {
    return (presentValue * interest) / (1 - (1 + interest) ** -numberOfPeriods);
}

export function getRepaymentDetails(requestedAmount, months, interestRate) {
    let repaymentDetails = [];

    const monthlyEqualRepayment = calculateMonthlyEqualRepayment(
        requestedAmount,
        interestRate / 100,
        months
    );

    for (let index = 0; index < months; index++) {
        const interest = (requestedAmount * interestRate) / 100;
        const originalLoan = monthlyEqualRepayment - interest;
        const monthlyRepayment = monthlyEqualRepayment;
        requestedAmount -= originalLoan;

        repaymentDetails.push({
            month: index + 1,
            originalLoan: formatMoney(originalLoan),
            interest: formatMoney(interest),
            monthlyRepayment: formatMoney(monthlyRepayment)
        });
    }

    return repaymentDetails;
}

export function sendSliderValueToGA(value, sliderType) {
    //http://www.lukekedz.com/rc-slider-onafterchange-event-behavior/
    const activeClass = document.activeElement.className;
    if (activeClass !== 'rc-slider-handle rc-slider-handle-click-focused')
        return;

    if (typeof window === 'undefined') return;

    const calculatorData = {
        event: `calculatorInteraction-${sliderType}`,
        calculatorData: {
            [sliderType]: value
        }
    };

    //Send data to GA
    postToDataLayer(calculatorData);
}
