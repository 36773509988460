import React from 'react';
import lodashGet from 'lodash/get';

import styles from './CustomerVideoSection.module.scss';

import SectionContainer from './SectionContainer';
import VideoPlayIcon from './VideoPlayIcon';

class CustomerVideoSection extends React.PureComponent {
    render() {
        const { data } = this.props;

        if (!data) {
            console.error(
                'CustomerVideoSection: No data found. Please provide an data object'
            );
            return null;
        }

        const { bannerText, banner, youtubeVideoId, quickJumpLink } = data;

        const quickJumpHash = quickJumpLink && quickJumpLink.hash;

        return (
            <SectionContainer quickJumpHash={quickJumpHash}>
                <div
                    style={
                        lodashGet(banner, 'fixed.src') && {
                            backgroundImage: `url(${banner.fixed.src})`
                        }
                    }
                    className={styles.customerVideo}>
                    <a
                        href={`https://www.youtube.com/watch?v=${youtubeVideoId}`}
                        className={styles.videoLink}
                        target="_blank"
                        rel="noopener noreferrer">
                        {bannerText && (
                            <h3 className={styles.videoTitle}>{bannerText}</h3>
                        )}
                        <VideoPlayIcon />
                    </a>
                </div>
            </SectionContainer>
        );
    }
}

CustomerVideoSection.propTypes = {};

export default CustomerVideoSection;
