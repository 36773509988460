import React from 'react';
import { BasicCircle } from '../../../../SVGComponents/shapes';
import classnames from 'classnames';
import styles from './shapes.module.scss';

const MenuShapes = ({ children, isOpen }) => {
    const activeStyle = isOpen ? styles.Active : '';

    return (
        <>
            <BasicCircle
                color="denim"
                className={classnames(styles.BasicCircle, activeStyle)}
            />

            {children}
        </>
    );
};

export default MenuShapes;
