import { defineMessages } from 'react-intl';

export default defineMessages({
    lets_get_started: {
        id: '_.step_zero__lets_get_started',
        defaultMessage: 'Let’s get started'
    },

    wont_affect_credit_score: {
        id: '_.step_zero__wont_affect_credit_score',
        defaultMessage: 'Applying won’t affect your credit score'
    },

    whats_next: {
        id: '_.step_zero__whats_next',
        defaultMessage: "What's next?"
    },
    apply_in_five_minutes: {
        id: '_.step_zero__apply_in_five_minutes',
        defaultMessage: 'Apply in 5 minutes'
    },
    account_manager: {
        id: '_.step_zero__account_manager',
        defaultMessage: 'Meet your account manager'
    },
    money_in_the_bank: {
        id: '_.step_zero__money_in_the_bank',
        defaultMessage: 'Get money in the bank in hours'
    },

    trustpilot: {
        id: '_.step_zero__trustpilot',
        defaultMessage: 'Based on <span>{reviews}</span> reviews •'
    },
    trustpilot_out_of: {
        id: '_.step_zero__trustpilot_out_of',
        defaultMessage: '<span>{score}</span> out of 5'
    },

    call_us: {
        id: '_.step_zero__call_us',
        defaultMessage: 'Need help? Call us:'
    },
    phone_number: {
        id: '_.step_zero__phone_number',
        defaultMessage: '020 3778 0274'
    }
});
