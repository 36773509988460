export function postToDataLayer(data) {
    if (typeof window !== 'undefined') {
        if (!window.dataLayer) {
            return;
        }

        window.dataLayer.push(data);
    }
}
export function postEventToDataLayer(eventName, eventData) {
    if (!eventName || !eventData) return;

    return {
        event: eventName,
        ...eventData
    };
}

export function logErrorToDataLayer(errorType) {
    postEventToDataLayer('error', {
        errorData: {
            errorType,
            url: typeof window !== 'undefined' && window.location.href
        }
    });
}
