import React, { useContext } from 'react';
import IwContext from 'aphrodite-shared/IwContext/IwContext';
import StepZeroModal from './StepZeroModal/StepZeroModal';
import { MODALS } from 'aphrodite-shared/util/iwocaConstants';

const IwModals = ({ openModal, maximumLoanAmount }) => {
    const context = useContext(IwContext);
    return (
        <>
            <StepZeroModal
                isOpen={
                    context.activeModals.includes(MODALS.signupStepZero) ||
                    openModal === MODALS.signupStepZero
                }
                maximumLoanAmount={maximumLoanAmount}
                prefilledOptions={context.prefilledOptions}
            />
        </>
    );
};

export default IwModals;
