import React from 'react';
import PropTypes from 'prop-types';

import styles from './FeaturesBarSection.module.scss';

import SectionContainer from './SectionContainer';

class FeaturesBarSection extends React.Component {
    render() {
        const { data } = this.props;

        if (!data) {
            console.error(
                'FeaturesBarSection: No data found. Please provide an data object'
            );
            return null;
        }

        const { feature: features, title, subtitle } = data;

        return (
            <SectionContainer className={styles.featuresBarSection}>
                {title && <h3 className={styles.title}>{title}</h3>}
                {subtitle && (
                    <span className={styles.subtitle}>{subtitle}</span>
                )}

                <div className={styles.featuresContainer}>
                    {features &&
                        features.map((feature, index) => (
                            <div className={styles.feature} key={index}>
                                <img
                                    src={feature.icon.file.url}
                                    className={styles.icon}
                                    alt={feature.featureTitle.featureTitle}
                                />
                                <h5 className={styles.featureTitle}>
                                    {feature.featureTitle.featureTitle}
                                </h5>
                                <small
                                    className={styles.featureDescription}
                                    dangerouslySetInnerHTML={{
                                        __html: feature.description.description
                                    }}
                                />
                            </div>
                        ))}
                </div>
            </SectionContainer>
        );
    }
}

FeaturesBarSection.propTypes = {
    data: PropTypes.shape({
        feature: PropTypes.arrayOf(
            PropTypes.shape({
                icon: PropTypes.shape({
                    file: PropTypes.shape({
                        url: PropTypes.string.isRequired
                    }).isRequired
                }).isRequired,
                featureTitle: PropTypes.shape({
                    featureTitle: PropTypes.string.isRequired
                }).isRequired,
                featureDescription: PropTypes.shape({
                    featureDescription: PropTypes.string.isRequired
                }).isRequired
            })
        ),
        title: PropTypes.string,
        subtitle: PropTypes.string,
        quickJumpLink: PropTypes.shape({
            hash: PropTypes.string
        })
    })
};

export default FeaturesBarSection;
