export default {
    de: {
        myAccount: 'Mein Konto',
        viewMyAccount: 'Kontoübersicht',
        logout: 'Ausloggen',
        readArticle: 'Artikel lesen',
        originalLoan: 'Kapitaltilgung'
    },
    uk: {
        myAccount: 'My account',
        viewMyAccount: 'View my account',
        logout: 'Logout',
        readArticle: 'Read article',
        originalLoan: 'Original loan'
    }
};
