import React from 'react';
import ReactMarkdown from 'react-markdown';
import lodashGet from 'lodash/get';
import styles from './PartnersIntroText.module.scss';
import { LongArch } from '../../../../SVGComponents/shapes';

const PartnersIntroText = (props) => {
    const { introHeader, introContent, underCTAText } = props;
    return (
        <div className={styles.PartnersIntroText}>
            {lodashGet(underCTAText, 'underCTAText') && (
                <ReactMarkdown
                    className={styles.underBoxText}
                    source={underCTAText.underCTAText}
                />
            )}
            <h2>{introHeader}</h2>
            <ReactMarkdown
                source={introContent.introContent}
                escapeHtml={false}
            />
            <LongArch className={styles.line} rotate="right" size="lg" />
        </div>
    );
};

export default PartnersIntroText;
