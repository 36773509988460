import React from 'react';
import ReactMarkdown from 'react-markdown';
import Img from 'gatsby-image';
import Slider from 'react-slick';
import { BasicDoughnut, LongArch } from '../../../../SVGComponents/shapes';
import styles from './Benefits.module.scss';
import { useIntl } from 'react-intl';
import messages from '../Careers.messages';

const Benefits = ({ benefits }) => {
    const { formatMessage } = useIntl();

    let settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        dotsClass: `${styles.dots}`
    };

    const renderBenefits = () => {
        if (benefits) {
            return benefits.map((benefit, i) => (
                <div className={styles.info} key={i}>
                    <Img fluid={benefit.icon.fluid} className={styles.icon} />
                    <h3
                        dangerouslySetInnerHTML={{
                            __html: benefit.title
                        }}></h3>
                    <ReactMarkdown source={benefit.description.description} />
                </div>
            ));
        }
        return null;
    };

    return (
        <section>
            <div className={styles.Benefits}>
                <h2>
                    {formatMessage({
                        ...messages.benefits
                    })}
                </h2>
                <hr />
                <div className={styles.desktopInfoContainer}>
                    <BasicDoughnut
                        className={styles.BasicDoughnut}
                        color="london"
                    />

                    {renderBenefits()}
                </div>
                <div className={styles.mobileInfoContainer}>
                    <LongArch className={styles.LongArch} color="london" />

                    <Slider {...settings}>{renderBenefits()}</Slider>
                </div>
            </div>
        </section>
    );
};

export default Benefits;
