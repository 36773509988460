import { defineMessages } from 'react-intl';

export default defineMessages({
    whats_your_email_address: {
        id: '_.step_zero__whats_your_email_address',
        defaultMessage: 'What is your business email address?'
    },
    please_enter_a_valid_email_address: {
        id: '_.step_zero__please_enter_a_valid_email_address',
        defaultMessage: 'Please enter a valid email address'
    },
    email_already_registered: {
        id: '_.step_zero__email_already_registered',
        defaultMessage: 'This email is already registered'
    },
    email_placeholder: {
        id: '_.step_zero__email_placeholder',
        defaultMessage: 'Enter your business email'
    },
    please_choose_an_option: {
        id: '_.step_zero__please_choose_an_option',
        defaultMessage: 'Please choose an option'
    },
    as_soon_as_possible: {
        id: '_.step_zero__as_soon_as_possible',
        defaultMessage: 'As soon as possible'
    },
    within_the_next_few_weeks: {
        id: '_.step_zero__within_the_next_few_weeks',
        defaultMessage: 'Within the next few weeks'
    },
    just_checking_for_the_future: {
        id: '_.step_zero__just_checking_for_the_future',
        defaultMessage: 'Just checking for the future'
    },
    please_choose_an_option__error: {
        id: '_.step_zero__please_choose_an_option__error',
        defaultMessage: 'Please choose an option'
    },

    how_much_would_you_like: {
        id: '_.step_zero__how_much_would_you_like',
        defaultMessage: 'How much would you like?'
    },
    any_amount_up_to: {
        id: '_.step_zero__any_amount_up_to',
        defaultMessage: 'Any amount, up to {amount}'
    },
    any_amount_up_to__error: {
        id: '_.step_zero__any_amount_up_to__error',
        defaultMessage: 'Please enter a requested amount'
    },
    how_will_you_use_the_funds: {
        id: '_.step_zero__how_will_you_use_the_funds',
        defaultMessage: 'How will you use the funds?'
    },
    inventory_stock: {
        id: '_.step_zero__inventory_stock',
        defaultMessage: 'Inventory/stock purchase'
    },
    bridging_loan: {
        id: '_.step_zero__bridging_loan',
        defaultMessage: 'Bridging loan'
    },
    marketing: {
        id: '_.step_zero__marketing',
        defaultMessage: 'Marketing'
    },
    equipment: {
        id: '_.step_zero__equipment',
        defaultMessage: 'Equipment/building purchase'
    },
    pay_a_due_bill: {
        id: '_.step_zero__pay_a_due_bill',
        defaultMessage: 'Pay a due bill'
    },
    pay_staff: {
        id: '_.step_zero__pay_staff',
        defaultMessage: 'Pay staff'
    },
    refinancing_debt: {
        id: '_.step_zero__refinancing_debt',
        defaultMessage: 'Refinancing debt'
    },
    other: {
        id: '_.step_zero__other',
        defaultMessage: 'Other purpose'
    },
    please_select_the_purpose_for_your_loan: {
        id: '_.step_zero__please_select_the_purpose_for_your_loan',
        defaultMessage: 'Please select the purpose for your loan'
    },

    marketing_sign_up: {
        id: '_.step_zero__marketing_sign_up',
        defaultMessage:
            "Sometimes we email out offers and prize giveaways. Interested? We'll never send spam or fluff."
    },
    marketing_sign_up_action: {
        id: '_.step_zero__marketing_sign_up_action',
        defaultMessage: 'Sign me up for the emails'
    },

    other_purpose: {
        id: '_.step_zero__other_purpose',
        defaultMessage: 'Other purpose'
    },
    tell_us_how: {
        id: '_.step_zero__tell_us_how',
        defaultMessage: 'Tell us how you will use the funds'
    },

    next: {
        id: '_.step_zero__next',
        defaultMessage: 'Next'
    },
    submitting: {
        id: '_.step_zero__submitting',
        defaultMessage: 'Submitting'
    },
    how_long_do_you_need_the_loan_for: {
        id: '_.step_zero__how_long_do_you_need_the_loan_for',
        defaultMessage: 'How long do you need the loan for?'
    },
    short_term: {
        id: '_.step_zero__short_term',
        defaultMessage: 'Short Term'
    },
    medium_term: {
        id: '_.step_zero__medium_term',
        defaultMessage: 'Medium Term'
    },
    long_term: {
        id: '_.step_zero__long_term',
        defaultMessage: 'Long Term'
    }
});
