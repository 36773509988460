import React from 'react';
import classnames from 'classnames';
import styles from '../shapes.module.scss';
import settings from '../settings';

const Settings = {
    sizes: {
        md: {
            height: '291',
            width: '420'
        }
    },
    ...settings
};

const ArchWithSquare = ({
    className,
    primaryColor = 'london75',
    secondaryColor = 'denim',
    size = 'md',
    rotate = 'left'
}) => {
    return (
        <svg
            className={classnames(styles.shape, className)}
            width={Settings.sizes[size].width}
            height={Settings.sizes[size].height}
            viewBox={`0 0 ${Settings.sizes[size].width} ${Settings.sizes[size].height}`}
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M214.1 291.283C204.312 173.008 99.2 94.9673 -17.4775 94.9673"
                stroke={Settings.colors[primaryColor]}
                stroke-width="2.95665"
                stroke-miterlimit="10"
            />
            <path
                d="M62.4184 134.76L25.7851 123.248C24.4975 122.845 23.7954 121.5 24.1995 120.213L35.6772 83.7442C36.0812 82.4572 37.4263 81.7553 38.7139 82.1589L75.2287 93.6293C76.5164 94.0329 77.2191 95.3815 76.8144 96.6641L65.341 133.133C64.966 134.433 63.7061 135.163 62.4184 134.76Z"
                fill={Settings.colors[secondaryColor]}
            />
            <rect y="290.478" width="1440" height="191" fill="#EEF3F7" />
        </svg>
    );
};

export default ArchWithSquare;
