import React from 'react';
import lodashGet from 'lodash/get';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';
import styles from './Review.module.scss';

class Reviews extends React.Component {
    render() {
        const {
            customerImage,
            text,
            customerName,
            legacyUrl
        } = this.props.review;

        return (
            <section className={styles.review}>
                {lodashGet(customerImage, 'fixed') && (
                    <Img
                        fluid={customerImage.fixed}
                        className={styles.customerImage}
                    />
                )}
                {lodashGet(text, 'text') && text.text && (
                    <small className={styles.text}>{`"${text.text}"`}</small>
                )}
                {customerName && (
                    <small className={styles.customerName}>
                        <a
                            href={legacyUrl}
                            target="_blank"
                            rel="noopener noreferrer">
                            {customerName}
                        </a>
                    </small>
                )}
            </section>
        );
    }
}

Reviews.propTypes = {
    reviews: PropTypes.shape({
        customerImage: PropTypes.shape({
            file: PropTypes.shape({
                url: PropTypes.string
            })
        }),
        title: PropTypes.string,
        customerName: PropTypes.string
    })
};

export default Reviews;
