import React from 'react';
import styles from './PartnersFooter.module.scss';

import lodashGet from 'lodash/get';
import IwocaLogoSVG from '../../../../SVGComponents/IwocaLogoSVG';
import Button from '../../../../components/common/Button';
import ReactMarkdown from 'react-markdown';

const PartnersFooter = ({
    email,
    number,
    numberLabel,
    loginLink,
    loginLabel,
    underCTAText
}) => {
    return (
        <React.Fragment>
            {lodashGet(underCTAText, 'underCTAText') && (
                <ReactMarkdown
                    className={styles.underBoxText}
                    source={underCTAText.underCTAText}
                />
            )}
            <div className={styles.PartnersFooter}>
                <div className={styles.PartnersPrimaryFooter}>
                    <div className={styles.inner}>
                        <div className={styles.column}>
                            <IwocaLogoSVG />
                        </div>
                        <div className={styles.column}>
                            <a href={`mailto:${email}`}>{email}</a>
                        </div>
                        <div className={styles.column}>
                            <a href={`tel:${number}`}>{numberLabel}</a>
                        </div>
                        <div className={styles.column}>
                            <Button href={loginLink} colour="primary">
                                {loginLabel}
                            </Button>
                        </div>
                    </div>
                </div>
                <div className={styles.PartnersSecondaryFooter}>
                    iwoca Deutschland GmbH © 2014-{new Date().getFullYear()}
                </div>
            </div>
        </React.Fragment>
    );
};

export default PartnersFooter;
