import React, { Component } from 'react';
import { graphql } from 'gatsby';

import Layout from '../../../components/Layout/Layout';
import PartnersIntroText from './PartnersIntroText/PartnersIntroText';
import PartnersRequirements from './PartnersRequirements/PartnersRequirements';
import PartnersDocuments from './PartnersDocuments/PartnersDocuments';
import PartnersCTABox from './PartnersCTABox/PartnersCTABox';
import HeroBanner from '../../../components/common/HeroBanner/HeroBanner';
import PartnersOutroText from './PartnersOutroText/PartnersOutroText';
import BelowHeaderCTA from './BelowHeaderCTA/BelowHeaderCTA';
import PartnersFooter from './PartnersFooter/PartnersFooter';
import { PartnersBottomCTA } from './PartnersBottomCTA/PartnersBottomCTA';

function generateNavbarOverrideDataFromHeaderData(header) {
    if (!header) return;

    const { contactLinks, standardButtons, scrollButton } = header;
    return {
        phoneNumber:
            contactLinks && contactLinks.length > 0 && contactLinks[0].linkText,
        phoneNumberLink:
            contactLinks && contactLinks.length > 0 && contactLinks[0].url,
        ctaButton: standardButtons &&
            standardButtons.length > 0 && {
                href: standardButtons[0].url,
                text: standardButtons[0].buttonText
            },
        loginButton: scrollButton && {
            href: scrollButton.url,
            text: scrollButton.buttonText
        }
    };
}

class partnersPage extends Component {
    render() {
        const { data } = this.props;
        const {
            contentfulPageCommerzbankLanding: pageData,
            contentfulHreflangManagement
        } = data;

        const {
            globalVariables = {},
            contentfulFeatureManagementSystem = {}
        } = this.props;

        const {
            title,
            url,
            metaDescription,
            allowSearchEngineScraping,
            canonicalTag,
            header,
            headerPdf,
            isPersonImage,
            firstPdfLabel,
            firstPdfDownload,
            secondPdfLabel,
            secondPdfDownload,
            thirdPdfLabel,
            thirdPdfDownload,
            partnerSignupLabel,
            partnerSignupLink,
            companyLogo,
            email,
            telNumber,
            telNumberLabel,
            loginLink,
            loginLabel,
            underCTAText
        } = pageData;

        const navbarOverride = generateNavbarOverrideDataFromHeaderData(header);

        return (
            <>
                <Layout
                    headerData={header}
                    data={data}
                    useBigFooter={false}
                    globalVariables={globalVariables}
                    metaData={{
                        titleTag: title,
                        metaDescription,
                        allowSearchEngineScraping,
                        url,
                        canonicalTag,
                        contentfulHreflangManagement
                    }}
                    featureFlags={contentfulFeatureManagementSystem}
                    navbarOverride={navbarOverride}>
                    <a href={headerPdf?.file?.url}>
                        <HeroBanner
                            text={pageData.heroText}
                            isPersonImage={isPersonImage}
                            fluidImage={pageData.headerImage.fluid}
                            hasCTABox
                            underHeader={
                                companyLogo && (
                                    <img
                                        style={{ width: '240px' }}
                                        src={companyLogo.file.url}
                                        alt="Partner logo"
                                    />
                                )
                            }
                        />
                    </a>
                    <BelowHeaderCTA
                        firstPDFLabel={firstPdfLabel}
                        firstPDF={firstPdfDownload}
                        secondPDFLabel={secondPdfLabel}
                        secondPDF={secondPdfDownload}
                        thirdPDFLabel={thirdPdfLabel}
                        thirdPDF={thirdPdfDownload}
                        partnerSignupLabel={partnerSignupLabel}
                        partnerSignupLink={partnerSignupLink}
                    />
                    <PartnersIntroText {...pageData} />
                    <PartnersRequirements {...pageData} />
                    <PartnersDocuments {...pageData} />
                    <PartnersOutroText {...pageData} />
                    <PartnersCTABox
                        firstPDFLabel={firstPdfLabel}
                        firstPDF={firstPdfDownload}
                        secondPDFLabel={secondPdfLabel}
                        secondPDF={secondPdfDownload}
                        thirdPDFLabel={thirdPdfLabel}
                        thirdPDF={thirdPdfDownload}
                        partnerSignupLabel={partnerSignupLabel}
                        partnerSignupLink={partnerSignupLink}
                    />
                    <PartnersBottomCTA
                        partnerSignupLabel={partnerSignupLabel}
                        partnerSignupLink={partnerSignupLink}
                    />
                    <PartnersFooter
                        email={email}
                        number={telNumber}
                        numberLabel={telNumberLabel}
                        loginLink={loginLink}
                        loginLabel={loginLabel}
                        underCTAText={underCTAText}
                    />
                </Layout>
            </>
        );
    }
}

export default partnersPage;

export const query = graphql`
    query CommerzbankPageQuery($id: String!) {
        contentfulPageCommerzbankLanding(id: { eq: $id }) {
            title
            url
            metaDescription
            allowSearchEngineScraping
            header {
                ...Header
            }
            heroText
            headerImage {
                fluid(maxWidth: 1440, quality: 80) {
                    ...GatsbyContentfulFluid_withWebp_noBase64
                }
            }
            headerPdf {
                file {
                    url
                }
            }
            isPersonImage
            firstPdfLabel
            firstPdfDownload {
                file {
                    url
                }
            }
            secondPdfLabel
            secondPdfDownload {
                file {
                    url
                }
            }
            thirdPdfLabel
            thirdPdfDownload {
                file {
                    url
                }
            }
            underCTAText {
                underCTAText
            }
            partnerSignupLabel
            partnerSignupLink
            companyLogo {
                file {
                    url
                }
            }
            introHeader
            introContent {
                introContent
            }
            requirementsTitle
            requirementsContent {
                requirementsContent
            }
            documentsTitle
            documentsIntroText {
                documentsIntroText
            }
            documentsDetails {
                documentsDetails
            }
            firstAmountTitle
            firstAmountDetails {
                firstAmountDetails
            }
            secondAmountTitle
            secondAmountDetails {
                secondAmountDetails
            }
            thirdAmountTitle
            thirdAmountDetails {
                thirdAmountDetails
            }
            outroTitle
            outroText {
                outroText
            }
            email
            telNumber
            telNumberLabel
            loginLink
            loginLabel
        }

        contentfulHreflangManagement(name: { eq: "Hreflang Management" }) {
            ...HreflangManagement
        }

        contentfulGlobalVariables {
            ...GlobalVariables
        }
    }
`;
