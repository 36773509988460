import React from 'react';
import IwLink from '../IwLink/IwLink';
import classNames from 'classnames';
import styles from './Button.module.scss';

function Button({
    href,
    to,
    title,
    type,
    disabled,
    className,
    children,
    outline,
    onClick,
    colour,
    icon,
    size,
    newTab,
    dataGaId,
    dataTestid
}) {
    const buttonClassName = outline ? styles.outlinedButton : styles.button;

    function handleOnClick(e) {
        e.preventDefault();
        onClick();
    }

    function generateClassName() {
        return classNames(
            buttonClassName,
            className,
            styles[`${colour}Button`],
            styles[`${size}Button`]
        );
    }

    if (href) {
        return (
            <a
                href={href && href}
                title={title && title}
                className={generateClassName()}
                role="button"
                target={newTab ? '_blank' : '_self'}
                onClick={
                    onClick
                        ? (e) => {
                              handleOnClick(e);
                          }
                        : null
                }
                data-ga-id={dataGaId}>
                {icon}
                {children}
            </a>
        );
    }

    if (to) {
        return (
            <IwLink
                to={to}
                title={title && title}
                className={generateClassName()}
                role="button"
                data-ga-id={dataGaId}>
                {icon}
                {children}
            </IwLink>
        );
    }

    return (
        <button
            title={title && title}
            type={type}
            disabled={disabled}
            className={generateClassName()}
            role="button"
            onClick={
                onClick
                    ? (e) => {
                          handleOnClick(e);
                      }
                    : null
            }
            data-ga-id={dataGaId}
            data-testid={dataTestid}>
            {icon}
            {children}
        </button>
    );
}

export default Button;
