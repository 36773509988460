import React from 'react';
import { capitalizeFirstLetter } from 'aphrodite-shared/util/helpers';
import styles from './CalculatorMonthsTable.module.scss';
import { translate } from '../../../util/localization';

class CalculatorMonthsTable extends React.Component {
    render() {
        const {
            repaymentDetails,
            loanAmount,
            data,
            formatMoney,
            globalData
        } = this.props;

        const totalInterest = repaymentDetails.reduce(
            (sum, repaymentDetail) =>
                sum + repaymentDetail.interest.props.value,
            0
        );
        const totalBorrowed = loanAmount.props.value + totalInterest;

        let tableRows = repaymentDetails.map((repaymentDetail) => (
            <tr className="t-body-row" key={repaymentDetail.month}>
                <td className="t-col">{repaymentDetail.month}</td>
                <td className="t-col">
                    {data.labelCurrency}
                    {repaymentDetail.originalLoan}
                </td>
                <td className="t-col">
                    {data.labelCurrency}
                    {repaymentDetail.interest}
                </td>
                <td className="t-col">
                    {data.labelCurrency}
                    {repaymentDetail.monthlyRepayment}
                </td>
            </tr>
        ));

        return (
            <table className={styles.table}>
                <thead className={styles.tableHeader}>
                    <tr className="t-header">
                        <th className="t-col">
                            {capitalizeFirstLetter(
                                globalData.labelMonthSingular || 'Month'
                            )}
                        </th>
                        <th className="t-col">
                            {capitalizeFirstLetter(translate('originalLoan'))}
                        </th>
                        <th className="t-col">
                            {capitalizeFirstLetter(data.interest)}
                        </th>
                        <th className="t-col">
                            {capitalizeFirstLetter(data.monthlyRepaymentText)}
                        </th>
                    </tr>
                </thead>

                <tbody>{tableRows}</tbody>

                <tfoot className="t-footer">
                    <tr>
                        <td className="t-col">
                            {data.principal || 'Total repayment'}
                        </td>
                        <td className="t-col">
                            {data.labelCurrency}
                            {loanAmount}
                        </td>
                        <td className="t-col">
                            {data.labelCurrency}
                            {formatMoney
                                ? formatMoney(totalInterest)
                                : totalInterest}
                        </td>
                        <td className="t-col">
                            {data.labelCurrency}
                            {formatMoney
                                ? formatMoney(totalBorrowed)
                                : totalBorrowed}
                        </td>
                    </tr>
                </tfoot>
            </table>
        );
    }
}

export default CalculatorMonthsTable;
