import React from 'react';
import ReactMarkdown from 'react-markdown';
import classNames from 'classnames';
import styles from './PartnersRequirements.module.scss';
import { BasicGrid } from '../../../../SVGComponents/shapes';

const PartnersRequirements = (props) => {
    const { requirementsTitle, requirementsContent, className } = props;
    return (
        <div className={classNames(styles.PartnersRequirements, className)}>
            <div className={styles.textContainer}>
                <div className={styles.textInner}>
                    <h2>{requirementsTitle}</h2>
                    <ReactMarkdown
                        source={requirementsContent.requirementsContent}
                        escapeHtml={false}
                    />
                    <BasicGrid className={styles.dotsRight} />
                    <BasicGrid className={styles.dotsLeft} />
                </div>
            </div>
        </div>
    );
};

export default PartnersRequirements;
