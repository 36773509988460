// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-de-partners-commerz-bank-page-jsx": () => import("./../src/templates/de/Partners/CommerzBankPage.jsx" /* webpackChunkName: "component---src-templates-de-partners-commerz-bank-page-jsx" */),
  "component---src-templates-uk-about-us-about-us-jsx": () => import("./../src/templates/uk/AboutUs/AboutUs.jsx" /* webpackChunkName: "component---src-templates-uk-about-us-about-us-jsx" */),
  "component---src-templates-uk-careers-careers-jsx": () => import("./../src/templates/uk/Careers/Careers.jsx" /* webpackChunkName: "component---src-templates-uk-careers-careers-jsx" */),
  "component---src-templates-uk-generic-landing-generic-landing-jsx": () => import("./../src/templates/uk/GenericLanding/GenericLanding.jsx" /* webpackChunkName: "component---src-templates-uk-generic-landing-generic-landing-jsx" */),
  "component---src-templates-uk-homepage-homepage-jsx": () => import("./../src/templates/uk/Homepage/Homepage.jsx" /* webpackChunkName: "component---src-templates-uk-homepage-homepage-jsx" */),
  "component---src-templates-website-page-js": () => import("./../src/templates/websitePage.js" /* webpackChunkName: "component---src-templates-website-page-js" */),
  "component---src-templates-press-page-js": () => import("./../src/templates/pressPage.js" /* webpackChunkName: "component---src-templates-press-page-js" */),
  "component---src-templates-legacy-page-js": () => import("./../src/templates/legacyPage.js" /* webpackChunkName: "component---src-templates-legacy-page-js" */),
  "component---src-templates-contenthub-content-hub-home-page-js": () => import("./../src/templates/contenthub/contentHubHomePage.js" /* webpackChunkName: "component---src-templates-contenthub-content-hub-home-page-js" */),
  "component---src-templates-uk-article-page-article-page-js": () => import("./../src/templates/uk/ArticlePage/ArticlePage.js" /* webpackChunkName: "component---src-templates-uk-article-page-article-page-js" */),
  "component---src-templates-contenthub-category-page-js": () => import("./../src/templates/contenthub/categoryPage.js" /* webpackChunkName: "component---src-templates-contenthub-category-page-js" */),
  "component---src-templates-one-trust-cookies-one-trust-cookies-jsx": () => import("./../src/templates/oneTrustCookies/oneTrustCookies.jsx" /* webpackChunkName: "component---src-templates-one-trust-cookies-one-trust-cookies-jsx" */),
  "component---src-templates-infroducer-signup-introducer-signup-jsx": () => import("./../src/templates/InfroducerSignup/IntroducerSignup.jsx" /* webpackChunkName: "component---src-templates-infroducer-signup-introducer-signup-jsx" */),
  "component---src-templates-infroducer-signup-introducer-signup-invite-jsx": () => import("./../src/templates/InfroducerSignup/IntroducerSignupInvite.jsx" /* webpackChunkName: "component---src-templates-infroducer-signup-introducer-signup-invite-jsx" */),
  "component---src-templates-redirect-page-js": () => import("./../src/templates/redirectPage.js" /* webpackChunkName: "component---src-templates-redirect-page-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */)
}

