import translations from '../translations';

/**
 * Gets the country build variable from the node environment
 *
 * @export
 * @returns {string} Value of the country build in lowercase characters
 */
export function getCountryBuild() {
    return process.env.GATSBY_COUNTRY_BUILD.toLowerCase();
}

/**
 * Gets the locale of the current build
 *
 * @export
 * @returns {string} Locale of the current build in lowercase characters
 */
export function getCurrentLocale() {
    const countryBuild = getCountryBuild();

    switch (countryBuild) {
        case 'uk':
            return 'en';
        default:
            return countryBuild;
    }
}

/**
 * Gives back the output of a given array dependent on the countrybuild, otherwise returns the
 * uk one. Fallback is an empty string. Format of input: {'lang': '...', 'value': '...'}
 *
 * @export
 * @param {array} [countryDependentOutputs=[]] Possible outputs for different countrybuilds
 * @returns {*} value dependent on the country build
 */
export function selectOutputByCountryBuild(countryDependentOutputs = []) {
    const countryBuild = getCountryBuild();
    let foundCountryOutput = false;
    let output;

    countryDependentOutputs.forEach((countryOutput) => {
        if (countryOutput.country.toLowerCase() === countryBuild) {
            output = countryOutput.value;
            foundCountryOutput = true;
        }
    });

    if (foundCountryOutput === true) {
        return output;
    }
}

/**
 * Returns the interest rates for the loan calculator, dependent on the country build
 *
 * @export
 * @returns {array} Values for loan calculator
 */
export function getLoanCalculatorInterestRates() {
    const loanCalculatorInterestRatesByCountry = [
        {
            country: 'uk',
            value: 3.33
        },
        {
            country: 'de',
            value: 2
        }
    ];

    return selectOutputByCountryBuild(loanCalculatorInterestRatesByCountry);
}

/**
 * Returns the translation for a given querystring
 *
 * @export
 * @returns {string} Translated string
 */
export function translate(stringToTranslate) {
    const countryBuild = getCountryBuild();

    if (
        typeof translations[countryBuild] === 'undefined' ||
        typeof translations[countryBuild][stringToTranslate] === 'undefined'
    ) {
        console.error(
            `Please provide a ${countryBuild.toUpperCase()}-translation for "${stringToTranslate}"`
        );
        return '';
    }

    return translations[countryBuild][stringToTranslate];
}

export function formatPhoneNumber(number) {
    number = number.substring(1); // remove the first 0
    if (getCountryBuild() === 'de') {
        return `tel:+49${number.replace(/[\s/]/g, '')}`; // remove slashe and spaces
    } else {
        return `tel:+44${number.replace(/\s/g, '')}`; // remove spaces
    }
}
