import React, { PureComponent } from 'react';
import { graphql } from 'gatsby';

import legacyPageComponentParser from './legacyPageComponentParser';
import LegacyPageLayout from '../components/Layout/LegacyPageLayout';
import { fetchIsLoggedIn } from '../util/authHelpers';

// import '../scss/legacy/globalStyles/globalStyles.scss';

class LegacyPage extends PureComponent {
    ContentHubHomePage;

    render() {
        const { data } = this.props;

        const globalVariables = data.contentfulGlobalVariables;

        const { contentfulLegacyPage, contentfulHreflangManagement } = data;

        const {
            titleTag,
            metaDescription,
            allowSearchEngineScraping,
            url,
            canonicalTag
        } = contentfulLegacyPage;

        const pageData = data.contentfulLegacyPage || {};
        const {
            header = {},
            sections = [],
            footer = {}
        } = pageData.pageContent;

        const isLoggedIn = fetchIsLoggedIn();

        // Sections
        const sectionsComponents = renderSections(sections, globalVariables, {
            pageData,
            header,
            isLoggedIn
        });

        // Forms
        const { applyForm, loginForm, resetForm, termLoansForm } = data;

        return (
            <LegacyPageLayout
                headerData={header}
                footerData={footer}
                data={data}
                userIsLoggedIn={isLoggedIn}
                globalVariables={globalVariables}
                modals={{
                    applyForm,
                    loginForm,
                    resetForm,
                    termLoansForm
                }}
                metaData={{
                    titleTag,
                    metaDescription,
                    allowSearchEngineScraping,
                    url,
                    canonicalTag,
                    contentfulHreflangManagement
                }}>
                {sectionsComponents}
            </LegacyPageLayout>
        );
    }
}

const renderSections = (sections, globalLabels, props) => {
    if (sections === null || sections.length < 1) return null;

    const result = sections.map((section, index) => {
        const sectionData = { ...section, ...globalLabels };

        return legacyPageComponentParser(section.__typename, {
            props,
            sectionData,
            index
        });
    });
    return result;
};

export default LegacyPage;

export const query = graphql`
    query LegacyWebsitePageQuery($id: String!) {
        contentfulLegacyPage(id: { eq: $id }) {
            ...LegacyPageData
        }
        contentfulGlobalVariables {
            ...GlobalVariables
        }
        allContentfulAsset {
            edges {
                node {
                    file {
                        url
                        fileName
                    }
                }
            }
        }
        applyForm: contentfulForm(type: { eq: "customer_signup_form" }) {
            ...Form
        }
        termLoansForm: contentfulForm(type: { eq: "Term Loans Form" }) {
            ...Form
        }
        loginForm: contentfulForm(type: { eq: "Customer Login Form" }) {
            ...Form
        }
        resetForm: contentfulForm(
            type: { eq: "Customer Password Reset Form" }
        ) {
            ...Form
        }
        contentfulFeatureManagementSystem(
            name: { eq: "Feature Management System" }
        ) {
            ...FeatureManagementSystem
        }
        contentfulHreflangManagement(name: { eq: "Hreflang Management" }) {
            ...HreflangManagement
        }
        allContentfulVariable {
            edges {
                node {
                    id
                    name
                    variableId
                    initialValue {
                        initialValue
                    }
                    fallbackValue {
                        fallbackValue
                    }
                }
            }
        }
    }

    fragment LegacyPageData on ContentfulLegacyPage {
        title
        url
        titleTag: title
        metaDescription
        allowSearchEngineScraping
        pageContent {
            header {
                ...Header
            }
            sections {
                __typename
                ...SectionHeroBanner
                ...SectionMetrics
                ...SectionHowItWorksScroll
                ...SectionCustomerReviews
                ...SectionRepaymentCalculator
                ...SectionCustomerVideo
                ...SectionCTA
                ...SectionMessage
                ...SectionLinkCards
                ...SectionContact
                ...SectionMediaAndText
                ...SectionHeroBannerSmall
                ...SectionQuickJumpContentBlocks
                ...SectionContent
                ...PressContactBox
                ...SectionPartnerCarousel
                ...SectionComparisonTable
                ...HeroDynamicImageOnTheRight
                ...SectionFeaturesAndReviews
                ...StickyHeader
                ... on ContentfulSectionSet {
                    sections {
                        __typename
                        ...SectionHeroBanner
                        ...SectionCustomerReviews
                        ...SectionRepaymentCalculator
                    }
                }
            }
            footer {
                ...LegacyFooter
            }
            activateSignupModal
            activateTermLoansModal
            activateLoginModal
            activatePasswordResetModal
        }
    }

    fragment SectionCustomerVideo on ContentfulSectionCustomerVideo {
        id
        name
        bannerText
        youtubeVideoId
        banner {
            file {
                url
            }
            fixed(width: 1440, quality: 90) {
                width
                height
                src
            }
        }
    }

    fragment LegacyFooter on ContentfulFooter {
        logoLink {
            ...LogoLink
        }
        logo {
            file {
                url
            }
        }
        regulatoryText {
            regulatoryText
        }
        socialMediaLinks {
            ...SocialMediaLink
        }
        footerColumns {
            id
            title
            legacyUrl
            content {
                __typename
                ...Link
                ...Page
                ...BuildingAddress
                ...ContactLink
            }
        }
    }

    fragment StickyHeader on ContentfulStickyHeader {
        id
        name
        logoLinks {
            ...LogoLink
        }
        scrollButton {
            ...Button
        }
        rightSideContent {
            ...LogoLink
        }
    }
`;
