import React from 'react';
import ReactMarkdown from 'react-markdown';
import styles from './PartnersOutroText.module.scss';

const PartnersOutroText = (props) => {
    const { outroTitle, outroText } = props;
    return (
        <div className={styles.PartnersOutroText}>
            <h2>{outroTitle}</h2>
            <ReactMarkdown source={outroText.outroText} escapeHtml={false} />
        </div>
    );
};

export default PartnersOutroText;
