import React from 'react';
import { WedgedArch } from '../../../../SVGComponents/shapes';
import styles from './CalculatorSection.module.scss';
import { animateScroll as scroll } from 'react-scroll';
import ReactMarkdown from 'react-markdown';
import CalculatorSectionShapes from './shapes/shapes';
import CalculatorRCF from 'aphrodite-shared/components/CalculatorRCF/CalculatorRCF';
import ArrowSVG from '../../../../SVGComponents/ArrowSVG';

const InterestRates = ({
    calculatorCopy,
    maximumLoanAmount,
    minimumLoanAmount,
    monthlyInterestRate,
    contactBubble,
    showArrow = true,
    overflowShapes = {
        WedgedArchShape: true,
        LongArchShape: true,
        SectionArchShape: true
    },
    noButtons = false
}) => {
    const { WedgedArchShape, SectionArchShape } = overflowShapes;
    function onArrowClick() {
        const innerHeight =
            typeof window !== 'undefined' ? window.innerHeight : '600';
        scroll.scrollMore(innerHeight - 100); // try exclude title of section
    }

    return (
        <section className={styles.CalculatorSectionContainer}>
            {WedgedArchShape && <WedgedArch className={styles.WedgedArch} />}
            <CalculatorSectionShapes ArchSectionShape={SectionArchShape}>
                <div className={styles.CalculatorSection}>
                    <h2 className={styles.hideMobile}>
                        {calculatorCopy.title}
                    </h2>
                    <h2 className={styles.showMobile}>
                        {calculatorCopy.mobileTitle}
                    </h2>
                    <hr />
                    <p className={styles.intro}>{calculatorCopy.subtitle}</p>
                    <div className={styles.card}>
                        <CalculatorRCF
                            text={contactBubble.text}
                            equalRepaymentText={
                                calculatorCopy.equalRepaymentText
                            }
                            monthlyInterestRate={monthlyInterestRate}
                            repayments={calculatorCopy.repaymentText}
                            helpText={calculatorCopy.helpText}
                            totalRepayments={calculatorCopy.totalRepaymentText}
                            interestText={calculatorCopy.interestText}
                            borrowUpToText={calculatorCopy.borrowUpToText}
                            forText={calculatorCopy.forText}
                            phoneNumber={contactBubble.phoneNumber}
                            borrowButtonText={
                                calculatorCopy.ctaButton.buttonText
                            }
                            maximumLoanAmount={maximumLoanAmount}
                            minimumLoanAmount={minimumLoanAmount}
                            noButtons={noButtons}
                        />
                    </div>
                    <ReactMarkdown
                        source={calculatorCopy.footerText.footerText}
                        className={styles.content}
                    />
                </div>
                {showArrow && (
                    <ArrowSVG className={styles.arrow} onClick={onArrowClick} />
                )}
            </CalculatorSectionShapes>
        </section>
    );
};

export default InterestRates;
