import React, { useContext } from 'react';
import { Field, Form, Formik } from 'formik';
import lodashStartCase from 'lodash/startCase';
import yup from 'yup';

import styles from './IntroducerSignupForm.module.scss';
import IwContext from 'aphrodite-shared/IwContext/IwContext';
import { logErrorToDataLayer } from '../../../util/googleTracking';
import { Input, Label, Button, Select, FormErrorMessage } from '@iwoca/orion';
import * as questions from './IntroducerSignupFormQuestions';
import { submitIntrodcerSignup } from './IntroducerSignupFormHelper';

const IntroducerSignupForm = () => {
    const context = useContext(IwContext);

    const handleSubmitForm = async (values, formik) => {
        try {
            await submitIntrodcerSignup(values);
            window.location.href = '/partner/account';
        } catch (error) {
            context.showApiError(
                'Es gibt ein Problem mit den Daten, die Sie eingegeben haben. Wenn Sie ein Konto bei iwoca haben, melden Sie sich über den Login-Button oben rechts an. Wenn nicht, dann rufen Sie uns gerne an und wir helfen Ihnen bei der Anmeldung.'
            );
            logErrorToDataLayer('Introducer Signup - Form submission error');

            // Prevent freeze in submission state
            formik.setSubmitting(false);
            if (typeof window !== 'undefined') {
                window.Raven.captureException(error);
            }
        }
    };
    return (
        <Formik
            initialValues={{
                [questions.TITLE]: questions.TITLE_OPTIONS[0],
                [questions.FIRST_NAME]: '',
                [questions.LAST_NAME]: '',
                [questions.COMPANY_EMAIL]: '',
                [questions.COMPANY_NAME]: '',
                [questions.VAT_NUMBER]: '',
                [questions.INTRODUCER_TYPE]: 'broker',
                [questions.COMPANY_NUMBER]: '',
                [questions.PHONE_NUMBER]: '',
                [questions.ADDRESS]: '',
                [questions.POSTCODE]: '',
                [questions.TERMS]: false
            }}
            onSubmit={(values, formik) => handleSubmitForm(values, formik)}
            validationSchema={introducerSignupFormSchema()}
            validateOnChange={false}>
            {(props) => {
                const { isSubmitting, touched, errors, handleSubmit } = props;

                return (
                    <Form
                        onSubmit={(e) => {
                            e.preventDefault();
                            handleSubmit();
                        }}>
                        <div className={styles.question}>
                            <Label htmlFor={questions.TITLE}>Anrede</Label>
                            <Field id={questions.TITLE} name={questions.TITLE}>
                                {({ field, meta }) => (
                                    <div className={styles.inputArea}>
                                        <Select {...field}>
                                            {questions.TITLE_OPTIONS.map(
                                                (option) => {
                                                    return (
                                                        <option value={option}>
                                                            {lodashStartCase(
                                                                option
                                                            )}
                                                        </option>
                                                    );
                                                }
                                            )}
                                        </Select>
                                        {meta.touched && meta.error && (
                                            <FormErrorMessage
                                                message={meta.error}
                                            />
                                        )}
                                    </div>
                                )}
                            </Field>
                        </div>

                        <div className={styles.question}>
                            <Label htmlFor={questions.FIRST_NAME}>
                                Vorname
                            </Label>
                            <Field
                                id={questions.FIRST_NAME}
                                name={questions.FIRST_NAME}>
                                {({ field, meta }) => (
                                    <div className={styles.inputArea}>
                                        <Input {...field} />
                                        {meta.touched && meta.error && (
                                            <FormErrorMessage
                                                message={meta.error}
                                            />
                                        )}
                                    </div>
                                )}
                            </Field>
                        </div>

                        <div className={styles.question}>
                            <Label htmlFor={questions.LAST_NAME}>
                                Nachname
                            </Label>

                            <Field
                                id={questions.LAST_NAME}
                                name={questions.LAST_NAME}>
                                {({ field, meta }) => (
                                    <div className={styles.inputArea}>
                                        <Input
                                            className="fs-block"
                                            {...field}
                                        />
                                        {meta.touched && meta.error && (
                                            <FormErrorMessage
                                                message={meta.error}
                                            />
                                        )}
                                    </div>
                                )}
                            </Field>
                        </div>

                        <div className={styles.question}>
                            <Label htmlFor={questions.COMPANY_EMAIL}>
                                E-Mail
                            </Label>
                            <Field
                                id={questions.COMPANY_EMAIL}
                                name={questions.COMPANY_EMAIL}>
                                {({ field, meta }) => (
                                    <div className={styles.inputArea}>
                                        <Input
                                            className="fs-block"
                                            {...field}
                                        />
                                        {meta.touched && meta.error && (
                                            <FormErrorMessage
                                                message={meta.error}
                                            />
                                        )}
                                    </div>
                                )}
                            </Field>
                        </div>

                        <Field
                            id={questions.INTRODUCER_TYPE}
                            name={questions.INTRODUCER_TYPE}>
                            {({ field }) => <Input type="hidden" {...field} />}
                        </Field>

                        <div className={styles.question}>
                            <Label htmlFor={questions.COMPANY_NAME}>
                                Firmenname
                            </Label>
                            <Field
                                id={questions.COMPANY_NAME}
                                name={questions.COMPANY_NAME}>
                                {({ field, meta }) => (
                                    <div className={styles.inputArea}>
                                        <Input
                                            className="fs-block"
                                            {...field}
                                        />
                                        {meta.touched && meta.error && (
                                            <FormErrorMessage
                                                message={meta.error}
                                            />
                                        )}
                                    </div>
                                )}
                            </Field>
                        </div>

                        <div className={styles.question}>
                            <Label htmlFor={questions.VAT_NUMBER}>
                                Ust. Nummer
                            </Label>
                            <Field
                                id={questions.VAT_NUMBER}
                                name={questions.VAT_NUMBER}>
                                {({ field, meta }) => (
                                    <div className={styles.inputArea}>
                                        <Input
                                            className="fs-block"
                                            {...field}
                                        />
                                        {meta.touched && meta.error && (
                                            <FormErrorMessage
                                                message={meta.error}
                                            />
                                        )}
                                    </div>
                                )}
                            </Field>
                        </div>

                        <div className={styles.question}>
                            <Label htmlFor={questions.COMPANY_NUMBER}>
                                Handelsregisternummer (HR) (optional)
                            </Label>
                            <Field
                                id={questions.COMPANY_NUMBER}
                                name={questions.COMPANY_NUMBER}>
                                {({ field, meta }) => (
                                    <div className={styles.inputArea}>
                                        <Input
                                            className="fs-block"
                                            {...field}
                                        />
                                        {meta.touched && meta.error && (
                                            <FormErrorMessage
                                                message={meta.error}
                                            />
                                        )}
                                    </div>
                                )}
                            </Field>
                        </div>

                        <div className={styles.question}>
                            <Label htmlFor={questions.PHONE_NUMBER}>
                                Telefonnummer
                            </Label>
                            <Field
                                id={questions.PHONE_NUMBER}
                                name={questions.PHONE_NUMBER}>
                                {({ field, meta }) => (
                                    <div className={styles.inputArea}>
                                        <Input
                                            className="fs-block"
                                            {...field}
                                        />
                                        {meta.touched && meta.error && (
                                            <FormErrorMessage
                                                message={meta.error}
                                            />
                                        )}
                                    </div>
                                )}
                            </Field>
                        </div>

                        <div className={styles.question}>
                            <Label htmlFor={questions.WEBSITE}>Webseite</Label>
                            <Field
                                id={questions.WEBSITE}
                                name={questions.WEBSITE}>
                                {({ field, meta }) => (
                                    <div className={styles.inputArea}>
                                        <Input
                                            className="fs-block"
                                            {...field}
                                        />
                                        {meta.touched && meta.error && (
                                            <FormErrorMessage
                                                message={meta.error}
                                            />
                                        )}
                                    </div>
                                )}
                            </Field>
                        </div>

                        <div className={styles.question}>
                            <Label htmlFor={questions.ADDRESS}>Adresse</Label>
                            <Field
                                id={questions.ADDRESS}
                                name={questions.ADDRESS}>
                                {({ field, meta }) => (
                                    <div className={styles.inputArea}>
                                        <Input
                                            className="fs-block"
                                            {...field}
                                        />
                                        {meta.touched && meta.error && (
                                            <FormErrorMessage
                                                message={meta.error}
                                            />
                                        )}
                                    </div>
                                )}
                            </Field>
                        </div>

                        <div className={styles.question}>
                            <Label htmlFor={questions.POSTCODE}>
                                Postleitzahl
                            </Label>
                            <Field
                                id={questions.POSTCODE}
                                name={questions.POSTCODE}>
                                {({ field, meta }) => (
                                    <div className={styles.inputArea}>
                                        <Input
                                            className="fs-block"
                                            {...field}
                                        />
                                        {meta.touched && meta.error && (
                                            <FormErrorMessage
                                                message={meta.error}
                                            />
                                        )}
                                    </div>
                                )}
                            </Field>
                        </div>
                        <div className={styles.submitArea}>
                            <div className={styles.terms}>
                                <Field
                                    id={questions.TERMS}
                                    name={questions.TERMS}>
                                    {({ field, meta }) => (
                                        <Input type="checkbox" {...field} />
                                    )}
                                </Field>

                                <div>
                                    <Label
                                        className={styles.termsLabel}
                                        htmlFor={questions.TERMS}>
                                        Ich habe die{' '}
                                        <a
                                            href="https://assets.ctfassets.net/wt7ul1fonsxk/11lNLHYaSsVOp8FHGllsT0/2ab2ff094b10f501a05dd6b77aa4c1b9/AGB_des_iwoca_Produktpartnerprogramms__1_.pdf"
                                            target="_blank"
                                            rel="noopener noreferrer">
                                            iwoca Partner AGB
                                        </a>{' '}
                                        gelesen und stimme diesen zu.
                                    </Label>
                                    {touched[questions.TERMS] &&
                                        errors[questions.TERMS] && (
                                            <FormErrorMessage
                                                message={
                                                    errors[questions.TERMS]
                                                }
                                            />
                                        )}
                                </div>
                            </div>
                            <Button
                                disabled={isSubmitting}
                                variant="primary"
                                type="submit">
                                Anmeldung
                            </Button>
                        </div>
                    </Form>
                );
            }}
        </Formik>
    );
};

function introducerSignupFormSchema() {
    return yup.object({
        [questions.TITLE]: yup
            .string()
            .required()
            .oneOf(questions.TITLE_OPTIONS)
            .required('Dies ist ein Pflichtfeld'),
        [questions.FIRST_NAME]: yup
            .string()
            .required()
            .min(0)
            .required('Dies ist ein Pflichtfeld'),
        [questions.LAST_NAME]: yup
            .string()
            .required()
            .min(0)
            .required('Dies ist ein Pflichtfeld'),
        [questions.COMPANY_EMAIL]: yup
            .string()
            .email()
            .required()
            .min(0)
            .required('Dies ist ein Pflichtfeld'),
        [questions.COMPANY_NAME]: yup
            .string()
            .required()
            .min(0)
            .required('Dies ist ein Pflichtfeld'),
        [questions.VAT_NUMBER]: yup
            .string()
            .min(0)
            .max(20),
        [questions.COMPANY_NUMBER]: yup.number().min(0),
        [questions.PHONE_NUMBER]: yup
            .string()
            .required()
            .min(0)
            .required('Dies ist ein Pflichtfeld'),
        [questions.ADDRESS]: yup
            .string()
            .required()
            .min(0)
            .required('Dies ist ein Pflichtfeld'),
        [questions.POSTCODE]: yup
            .string()
            .required()
            .min(0)
            .max(10)
            .required('Dies ist ein Pflichtfeld'),
        [questions.TERMS]: yup.bool().oneOf([true], 'Dies ist ein Pflichtfeld')
    });
}

export default IntroducerSignupForm;
