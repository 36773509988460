import React from 'react';
import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown';
import SectionContainer from '../SectionContainer';
import Review from './Review';
import styles from './Reviews.module.scss';

class Reviews extends React.Component {
    render() {
        if (!this.props.data) return null;

        const {
            title,
            reviews,
            logoBar,
            legacySubtitle,
            quickJumpLink = {}
        } = this.props.data;

        return (
            <SectionContainer
                className={styles.reviews}
                quickJumpHash={quickJumpLink.hash}>
                {title && <h3 className={styles.title}>{title}</h3>}
                {legacySubtitle && (
                    <h4 className={styles.subtitle}>
                        <ReactMarkdown
                            source={legacySubtitle.legacySubtitle}
                            escapeHtml={false}
                        />
                    </h4>
                )}
                <div className={styles.reviewsList}>
                    {reviews.map((review) => (
                        <Review review={review} />
                    ))}
                </div>
                <div className={styles.logoBar}>
                    {logoBar.map((item) => (
                        <div key={item.id} className={styles.logo}>
                            <img src={item.logo.file.url} alt={item.name} />
                        </div>
                    ))}
                </div>
            </SectionContainer>
        );
    }
}

Reviews.propTypes = {
    title: PropTypes.string,
    legacySubtitle: PropTypes.shape({
        legacySubtitle: PropTypes.string
    }),
    reviews: PropTypes.arrayOf(
        PropTypes.shape({
            customerImage: PropTypes.shape({
                url: PropTypes.string
            }),
            title: PropTypes.string,
            customerName: PropTypes.string
        })
    ),
    logoBar: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string,
            url: PropTypes.string,
            name: PropTypes.string,
            logo: PropTypes.shape({
                file: PropTypes.shape({
                    url: PropTypes.string
                })
            })
        })
    )
};

export default Reviews;
