import React from 'react';
import GatsbyImage from 'gatsby-image';
import styles from './HeroBanner.module.scss';

const HeroBanner = (props) => {
    const { fluidImage, isPersonImage, text, underHeader, hasCTABox } = props;
    return (
        <div
            className={
                isPersonImage
                    ? `${styles.HeroBanner} ${styles.person}`
                    : `${styles.HeroBanner}`
            }>
            <GatsbyImage
                className={
                    isPersonImage ? styles.personImage : styles.bannerImage
                }
                fluid={fluidImage}
            />
            {text && (
                <div className={hasCTABox ? styles.textWithCTA : styles.text}>
                    <h1>{text}</h1>
                    {underHeader}
                </div>
            )}
        </div>
    );
};

export default HeroBanner;
