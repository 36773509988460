import React from 'react';
import Slider from 'react-slick';
import PropTypes from 'prop-types';
import styles from './HeroDynamicImageOnTheRight.module.scss';
import IwocaImage from '../IwocaImage';

class HeroDynamicImageOnTheRight extends React.Component {
    constructor() {
        super();
        this.state = {
            imageNumbersToUse: []
        };

        this.generateImageNumbersToUse = this.generateImageNumbersToUse.bind(
            this
        );
    }

    componentDidMount() {
        this.generateImageNumbersToUse();
    }

    generateImageNumbersToUse() {
        const { numberOfImages } = this.props;
        let { images } = this.props.data;
        let imageList = [];

        if (images.length > numberOfImages) {
            for (let index = 0; index < numberOfImages; index++) {
                imageList.push(this.generateRandomImageNumber(images));
            }
        } else {
            for (let index = 0; index < numberOfImages; index++) {
                imageList.push(index);
            }
        }

        this.setState({ imageNumbersToUse: imageList });
    }

    generateRandomImageNumber(images) {
        return Math.floor(Math.random() * Math.floor(images.length - 1));
    }

    render() {
        const sliderSettings = {
            autoplaySpeed: this.props.intervalTime,
            autoplay:
                typeof window !== 'undefined'
                    ? !window.DISABLE_ANIMATIONS
                    : true,
            slidesToShow: 1,
            arrows: false
        };

        const { preHeadline, headline, images } = this.props.data;
        const { imageNumbersToUse } = this.state;

        return (
            <section className={styles.heroOuterContainer}>
                <div className={styles.heroContainer}>
                    <div className={styles.heroInnerContainer}>
                        <div className={styles.headlines}>
                            {preHeadline && (
                                <span className={styles.preHeadline}>
                                    {preHeadline}
                                </span>
                            )}
                            {headline && <h1>{headline}</h1>}
                        </div>
                        <div className={styles.imageContainer}>
                            <div className={styles.sliderContainer}>
                                {imageNumbersToUse.length > 0 && (
                                    <Slider {...sliderSettings}>
                                        {imageNumbersToUse.map(
                                            (imageNumber) => (
                                                <IwocaImage
                                                    src={
                                                        images[imageNumber].file
                                                            .url
                                                    }
                                                    className={styles.image}
                                                />
                                            )
                                        )}
                                    </Slider>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

HeroDynamicImageOnTheRight.propTypes = {
    preHeadline: PropTypes.string,
    headline: PropTypes.string,
    images: PropTypes.arrayOf(
        PropTypes.shape({
            file: PropTypes.shape({
                url: PropTypes.string
            })
        })
    )
};

HeroDynamicImageOnTheRight.defaultProps = {
    intervalTime: 15000,
    numberOfImages: 5
};

export default HeroDynamicImageOnTheRight;
