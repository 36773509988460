import React from 'react';
import Button from '../../../../components/common/Button';

import styles from './PartnersBottomCTA.module.scss';

export const PartnersBottomCTA = ({
    partnerSignupLink,
    partnerSignupLabel
}) => {
    return (
        <div className={styles.PartnersBottomCTA}>
            <Button colour="secondary" href={partnerSignupLink} newTab>
                {partnerSignupLabel}
            </Button>
        </div>
    );
};
