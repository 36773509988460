import React from 'react';
import ContentHubEmailCapture from '../common/ContentHubEmailCapture';
import ArticleList from '../common/ArticleList';
import styles from './ContentHubHomePage.module.scss';
import FeaturedArticles from '../common/FeaturedArticles/FeaturedArticles';

class ContentHubHomePage extends React.Component {
    render() {
        const {
            pageTitle,
            subHeading,
            emailCapture,
            featuredArticles
        } = this.props.contentfulBlogHomepage;

        const { allContentfulBlogArticle } = this.props;
        const articleList = allContentfulBlogArticle.edges
            .map((edge) => edge.node) // Parse articles into a easier to use array
            .filter(
                (article) =>
                    !featuredArticles.some(
                        (featuredArticle) => featuredArticle.id === article.id
                    ) &&
                    !/.*(SCHEMA).*/gi.test(article.name) &&
                    article.excludeFromHomepage !== true
            )
            .sort((articleOne, articleTwo) => {
                return new Date(articleTwo.date) - new Date(articleOne.date);
            });

        return (
            <React.Fragment>
                <div className={styles.container}>
                    <h1 className={styles.title}>
                        {pageTitle}
                        <i className={styles.line} />
                    </h1>
                    {subHeading && (
                        <h4 className={styles.subHeading}>{subHeading}</h4>
                    )}
                </div>
                {featuredArticles.length > 0 && (
                    <FeaturedArticles
                        featuredArticles={[featuredArticles[0]]}
                    />
                )}
                {featuredArticles.length > 1 && (
                    <FeaturedArticles
                        featuredArticles={featuredArticles.slice(1, 3)}
                    />
                )}
                {/* <ContentHubEmailCapture emailCapture={emailCapture} /> */}
                {/*  FeaturedArticleSection  3-6 */}
                {featuredArticles.length >= 3 && (
                    <FeaturedArticles
                        featuredArticles={featuredArticles.slice(3, 6)}
                        alternativeLayout
                    />
                )}
                <div className={styles.list}>
                    <ArticleList articleList={articleList} />
                </div>
            </React.Fragment>
        );
    }
}

export default ContentHubHomePage;
