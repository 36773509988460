import React from 'react';
import PropTypes from 'prop-types';

import styles from './HowItWorksSection.module.scss';

import SectionContainer from './SectionContainer';

class HowItWorksSection extends React.Component {
    constructor() {
        super();

        this.renderStep = this.renderStep.bind(this);
    }

    renderStep(stepData, stepNumber) {
        const {
            title,
            description,
            callToActionLinkIcon,
            callToActionLinkText,
            callToActionLinkUrl
        } = stepData;

        const renderCTA = callToActionLinkText && callToActionLinkUrl;

        return (
            <div className={styles.step} key={stepNumber}>
                <span className={styles.numberContainer}>
                    <span className={styles.number}>{stepNumber}</span>
                </span>
                <h4 className={styles.stepTitle}>{title}</h4>
                <small className={styles.stepDescription}>
                    {description.description}
                </small>
                {renderCTA && (
                    <a
                        className={styles.ctaLink}
                        title={callToActionLinkText}
                        href={callToActionLinkUrl}>
                        {callToActionLinkText}
                        {callToActionLinkIcon && callToActionLinkIcon.file && (
                            <img
                                className={styles.ctaIcon}
                                src={callToActionLinkIcon.file.url}
                                alt={callToActionLinkText}
                            />
                        )}
                    </a>
                )}
            </div>
        );
    }

    getScreenshotUrl(data) {
        const screenshotFallbackUrl =
            'https://static.iwoca.com/images/test-landing-pages/how_it_works/Credit_Line_Screenshot.png';

        // Get first step with a screenshot, otherwise get fallback screenshot
        const screenshotStep = data.steps.find(
            (step) => step.banner && step.banner.file
        );
        const screenshotUrl =
            screenshotStep.banner && screenshotStep.banner.file
                ? screenshotStep.banner.file.url
                : screenshotFallbackUrl;

        return screenshotUrl;
    }

    render() {
        const { data } = this.props;

        if (!data) {
            console.error(
                'CustomerVideoSection: No data found. Please provide an data object'
            );
            return null;
        }

        const { headline, steps } = data;

        const screenshotUrl = this.getScreenshotUrl(data);

        return (
            <SectionContainer className={styles.howItWorksSection}>
                <div className={styles.howItWorks}>
                    <img
                        className={styles.screenshot}
                        alt="Application Complete"
                        src={screenshotUrl}
                    />
                    <div className={styles.text}>
                        {headline && <h3>{headline}</h3>}
                        {steps && (
                            <div className={styles.stepContainer}>
                                {steps.map((stepData, index) =>
                                    this.renderStep(stepData, index + 1)
                                )}
                            </div>
                        )}
                    </div>
                </div>
            </SectionContainer>
        );
    }
}

HowItWorksSection.propTypes = {
    data: PropTypes.shape({
        headline: PropTypes.string,
        steps: PropTypes.arrayOf(
            PropTypes.shape({
                title: PropTypes.string,
                banner: PropTypes.shape({
                    file: PropTypes.shape({
                        url: PropTypes.string.isRequired
                    })
                }),
                description: PropTypes.shape({
                    description: PropTypes.string.isRequired
                }),
                callToActionLinkIcon: PropTypes.shape({
                    file: PropTypes.shape({
                        url: PropTypes.string.isRequired
                    })
                }),
                callToActionLinkText: PropTypes.string,
                callToActionLinkUrl: PropTypes.string
            })
        )
    })
};

export default HowItWorksSection;
