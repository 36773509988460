import React from 'react';

const DownloadSVG = () => {
    return (
        <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M16 11.6441V15.1924C16 15.263 15.9899 15.3286 15.9748 15.3892C15.9043 15.7375 15.597 15.9999 15.2343 15.9999H0.760705C0.372796 15.9999 0.0503779 15.7021 0.0100756 15.3236C3.82851e-08 15.2782 0 15.2378 0 15.1873V11.6391C0 11.2201 0.342569 10.882 0.755668 10.882C0.962217 10.882 1.15365 10.9678 1.28967 11.104C1.42569 11.2403 1.51134 11.4321 1.51134 11.6391V14.4807H14.4937V11.6391C14.4937 11.2201 14.8363 10.882 15.2494 10.882C15.4559 10.882 15.6474 10.9678 15.7834 11.104C15.9144 11.2454 16 11.4372 16 11.6441Z"
                fill="white"
            />
            <path
                d="M12.3574 8.09085L8.64454 11.8107C8.63951 11.8208 8.62943 11.8259 8.62439 11.8309C8.48837 11.9672 8.31205 12.053 8.13573 12.0782C8.12061 12.0782 8.1055 12.0833 8.09039 12.0833C8.06016 12.0883 8.02993 12.0883 7.99971 12.0883L7.91407 12.0833C7.89895 12.0833 7.88384 12.0782 7.86873 12.0782C7.68737 12.053 7.51608 11.9672 7.38006 11.8309C7.37502 11.8259 7.36495 11.8158 7.35991 11.8107L3.64706 8.09085C3.47578 7.91924 3.39014 7.69211 3.39014 7.46498C3.39014 7.23785 3.47578 7.01073 3.64706 6.83912C3.98963 6.4959 4.54883 6.4959 4.89643 6.83912L7.12817 9.07508V0.883281C7.12817 0.398738 7.52616 0 8.00978 0C8.2516 0 8.47326 0.100946 8.63447 0.257413C8.79568 0.418927 8.8914 0.635962 8.8914 0.883281V9.07508L11.1231 6.83912C11.4657 6.4959 12.0249 6.4959 12.3725 6.83912C12.7 7.18738 12.7 7.74763 12.3574 8.09085Z"
                fill="white"
            />
        </svg>
    );
};

export default DownloadSVG;
